import { IAction, ISapData } from '../../schemas';
import { GET_SAPDATA_LIST, UPDATE_SAPDATA } from '../constant';

const initState: any = {};

export default function budgetsReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case GET_SAPDATA_LIST:
			return { ...data };
		case UPDATE_SAPDATA:
			const index = preState.data.findIndex(
				(item) => item.id === action.data.id
			);
			const updateData = [
				...preState.data.slice(0, index),
				{
					...data,
				},
				...preState.data.slice(index + 1),
			];
			return { data: updateData, total: preState.total };
		default:
			return preState;
	}
}

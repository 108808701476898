import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { addBudgetAsync, submitBudgetAsync, deleteBudgetAsync } from '../../redux/actions';
import { IBudget, IBudgetValidationInfo, IStore } from '../../schemas';

import * as OPTIONS from '../options';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { validateBudgetApi } from '../../api';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class BudgetPanelNew extends Component<any, any> {
	state = {
		budget: {} as IBudget,
		isSaving: false,
		errors: {
			sysError: '',
			itemNo: '',
		},
		messageBar: { show: false, msg: '' },
	};

	async componentDidMount() {
		const { budget } = this.state;
		var newBudget = await this.props.addBudgetAsync(budget);
		this.setState({
			budget: { ...newBudget },
		});
	}

	preCloseCheck = () => {
		const { budget } = this.state;
		let check = true;
		if (!budget.itemNo || budget.itemNo == '') {
			check = false;
		} else if (budget.itemNo.length > 4) {
			check = false;
		} else if (!budget.budgetYear || budget.budgetYear == '') {
			check = false;
		} else if (!/^((20)\d{2})$/.test(budget.budgetYear)) {
			check = false;
		} else if (!budget.bucketTitle || budget.bucketTitle == '') {
			check = false;
		} else if (!budget.coodinatorId || budget.coodinatorId == '') {
			check = false;
		} else if (!budget.teamName || budget.teamName == '') {
			check = false;
		} else if (!budget.subDepartmentName || budget.subDepartmentName == '') {
			check = false;
		} else if (!budget.budgetOwnerId || budget.budgetOwnerId == '') {
			check = false;
		} else if (!budget.budgetTypeId || budget.budgetTypeId == '') {
			check = false;
		} else if (!budget.platformId || budget.platformId == '') {
			check = false;
		} else if (!budget.expenseId || budget.expenseId == '') {
			check = false;
		} else if (!budget.categoryId || budget.categoryId == '') {
			check = false;
		} else if (!budget.picId || budget.picId == '') {
			check = false;
		}

		return check;
	};

	closePanel = async () => {
		if (!this.preCloseCheck()) {
			const { budget } = this.state;
			await this.props.deleteBudgetAsync(budget);
		}
		this.props.onDismiss(false);
	};

	preCheck = () => {
		const { budget } = this.state;
		let check = true;
		if (!budget.itemNo || budget.itemNo == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Item No."' },
			});
		} else if (budget.itemNo.length > 4) {
			check = false;
			this.setState({
				messageBar: {
					show: true,
					msg: '"Item No." does not meet the specifications.',
				},
			});
		} else if (!budget.budgetYear || budget.budgetYear == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Budget Year"' },
			});
		} else if (!/^((20)\d{2})$/.test(budget.budgetYear)) {
			check = false;
			this.setState({
				messageBar: {
					show: true,
					msg: '"Budget Year" does not meet the specifications',
				},
			});
		} else if (!budget.bucketTitle || budget.bucketTitle == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Bucket"' },
			});
		} else if (!budget.coodinatorId || budget.coodinatorId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Coordinator"' },
			});
		} else if (!budget.teamName || budget.teamName == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Team"' },
			});
		} else if (!budget.subDepartmentName || budget.subDepartmentName == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Sub Department"' },
			});
		} else if (!budget.budgetOwnerId || budget.budgetOwnerId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Budget Owner"' },
			});
		} else if (!budget.budgetTypeId || budget.budgetTypeId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Type"' },
			});
		} else if (!budget.platformId || budget.platformId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Platform/Customer"' },
			});
		} else if (!budget.expenseId || budget.expenseId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Expense Category"' },
			});
		} else if (!budget.categoryId || budget.categoryId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Category"' },
			});
		} else if (!budget.picId || budget.picId == '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "PIC"' },
			});
		} else if (!budget.initQ1Value || budget.initQ1Value == '') {
			// check = false;
			// this.setState({
			// 	messageBar: { show: true, msg: 'Please enter the "Q1"' },
			// });
		} else if (!budget.initQ2Value || budget.initQ2Value == '') {
			// check = false;
			// this.setState({
			// 	messageBar: { show: true, msg: 'Please enter the "Q2"' },
			// });
		} else if (!budget.initQ3Value || budget.initQ3Value == '') {
			// check = false;
			// this.setState({
			// 	messageBar: { show: true, msg: 'Please enter the "Q3"' },
			// });
		} else if (!budget.initQ4Value || budget.initQ4Value == '') {
			// check = false;
			// this.setState({
			// 	messageBar: { show: true, msg: 'Please enter the "Q4"' },
			// });
		}

		return check;
	};

	onBtnSaveClick = async (_) => {
		const { budget } = this.state;

		if (!this.preCheck()) {
			return;
		}

		this.setState({
			isSaving: true,
		});
		let isValid = true;
		const errors = await this.validateFormValueAsync();
		Object.keys(errors).forEach((key) => {
			if (errors[key] && errors[key].length > 0) {
				isValid = false;
			}
		});
		if (!isValid) {
			this.setState({
				errors: { ...errors },
			});
			this.setState({
				isSaving: false,
			});
		} else {
			await this.props.addBudgetAsync(budget);
			this.setState({
				isSaving: false,
			});
			this.props.onDismiss(false);
		}
	};

	onBtnSubmitClick = async (_) => {
		const { budget } = this.state;

		if (!this.preCheck()) {
			return;
		}

		this.setState({
			isSaving: true,
		});
		let isValid = true;
		const errors = await this.validateFormValueAsync();
		Object.keys(errors).forEach((key) => {
			if (errors[key] && errors[key].length > 0) {
				isValid = false;
			}
		});
		if (!isValid) {
			this.setState({
				errors: { ...errors },
			});
			this.setState({
				isSaving: false,
			});
		} else {
			await this.props.submitBudgetAsync(budget);
			this.setState({
				isSaving: false,
			});
			this.props.onDismiss(false);
		}
	};

	validateFormValueAsync = async () => {
		const { budget } = this.state;
		let errors: IBudgetValidationInfo = {
			sysError: '',
			itemNo: '',
		};

		if (!budget.itemNo || budget.itemNo?.length === 0) {
			errors = { itemNo: 'Please enter the "Item No."' };
			return errors;
		}

		const validateResult = await validateBudgetApi(budget);
		if (validateResult.errorCode.length === 0) {
			errors = { ...validateResult.value };
		} else {
			errors['sysError'] = validateResult.errorMessage;
		}

		return errors;
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
					Cancel
				</DefaultButton>
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
					Save
				</PrimaryButton>
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSubmitClick}>
					Submit
				</PrimaryButton>
			</div>
		);
	};

	onTextChanged = (name) => {
		const { budget } = this.state;

		return (_, data) => {
			let { initFyValue, initQ1Value, initQ2Value, initQ3Value, initQ4Value } =
				budget;

			switch (name) {
				case 'itemNo':
					data = data.replace(/[^0-9]/g, '');
					break;
				case 'budgetYear':
					data = data.replace(/[^0-9]/g, '');
					break;
				case 'initQ1Value':
					data = data.replace(/[^0-9]/g, '');
					initFyValue = (
						Number(data) +
						(Number(initQ2Value) | 0) +
						(Number(initQ3Value) | 0) +
						(Number(initQ4Value) | 0)
					).toString();
					break;
				case 'initQ2Value':
					data = data.replace(/[^0-9]/g, '');
					initFyValue = (
						Number(data) +
						(Number(initQ1Value) | 0) +
						(Number(initQ3Value) | 0) +
						(Number(initQ4Value) | 0)
					).toString();
					break;
				case 'initQ3Value':
					data = data.replace(/[^0-9]/g, '');
					initFyValue = (
						Number(data) +
						(Number(initQ2Value) | 0) +
						(Number(initQ1Value) | 0) +
						(Number(initQ4Value) | 0)
					).toString();
					break;
				case 'initQ4Value':
					data = data.replace(/[^0-9]/g, '');
					initFyValue = (
						Number(data) +
						(Number(initQ2Value) | 0) +
						(Number(initQ3Value) | 0) +
						(Number(initQ1Value) | 0)
					).toString();
					break;
			}

			this.setState({
				budget: { ...budget, [name]: data, initFyValue },
				errors: {
					sysError: '',
					itemNo: '',
				},
			});
		};
	};

	onDropDownChanged = (name) => {
		const { budget } = this.state;

		return (_, data) => {
			const optionObj = {
				[`${name}Id`]: data.key,
				[`${name}Name`]: data.text,
			};
			this.setState({
				budget: { ...budget, ...optionObj },
			});
		};
	};

	onDropDownChanged2 = (name, item?) => {
		const { budget } = this.state;

		if (name === 'coodinator') {
			this.setState({
				budget: {
					...budget,
					coodinatorId: item.key,
					coodinatorName: item.text,
					teamId: item.data.team,
					teamName: item.data.team,
				},
			});
		} else {
			const optionObj = {
				[`${name}Id`]: item.key,
				[`${name}Name`]: item.text,
			};
			this.setState({
				budget: { ...budget, ...optionObj },
			});
		}
	};

	render() {
		const { dictionary, coordinators, budgetowners, pics, common } = this.props;
		const { budget, errors } = this.state;
		let subDepts = [];
		let subDeptsNew: any = [];

		if (
			common.teamMappings &&
			common.teamMappings.length > 0 &&
			budget.teamName &&
			budget.teamName != ''
		) {
			subDepts = common.teamMappings.filter((v) => {
				if (budget.teamName === 'Finance') {
					return true;
				} else {
					return v.name == budget.teamName;
				}
			});
			if (subDepts.length > 0) {
				subDeptsNew = subDepts.map((c: any) => {
					return { key: c.department, text: c.department };
				});
			}
		}

		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Add Budget Bucket'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					{this.state.messageBar.show ? (
						<MessageBar
							messageBarType={MessageBarType.error}
							isMultiline={false}
							onDismiss={() => {
								this.setState({ messageBar: { show: false } });
							}}
							dismissButtonAriaLabel='Close'
							styles={{
								root: {
									position: 'fixed',
									zIndex: 10000,
									width: 'calc(100% - 50px)',
								},
							}}
						>
							{this.state.messageBar.msg}
						</MessageBar>
					) : null}

					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow style={{ maxWidth: '50%' }}>
								<TextField
									key='txtItemNo'
									label='Item No.'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('itemNo')}
									value={budget.itemNo}
									errorMessage={errors.itemNo}
									readOnly
								/>
							</Stack.Item>

							<Stack.Item grow style={{ maxWidth: '50%' }}>
								<TextField
									key='budgetYear'
									label='Budget Year'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('budgetYear')}
									value={budget.budgetYear}
									readOnly
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Bucket'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('bucketTitle')}
									value={budget.bucketTitle}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpCoodinator'
									label='Coordinator'
									options={coordinators.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									onChange={(_, data) =>
										this.onDropDownChanged2('coodinator', data)
									}
									selectedKey={budget.coodinatorId}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<TextField
									key='txtTeam'
									label='Team'
									borderless
									styles={textFieldSytles}
									value={budget.teamName}
									readOnly
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpSubDepartment'
									label='Sub Department'
									options={subDeptsNew}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('subDepartment')}
									selectedKey={budget.subDepartmentName}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpBudgetType'
									label='Type'
									// options={OPTIONS.typeOptions}
									options={dictionary.budgetTypes.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('budgetType')}
									selectedKey={budget.budgetTypeId}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpPlatform'
									label='Platform/Customer'
									// options={OPTIONS.platformOptions}
									options={dictionary.platforms.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('platform')}
									selectedKey={budget.platformId}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpCategory'
									label='Product Category'
									// options={OPTIONS.categoryOptions}
									options={dictionary.categories.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('category')}
									selectedKey={budget.categoryId}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpExpense'
									label='Expense Category'
									// options={OPTIONS.expenseOptions}
									options={dictionary.expenses.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('expense')}
									selectedKey={budget.expenseId}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpSubExpense'
									label='SubExpense Category'
									// options={OPTIONS.expenseOptions}
									options={[{ key: '', text: '' }].concat(
										dictionary.subExpenses.map((data) => {
											return { key: data.itemName, text: data.itemName };
										})
									)}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('subExpense')}
									selectedKey={budget.subExpenseId}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpBudgetOwner'
									label='Budget Owner'
									options={budgetowners.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('budgetOwner')}
									selectedKey={budget.budgetOwnerId}
								/>
							</Stack.Item>

							<Stack.Item styles={{ root: { width: '50%' } }}>
								<TextField
									label='Budget Owner ID'
									borderless
									styles={textFieldSytles}
									readOnly
									onChange={this.onTextChanged('budgetOwnerId')}
									value={budget.budgetOwnerId}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpPic'
									label='PIC'
									options={pics.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									onChange={(_, data) => this.onDropDownChanged2('pic', data)}
									selectedKey={budget.picId}
								/>
							</Stack.Item>

							<Stack.Item styles={{ root: { width: '50%' } }}>
								<TextField
									label='PIC ID'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('picId')}
									value={budget.picId}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'xLarge'}>Original Version</Text>
						</Stack.Item>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									disabled
									label='Q1'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('initQ1Value')}
									value={budget.initQ1Value}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									disabled
									label='Q2'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('initQ2Value')}
									value={budget.initQ2Value}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									disabled
									label='Q3'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('initQ3Value')}
									value={budget.initQ3Value}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									disabled
									label='Q4'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('initQ4Value')}
									value={budget.initQ4Value}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									disabled
									label='FY'
									borderless
									styles={textFieldSytles}
									readOnly
									onChange={this.onTextChanged('initFyValue')}
									value={budget.initFyValue}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow>
							<TextField
								label='Comments'
								multiline={true}
								borderless
								styles={textFieldSytles}
								onChange={this.onTextChanged('comments')}
								value={budget.comments}
							/>
						</Stack.Item>
					</Stack>

					{this.state.isSaving ? (
						<div className='panel-loading'>
							<Spinner
								size={SpinnerSize.large}
								label='Saving...'
								styles={{
									label: {
										color: 'white',
										fontSize: '1rem',
										fontWeight: 'bold',
									},
								}}
							/>
						</div>
					) : null}
				</Panel>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		dictionary: state.dictionary,
		coordinators: state.coordinators,
		budgetowners: state.budgetowners,
		pics: state.pics,
		common: state.common,
	}),
	{
		addBudgetAsync,
		submitBudgetAsync,
		deleteBudgetAsync
	}
)(BudgetPanelNew);

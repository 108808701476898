import {
	ADD_USER,
	UPDATE_USER,
	DELETE_USER,
	GET_USER_LIST,
	GET_COORDINATOR_LIST,
	GET_BUDGETOWNER_LIST,
	GET_PIC_LIST,
} from '../constant';
import {
	getUsersApi,
	addUserApi,
	updateUserApi,
	deleteUserApi,
} from '../../api';

export const getUsers = (data) => ({
	type: GET_USER_LIST,
	data,
});

export const addUser = (data) => ({
	type: ADD_USER,
	data,
});

export const updateUser = (data) => ({
	type: UPDATE_USER,
	data,
});

export const deleteUser = (data) => ({
	type: DELETE_USER,
	data,
});

export const getCoordinators = (data) => ({
	type: GET_COORDINATOR_LIST,
	data,
});

export const getBudgetOwners = (data) => ({
	type: GET_BUDGETOWNER_LIST,
	data,
});

export const getPics = (data) => ({
	type: GET_PIC_LIST,
	data,
});

export const getUsersAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getUsersApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 1;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					console.log('::::::::::::::::::', totalpage);
					dispatch(
						getUsers({ data: (data.value as any).data ?? data.value, total: totalpage })
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

export const addUserAsync = (data) => async (dispatch) => {
	try {
		const response = await addUserApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(addUser(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateUserAsync = (data) => async (dispatch) => {
	try {
		const response = await updateUserApi(data);
		if (response.errorCode.trim().length === 0) {
			console.log(':::::::::::updateUserAsync', response.value);
			dispatch(updateUser(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteUserAsync = (data) => async (dispatch) => {
	try {
		const response = await deleteUserApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(deleteUser(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getUserDictionaryAsync = (params) => {
	return (dispatch) => {
		return getUsersApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					dispatch(
						getCoordinators(
							data.value.filter((d) => d.role === 'BU Coordinator')
						)
					);
					dispatch(
						getBudgetOwners(data.value.filter((d) => d.role === 'Budget Owner'))
					);
					dispatch(getPics(data.value.filter((d) => d.role === 'PIC')));
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

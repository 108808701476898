import { get, post, put, del } from './comm-http';
import { IUser, IResponse } from '../schemas';

/**
 * Get the user list
 * @param params The params used to query user
 * @returns User list
 */
async function getUsersApi(params): Promise<IResponse<IUser[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IUser[]>>('/user', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getUserByIdApi(id: string): Promise<IResponse<IUser>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IUser>>(`/user/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

async function getUserProfileApi(): Promise<IResponse<IUser>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IUser>>(`/user/profile`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new user
 * @param data User data
 * @returns User data from backend service after the user is created
 */
async function addUserApi(data: IUser): Promise<IResponse<IUser>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IUser, IResponse<IUser>>('/user', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a user
 * @param data User data
 * @returns User data from backend service after the user is updated
 */
async function updateUserApi(data: IUser): Promise<IResponse<IUser>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IUser, IResponse<IUser>>(`/user/${data.id}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Delete a user
 * @param data User data
 * @returns User data from backend service after the user is updated
 */
async function deleteUserApi(data: IUser): Promise<IResponse<IUser>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IUser>>(`/user/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

async function getUserValidateApi(params): Promise<any> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<any>(`/auth/validateusercode`, {
		params,
	});
}

async function getauthorizationCodeApi(params): Promise<any> {
	return await get<any>(`/auth/validatecode`, {
		params,
	});
}

export {
	getUsersApi,
	getUserByIdApi,
	getUserProfileApi,
	addUserApi,
	updateUserApi,
	deleteUserApi,
	getUserValidateApi,
	getauthorizationCodeApi,
};

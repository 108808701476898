import { combineReducers } from 'redux';
import cardTemplates from './template';
import sharedPortalNavSwitch from './shared';
import budgets from './budgets';
import speakerfees from './speakerfees';
import requests from './requests';
import podatas from './podatas';
import sapdatas from './sapdatas';
import speakerdatas from './speakerdatas';
import dictteams from './dictteams';
import dictproducts from './dictproducts';
import dictexpenses from './dictexpenses';
import dictsubexpenses from './dictsubexpenses';
import dictbudgettypes from './dictbudgettypes';
import dictplatforms from './dictplatforms';
import users from './users';
import subDepartments from './subDepartments'
import currentUser from './currentuser';
import coordinators from './coordinators';
import budgetowners from './budgetowners';
import pics from './pics';
import common from './common';
import dictionary from './dictionary';
import importHistories from './importhistories';

export default combineReducers({
	sharedPortalNavSwitch,
	cardTemplates,
	budgets,
	speakerfees,
	requests,
	podatas,
	sapdatas,
	speakerdatas,
	dictteams,
	dictproducts,
	dictexpenses,
	dictsubexpenses,
	dictbudgettypes,
	dictplatforms,
	users,
	subDepartments,
	currentUser,
	coordinators,
	budgetowners,
	pics,
	common,
	dictionary,
	importHistories,
});

const PageTitle = {
	team: 'Team',
	product: 'Product Category',
	expense: 'Expense Category',
	subexpense: 'SubExpense Category',
	budgettype: 'Type',
	platform: 'Platform/Customer',
};

export { PageTitle };

import React, { Component } from 'react';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import { ISpeakerFee } from '../../schemas';
const moment = require('moment');
const numbro = require('numbro');

class MyContextMenu extends React.Component<any, any> {
	acttionbtnStyle = {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	};

	_menuItemClick = (_, item) => {
		this.props.onMenuItemClick(item?.key);
	};

	_getMenuProps = () => {
		return {
			shouldFocusOnMount: true,
			shouldFocusOnContainer: true,
			items: [
				{
					key: 'details',
					text: 'Details',
					iconProps: { iconName: 'Search' },
					onClick: this._menuItemClick,
				},
			],
		};
	};

	render() {
		const menuProps = this._getMenuProps();
		return (
			<IconButton
				persistMenu={false}
				menuProps={menuProps}
				style={this.acttionbtnStyle}
			/>
		);
	}
}

export interface IDetailsListDocumentsExampleState {
	columns: IColumn[];
	selectionDetails: string;
	isModalSelection: boolean;
	isCompactMode: boolean;
	announcedMessage?: string;
}

export interface IDocument {
	key: string;
	itemNo: string;
	hccCode: string;
	eventDate: string;
	activityType: string;
	sapVendorCode: string;
	kolName: string;
	hospital: string;
	serviceHour: number;
	amount: number;
}

export default class RequestList extends Component<
	any,
	IDetailsListDocumentsExampleState
> {
	private _selection: Selection;

	_renderItemColumn = (
		item?: ISpeakerFee,
		index?: number,
		column?: IColumn
	) => {
		if (item && column) {
			const fieldContent = item[column.fieldName as keyof ISpeakerFee];

			switch (column.fieldName) {
				case 'eventDate':
					return moment(fieldContent).format('YYYY-MM-DD');
				case 'amount':
					return numbro(+(fieldContent + '')).format({
						thousandSeparated: true,
						mantissa: 2,
					});
				default:
					return fieldContent;
			}
		} else {
			return '';
		}
	};

	constructor(props: {}) {
		super(props);

		const columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				onRender: (item: IDocument) => (
					<MyContextMenu item={item} onMenuItemClick={this._onMenuItemClick} />
				),
			},
			{
				key: 'itemNo',
				name: 'Item No.',
				fieldName: 'itemNo',
				minWidth: 80,
				isRowHeader: true,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'hccCode',
				name: 'HCC Code',
				fieldName: 'hccCode',
				minWidth: 50,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'eventDate',
				name: 'Event Date',
				fieldName: 'eventDate',
				minWidth: 50,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'activityType',
				name: 'Activity Type',
				fieldName: 'activityType',
				minWidth: 90,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'sapVendorCode',
				name: 'Sap Vendor Code',
				fieldName: 'sapVendorCode',
				minWidth: 110,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'kolName',
				name: 'KOL Name',
				fieldName: 'kolName',
				minWidth: 50,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'hospitalName',
				name: 'Hospital',
				fieldName: 'hospitalName',
				minWidth: 150,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'serviceHour',
				name: 'Service Hours',
				fieldName: 'serviceHour',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'amount',
				name: 'Amount',
				fieldName: 'amount',
				minWidth: 100,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
		];

		this._selection = new Selection({
			onSelectionChanged: () => {
				this.setState({
					selectionDetails: this._getSelectionDetails(),
				});
			},
		});

		this.state = {
			columns: columns,
			selectionDetails: this._getSelectionDetails(),
			isModalSelection: false,
			isCompactMode: false,
			announcedMessage: undefined,
		};
	}

	render() {
		const { columns, isCompactMode } = this.state;
		const { items } = this.props;

		return (
			<div
				style={{
					height: 'calc(100vh - 7rem)',
					width: '100%',
					overflow: 'auto',
					padding: '10px',
				}}
			>
				<DetailsList
					items={items}
					compact={isCompactMode}
					columns={columns}
					onRenderItemColumn={this._renderItemColumn}
					selectionMode={SelectionMode.single}
					getKey={this._getKey}
					setKey='none'
					selection={this._selection}
					layoutMode={DetailsListLayoutMode.fixedColumns}
					isHeaderVisible={true}
					// onItemInvoked={this._onItemInvoked}
				/>
			</div>
		);
	}

	componentDidUpdate(
		previousProps: any,
		previousState: IDetailsListDocumentsExampleState
	) {
		if (
			previousState.isModalSelection !== this.state.isModalSelection &&
			!this.state.isModalSelection
		) {
			this._selection.setAllSelected(false);
		}
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	private _onItemInvoked(item: any): void {
		alert(`Item invoked: ${item.name}`);
	}

	private _getSelectionDetails(): any {
		const selectionCount = this._selection.getSelectedCount();

		switch (selectionCount) {
			case 0:
				return null;
			case 1:
				return this._selection.getSelection()[0];
			default:
				return null;
		}
	}

	private _onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		const { columns } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				this.setState({
					announcedMessage: `${currColumn.name} is sorted ${
						currColumn.isSortedDescending ? 'descending' : 'ascending'
					}`,
				});
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		this.setState({
			columns: newColumns,
		});
	};

	itemOptionsClick = (data) => {
		return (e) => {
			console.log('----------------button', e, data);
		};
	};

	_onMenuItemClick = (menuKey) => {
		const selectItem = this._getSelectionDetails();
		this.setState({
			selectionDetails: selectItem,
		});
		this.props.onOptionMenuClick(menuKey, selectItem);
	};
}

function _copyAndSort<T>(
	items: T[],
	columnKey: string,
	isSortedDescending?: boolean
): T[] {
	const key = columnKey as keyof T;
	return items
		.slice(0)
		.sort((a: T, b: T) =>
			(isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
		);
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	DictList,
	DictPagination,
	DictPanelDetail,
	DictPanelEdit,
	DictPanelNew,
	DictToolbar,
} from '../../components';
import {
	deleteDictTeamAsync,
	getDictTeamsAsync,
	getDictProductsAsync,
	openLoadingDialog,
	sharedSwithPortalNav,
	deleteDictProductAsync,
	deleteDictExpenseAsync,
	getDictExpensesAsync,
	deleteDictSubExpenseAsync,
	getDictSubExpensesAsync,
	deleteDictBudgetTypeAsync,
	getDictBudgetTypesAsync,
	deleteDictPlatformAsync,
	getDictPlatformsAsync,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import './index.scss';

class Dictionaries extends Component<any, any> {
	private _pagination: any = null;
	private _searchParams: any = {};
	state = {
		isDetailOpened: false,
		isPanelNewOpened: false,
		isPanelEditOpened: false,
		selectedRow: {},
		searchText: '',
		page: 1,
	};

	/**
	 * Triggered when option menu is clicked
	 * @param menuKey The key of the option menu item
	 * @param dataItem The data item of select row
	 */
	optionMenuClick = (menuKey, dataItem: any) => {
		let deleteFunc: Function | undefined;
		const { type } = this.props.match?.params;
		switch (type) {
			case 'team':
				deleteFunc = this.props.deleteDictTeamAsync;
				break;
			case 'product':
				deleteFunc = this.props.deleteDictProductAsync;
				break;
			case 'expense':
				deleteFunc = this.props.deleteDictExpenseAsync;
				break;
			case 'subexpense':
				deleteFunc = this.props.deleteDictSubExpenseAsync;
				break;
			case 'budgettype':
				deleteFunc = this.props.deleteDictBudgetTypeAsync;
				break;
			case 'platform':
				deleteFunc = this.props.deleteDictPlatformAsync;
				break;
		}

		switch (menuKey) {
			case 'details':
				this.setState({ isDetailOpened: true, selectedRow: { ...dataItem } });
				break;
			case 'edit':
				this.setState({
					isPanelEditOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'delete':
				deleteFunc && deleteFunc(dataItem);
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		this.setState({ isDetailOpened: isOpen });
	};

	closePanelNew = (isOpen) => {
		console.log('-------------panel new dismiss');
		this.setState({ isPanelNewOpened: isOpen });
	};

	closePanelEdit = (isOpen) => {
		console.log('-------------panel edit dismiss');
		this.setState({ isPanelEditOpened: isOpen });
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isPanelNewOpened: true });
	};

	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ pageNumber: 1 });
		this.setState({ searchText, pageNumber: 1 });
		const { type } = this.props.match?.params;
		this.getData(type);
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		const { type } = this.props.match?.params;
		this.getData(type);
	};

	componentDidMount() {
		const { type } = this.props.match?.params;
		this.getData(type);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { type: currentType } = this.props.match?.params;
		const { type: nextType } = nextProps.match?.params;
		if (currentType !== nextType) {
			this.setState({ page: 1 });
			this._searchParams = {};
			this.getData(nextType);
		}
	}

	getData = (type) => {
		// const { type } = this.props.match?.params;
		this.props.openLoadingDialog({ loadingOpen: true });
		// const params = {
		// 	pageNumber: this.state.page,
		// 	searchText: this.state.searchText,
		// };

		switch (type) {
			case 'team':
				this.props.getDictTeamsAsync(this._searchParams).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
			case 'product':
				this.props.getDictProductsAsync(this._searchParams).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
			case 'expense':
				this.props.getDictExpensesAsync(this._searchParams).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
			case 'subexpense':
				this.props.getDictSubExpensesAsync(this._searchParams).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
			case 'budgettype':
				this.props.getDictBudgetTypesAsync(this._searchParams).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
			case 'platform':
				this.props.getDictPlatformsAsync(this._searchParams).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
		}
	};

	render() {
		const { type } = this.props.match.params;
		const {
			dictteams,
			dictproducts,
			dictexpenses,
			dictsubexpenses,
			dictbudgettypes,
			dictplatforms,
		} = this.props;
		let items = [];
		let pageCount = 0;
		switch (type) {
			case 'team':
				items = dictteams.data ?? [];
				pageCount = dictteams.total ?? 0;
				break;
			case 'product':
				items = dictproducts.data ?? [];
				pageCount = dictproducts.total ?? 0;
				break;
			case 'expense':
				items = dictexpenses.data ?? [];
				pageCount = dictexpenses.total ?? 0;
				break;
			case 'subexpense':
				items = dictsubexpenses.data ?? [];
				pageCount = dictsubexpenses.total ?? 0;
				break;
			case 'budgettype':
				items = dictbudgettypes.data ?? [];
				pageCount = dictbudgettypes.total ?? 0;
				break;
			case 'platform':
				items = dictplatforms.data ?? [];
				pageCount = dictplatforms.total ?? 0;
				break;
		}
		return (
			<>
				<DictToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
					dictType={type}
				/>

				<DictList items={items} onOptionMenuClick={this.optionMenuClick} />

				{this.state.isDetailOpened ? (
					<DictPanelDetail
						isOpen={this.state.isDetailOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closeDetailPanel}
						dictType={type}
					/>
				) : null}

				{this.state.isPanelNewOpened ? (
					<DictPanelNew
						isOpen={this.state.isPanelNewOpened}
						onDismiss={this.closePanelNew}
						dictType={type}
					/>
				) : null}

				{this.state.isPanelEditOpened ? (
					<DictPanelEdit
						isOpen={this.state.isPanelEditOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closePanelEdit}
						dictType={type}
					/>
				) : null}

				<DictPagination
					ref={(v) => {
						this._pagination = v;
					}}
					onPageClick={this.pageClick}
					pageCount={pageCount}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		dictteams: state.dictteams,
		dictproducts: state.dictproducts,
		dictexpenses: state.dictexpenses,
		dictsubexpenses: state.dictsubexpenses,
		dictbudgettypes: state.dictbudgettypes,
		dictplatforms: state.dictplatforms,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{
		sharedSwithPortalNav,
		openLoadingDialog,
		getDictTeamsAsync,
		getDictProductsAsync,
		getDictExpensesAsync,
		getDictSubExpensesAsync,
		getDictBudgetTypesAsync,
		getDictPlatformsAsync,
		deleteDictTeamAsync,
		deleteDictProductAsync,
		deleteDictExpenseAsync,
		deleteDictSubExpenseAsync,
		deleteDictBudgetTypeAsync,
		deleteDictPlatformAsync,
	}
)(Dictionaries);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getImportHistoriesAsync,
	openLoadingDialog,
	sharedSwithPortalNav,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	ImportBucketDetailPanel,
	ImportBucketHistoryPanel,
	ImportBucketList,
	ImportBucketPagination,
	ImportBucketToolbar,
} from '../../components';
import './index.scss';
import { getImportHistoryLogApi } from '../../api';

class ImportBuckets extends Component<any, any> {
	private _pagination: any = null;
	private _searchParams: any = {};
	state = {
		isDetailOpened: false,
		isHistoryOpened: false,
		logs: [],
		selectedRow: {},
		searchText: '',
		page: 1,
	};

	optionMenuClick = (menuKey, dataItem: any) => {
		switch (menuKey) {
			case 'history':
				getImportHistoryLogApi({ importHistoryId: dataItem.id }).then(
					(result) => {
						if (result && result.errorCode.length === 0) {
							this.setState({
								isHistoryOpened: true,
								selectedRow: { ...dataItem },
								logs: result.value,
							});
						} else {
							this.setState({
								isHistoryOpened: true,
								selectedRow: { ...dataItem },
								logs: [],
							});
						}
					}
				);
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		this.setState({ isDetailOpened: isOpen });
	};

	closeHistoryPanel = (isOpen) => {
		this.setState({ isHistoryOpened: isOpen });
	};

	switchPortalNavClick = () => {
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isDetailOpened: true });
	};

	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ pageNumber: 1 });
		this.setState({ searchText, pageNumber: 1 });
		this.getData();
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props.getImportHistoriesAsync(this._searchParams).then(() => {
			this.props.openLoadingDialog({ loadingOpen: false });
		});
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		const { importHistories } = this.props;
		return (
			<>
				<ImportBucketToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
				/>

				<ImportBucketList
					items={importHistories.data ?? importHistories}
					onOptionMenuClick={this.optionMenuClick}
				/>

				<ImportBucketDetailPanel
					isOpen={this.state.isDetailOpened}
					detail={this.state.selectedRow}
					onDismiss={this.closeDetailPanel}
				/>

				<ImportBucketHistoryPanel
					isOpen={this.state.isHistoryOpened}
					detail={this.state.selectedRow}
					onDismiss={this.closeHistoryPanel}
					logs={this.state.logs}
				/>

				<ImportBucketPagination
					ref={(v) => {
						this._pagination = v;
					}}
					onPageClick={this.pageClick}
					pageCount={this.props.importHistories.total ?? 0}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		importHistories: state.importHistories,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
	}),
	{ getImportHistoriesAsync, sharedSwithPortalNav, openLoadingDialog }
)(ImportBuckets);

import { get, post, put, del } from './comm-http';
import { IBudget, IImportHistory, IResponse } from '../schemas';

/**
 * Get the import history list
 * @param params The params used to query import history
 * @returns Import History list
 */
async function getImportHistoriesApi(
	params
): Promise<IResponse<IImportHistory[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IImportHistory[]>>('/import', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

/**
 * Get the import history logs
 * @param params The params used to query import history logs
 * @returns Import History Logs
 */
async function getImportHistoryLogApi(params): Promise<IResponse<any[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<any[]>>(`/import/log/${params.importHistoryId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Init buckets
 * @param data Bucket list
 * @returns Import result
 */
async function initBucketsApi(data: IBudget[]): Promise<IResponse<string>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IBudget[], IResponse<string>>('/import/init', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update buckets
 * @param data Bucket list
 * @returns Import result
 */
async function updateBucketsApi(data: IBudget[]): Promise<IResponse<string>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IBudget[], IResponse<string>>('/import/update', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}
export {
	getImportHistoriesApi,
	getImportHistoryLogApi,
	initBucketsApi,
	updateBucketsApi,
};

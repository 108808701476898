import { get, post, put, del } from './comm-http';
import { IDictItem, IResponse } from '../schemas';

/**
 * Get the dictProduct list
 * @param params The params used to query dictProduct
 * @returns DictProduct list
 */
async function getDictProductsApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/product', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getDictProductByIdApi(
	id: string
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem>>(`/dict/product/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new dictProduct
 * @param data DictProduct data
 * @returns DictProduct data from backend service after the dictProduct is created
 */
async function addDictProductApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IDictItem, IResponse<IDictItem>>('/dict/product', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a dictProduct
 * @param data DictProduct data
 * @returns DictProduct data from backend service after the dictProduct is updated
 */
async function updateDictProductApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IDictItem, IResponse<IDictItem>>(
		`/dict/product/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a dictProduct
 * @param data DictProduct data
 * @returns DictProduct data from backend service after the dictProduct is updated
 */
async function deleteDictProductApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IDictItem>>(`/dict/product/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getDictProductsApi,
	getDictProductByIdApi,
	addDictProductApi,
	updateDictProductApi,
	deleteDictProductApi,
};

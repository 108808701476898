//@ts-nocheck
import React, { Component } from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { getSpeakerApi } from '../../api';
import {
	TextField,
	ITextFieldStyles,
	ITextFieldProps,
} from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import {
	IconButton,
	PrimaryButton,
	DefaultButton,
} from '@fluentui/react/lib/Button';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { SearchBox } from '@fluentui/react/lib/SearchBox';

import './TextSearch.scss';
interface ITextSearchProps extends ITextFieldProps {
	//searchType="vendorcode,hospital,kolname"
	searchType?: string;
	afterSaved?: (items, searchType) => void;
	onChange?: (v, searchType) => void;
}

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const gridStyles: Partial<IDetailsListStyles> = {
	root: {
		overflowX: 'scroll',
		selectors: {
			'& [role=grid]': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				height: '22vh',
			},
		},
	},
	headerWrapper: {
		flex: '0 0 auto',
	},
	contentWrapper: {
		flex: '1 1 auto',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
};

export default class TextSearch extends Component<ITextSearchProps, any> {
	private selection: Selection;
	state = {
		hideMonacoDialog: true,
		filterText: '',
		items: [],
		selectSubItems: [],
		errormessage:0,
	};

	constructor(props: ITextSearchProps) {
		super(props);
		this.selection = new Selection({
			onSelectionChanged: () => {
				const items = this.selection.getSelection();
				this.setState({
					selectSubItems: items,
				});
			},
		});
	}

	onWrapDefaultLabelRenderer = (
		props: ITextFieldProps,
		defaultRender: IRenderFunction<ITextFieldProps>
	) => {
		return (
			<>
				<Stack horizontal verticalAlign='center'>
					<span>{defaultRender(props)}</span>
					<IconButton
						iconProps={{ iconName: 'Search' }}
						title='Search'
						onClick={this.iconClick}
						styles={{ root: { height: '30px', width: '30px' } }}
					/>
				</Stack>
			</>
		);
	};

	iconClick = () => {
		this.setState({ hideMonacoDialog: false });
	};

	handleClose = () => {
		this.setState({ hideMonacoDialog: true });
	};

	handleMonacoSave = () => {
		if (this.state.selectSubItems && this.state.selectSubItems.length > 0) {
			if (this.props.afterSaved) {
				this.props.afterSaved(this.state.selectSubItems, this.props.searchType);
			}
			this.setState({ hideMonacoDialog: true });
		}
	};
	onSearch = (newValue) => {
		const params = {
			searchText: this.state.filterText,
			searchType: this.props.searchType,
		};
		getSpeakerApi(params).then((data) => {
			this.setState({ items: data.value });
		});
		this.state.errormessage=1;
	};

	onSeachChange = (
		ev: React.ChangeEvent<HTMLInputElement>,
		newValue: string
	) => {
		this.setState({
			filterText: newValue,
		});
	};

	renderContent = () => {
		return (
			<div>
				<Dialog
					hidden={this.state.hideMonacoDialog}
					maxWidth={700}
					onDismiss={this.handleClose}
					dialogContentProps={{
						type: DialogType,
						title: this.props.label,
					}}
					styles={{
						root: {
							selectors: {
								'.ms-Dialog-main': {
									width: '700px !important',
									maxwidth: 'none !important',
									maxHeight: '400px',
									minHeight: '400px',
									height: '400px',
								},
							},
						},
					}}
				>
					<div>
						{/* <div style={{ display: 'flex' }}>
                            <TextField style={{ width: '200px' }} placeholder="Search" onChange={this.onSeachChange} />
                            <IconButton iconProps={{ iconName: 'Search' }} title="Search" onClick={(e) => {
                                this.onSearch()
                            }} />
                        </div> */}
						<SearchBox
							placeholder='Search'
							onChange={this.onSeachChange}
							onSearch={(newValue) => this.onSearch(newValue)}
						/>

						<div
							style={{
								minHeight: '200px',
								width: '100%',
								overflow: 'auto',
								padding: '10px',
							}}
						>
								 {this.state.errormessage==1&& this.state.items.length==0?
								<small
		style={{color:'#A01C22'}}
		> If can't find the HCP on the system, please contact Holly (hcao26@its.jnj.com), thanks.</small>	
	 :null}
							<DetailsList
								items={this.state.items}
								compact={false}
								selection={this.selection}
								columns={[
									{
										key: 'vendorCode',
										name: 'Vendor Code',
										fieldName: 'vendorCode',
										minWidth: 100,
										maxWidth: 200,
										isResizable: true,
									},
									{
										key: 'name',
										name: 'KOL Name',
										fieldName: 'name',
										minWidth: 100,
										maxWidth: 200,
										isResizable: true,
									},
									{
										key: 'company',
										name: 'Hospital',
										fieldName: 'company',
										minWidth: 100,
										maxWidth: 200,
										isResizable: true,
									},
								]}
								selectionMode={SelectionMode.single}
								layoutMode={DetailsListLayoutMode.fixedColumns}
								isHeaderVisible={true}
								styles={gridStyles}
								// onItemInvoked={this._onItemInvoked}
							/>
						</div>
					</div>
					<DialogFooter>
						<PrimaryButton onClick={this.handleMonacoSave} text='OK' />
						<DefaultButton onClick={this.handleClose} text='Close' />
					</DialogFooter>
				</Dialog>
			</div>
		);
	};
	onChange = (e, v) => {
		if (this.props.onChange) {
			this.props.onChange(v, this.props.searchType);
		}
	};
	render() {
		const { label, value } = this.props;
		return (
			<>
				<TextField
					disabled
					label={label}
					onChange={this.onChange}
					onRenderLabel={this.onWrapDefaultLabelRenderer}
					value={value}
				/>
				{this.state.hideMonacoDialog ? null : <>{this.renderContent()}</>}
			</>
		);
	}
}

import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import numbro from 'numbro';

const moment = require('moment');

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const options: IDropdownOption[] = [
	{ key: 'Ella', text: 'Ella' },
	{ key: 'Candy', text: 'Candy' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

export default class SpeakerFeeDetailPanel extends Component<any> {
	closeDetailPanel = () => {
		console.log('-------------panel dismiss');
		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle}>
					Cancel
					</DefaultButton>
				<PrimaryButton styles={buttonStyle}>
			    Save
					</PrimaryButton>
			</div>
		);
	};

	render() {
		console.log('-------detail panel', this.props);
		const { detail } = this.props;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closeDetailPanel}
					type={PanelType.medium}
					headerText=	'Speaker Data Detail'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Type'
									disabled
									value={detail.district}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Vendor Code'
									disabled
									value={detail.vendorCode}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='HCP Name'
									disabled
									value={detail.name}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
							<Stack.Item grow>
								<TextField
									label='City'
									disabled
									value={detail.province}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='HCO Name'
								disabled
								value={detail.company}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Title'
									disabled
									value={detail.jobTitle}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Member of Academic Society'
								disabled
								value={detail.societyMember}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Education and Training Background'
									disabled
									value={detail.educational}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Work/Specialty Experience'
								disabled
								value={detail.workYear}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Publications'
									disabled
									value={detail.publish}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Title Score'
								disabled
								value={detail.jobTitleScore}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Member of Academic Society Score'
									disabled
									value={detail.societyMemberScore}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Education and Training Background Score'
								disabled
								value={detail.educationalScore}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Work/Specialty Experience Score'
									disabled
									value={detail.workYearScore}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Publications Score'
								disabled
								value={detail.publishScore}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Total Score'
									disabled
									value={detail.totalScore}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='J&J Level'
								disabled
								value={detail.jnjLevel}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='KOL stature'
									disabled
									value={detail.expertLevel}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>


						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Service Agreement No.'
								disabled
								value={detail.serviceAgreementNumber}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Contract start date'
									disabled
									value={detail.contractStartDate}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
								label='Contract end date'
								disabled
								value={detail.contractExpirationDate}
								borderless
								styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Specialty Segment'
									disabled
									value={detail.expertise}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
							<Stack.Item grow>
								<TextField
									label='HCP Specialty'
									disabled
									value={detail.expertiseArea}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

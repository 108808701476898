import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getRequestsForApprovalAsync,
	openLoadingDialog,
	sharedSwithPortalNav,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	ApprovalDetailPanel,
	ApprovalList,
	ApprovalPagination,
	ApprovalToolbar,
} from '../../components';
import './index.scss';
import { getRequestDestailsApi } from '../../api';

class Approvals extends Component<any, any> {
	private _pagination: any = null;
	private _searchParams: any = {};

	state = {
		isDetailOpened: false,
		selectedRow: {},
		searchText: '',
		page: 1,
		history: [],
		groupRequestDestails: []
	};

	/**
	 * Triggered when option menu is clicked
	 * @param menuKey The key of the option menu item
	 * @param dataItem The data item of select row
	 */
	optionMenuClick = (menuKey, dataItem: any) => {
		console.log('::::::::::::::::menuKey', menuKey, dataItem);
		switch (menuKey) {
			case 'details':
				getRequestDestailsApi({ requestId: dataItem.groupRequestId }).then(
					(result) => {
						if (result && result.value) {
							this.setState({
								isDetailOpened: true,
								selectedRow: { ...dataItem },
								groupRequestDestails: (result.value as any).groupRequestDestails,
								history: (result.value as any).approvalHistoryData,
							});
						} else {
							this.setState({
								isDetailOpened: true,
								selectedRow: { ...dataItem },
								history: [],
								groupRequestDestails: []
							});
						}
					}
				);
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		console.log('-------------panel dismiss');
		this.setState({ isDetailOpened: isOpen });
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isDetailOpened: true });
	};

	componentDidMount() {
		this.getData();
	}

	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ page: 1 });
		this.setState({ searchText, page: 1 });
		this.getData();
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props.getRequestsForApprovalAsync(this._searchParams).then(() => {
			this.props.openLoadingDialog({ loadingOpen: false });
		});
	};

	render() {
		const { requests } = this.props;
		return (
			<>
				<ApprovalToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
				/>

				<ApprovalList
					items={requests.data ?? requests}
					onOptionMenuClick={this.optionMenuClick}
				/>

				<ApprovalDetailPanel
					isOpen={this.state.isDetailOpened}
					detail={this.state.selectedRow}
					onDismiss={this.closeDetailPanel}
					history={this.state.history}
					groupRequestDestails={this.state.groupRequestDestails}
				/>

				<ApprovalPagination
					ref={(v) => {
						{
							this._pagination = v;
						}
					}}
					onPageClick={this.pageClick}
					pageCount={this.props.requests.total ?? 10}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		requests: state.requests,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{ getRequestsForApprovalAsync, sharedSwithPortalNav, openLoadingDialog }
)(Approvals);

import { GET_DICTIONARY } from '../constant';
import { getDictionaryApi } from '../../api';

export const getDictionary = (data) => ({
	type: GET_DICTIONARY,
	data,
});

export const getDictionaryAsync = (params) => {
	return (dispatch) => {
		return getDictionaryApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					dispatch(getDictionary(data.value));
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

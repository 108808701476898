import React, { Component } from 'react';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import { IUser } from '../../schemas';

class MyContextMenu extends React.Component<any, any> {
	acttionbtnStyle = {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	};

	_menuItemClick = (_, item) => {
		this.props.onMenuItemClick(item?.key);
	};

	_getMenuProps = () => {
		return {
			shouldFocusOnMount: true,
			shouldFocusOnContainer: true,
			items: [
				{
					key: 'details',
					text: 'Details',
					iconProps: { iconName: 'Search' },
					onClick: this._menuItemClick,
				},
				{
					key: 'edit',
					text: 'Edit',
					iconProps: { iconName: 'Edit' },
					onClick: this._menuItemClick,
				},
				{
					key: 'delete',
					text: 'Delete',
					iconProps: { iconName: 'Delete' },
					onClick: this._menuItemClick,
				},
			],
		};
	};

	render() {
		const menuProps = this._getMenuProps();
		return (
			<IconButton
				persistMenu={false}
				menuProps={menuProps}
				style={this.acttionbtnStyle}
			/>
		);
	}
}

export interface ISubDepartmentState {
	columns: IColumn[];
	selectionDetails: string;
	announcedMessage?: string;
}

export default class SubDepartmentList extends Component<any, ISubDepartmentState> {
	private _selection: Selection;

	constructor(props: {}) {
		super(props);

		const columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				fieldName: 'optionMenu',

				onRender: (item: IUser) => (
					<MyContextMenu item={item} onMenuItemClick={this._onMenuItemClick} />
				),
			},
			{
				key: 'team',
				name: 'Team',
				fieldName: 'name',
				minWidth: 50,
				maxWidth: 90,
				isResizable: true,
				data: 'number',
				isPadded: true,
			},
			{
				key: 'department',
				name: 'Department',
				fieldName: 'department',
				minWidth: 50,
				maxWidth: 90,
				isResizable: true,
				isCollapsible: true,
				data: 'number',
				isPadded: true,
			},
		];

		this._selection = new Selection({
			onSelectionChanged: () => {
				this.setState({
					selectionDetails: this._getSelectionDetails(),
				});
			},
		});

		this.state = {
			columns: columns,
			selectionDetails: this._getSelectionDetails(),
			announcedMessage: undefined,
		};
	}

	render() {
		const { columns } = this.state;

		const { items } = this.props;

		return (
			<div
				style={{
					height: 'calc(100vh - 7rem)',
					width: '100%',
					overflow: 'auto',
					padding: '10px',
				}}
			>
				<DetailsList
					items={items}
					columns={columns}
					selectionMode={SelectionMode.single}
					getKey={this._getKey}
					setKey='none'
					selection={this._selection}
					layoutMode={DetailsListLayoutMode.fixedColumns}
					isHeaderVisible={true}
				/>
			</div>
		);
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	private _getSelectionDetails(): any {
		const selectionCount = this._selection.getSelectedCount();

		switch (selectionCount) {
			case 0:
				return null;
			case 1:
				return this._selection.getSelection()[0];
			default:
				return null;
		}
	}

	_onMenuItemClick = (menuKey) => {
		const selectItem = this._getSelectionDetails();
		this.setState({
			selectionDetails: selectItem,
		});
		this.props.onOptionMenuClick(menuKey, selectItem);
	};
}
import React, { Component } from "react";
import { Box, Text, Toolbar } from "@fluentui/react-northstar";
import { ArrowLeftIcon, ArrowRightIcon } from "@fluentui/react-icons-northstar";
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';

const MY_PAGE_ACTION = {
	PrePage: "prepage",
	NextPage: "nextpage",
};

export default class UserPagination extends Component<any> {
	page: number = 1;
	state = {
		page: 1,
		goPage: "",
	};


	gotoPage = (action) => {
		switch (action) {
			case MY_PAGE_ACTION.PrePage:
				this.page = this.state.page <= 1 ? 1 : this.state.page - 1;
				break;
			case MY_PAGE_ACTION.NextPage:
				this.page = this.state.page + 1 > this.props.pageCount ? this.props.pageCount : this.state.page + 1;
				break;
		}
		this.setState({ page: this.page })

		return (e) => {
			this.props.onPageClick(this.page);
		};
	};

	goChange = (e, v) => {
		const data = v.replace(/[^0-9]/g, '');
		this.setState({ goPage: data })
	}



	onKeyDodwn = (e) => {
		if (e.keyCode == 13) {
			if (this.state.goPage != "" && +this.state.goPage >= 1 && this.state.goPage <= this.props.pageCount) {
				this.setState({ page: this.state.goPage })
				this.props.onPageClick(this.state.goPage);
			}

		};
	}

	render() {
		return (
			<>
				<Box
					styles={{
						display: "flex",
						height: "3rem",
						justifyContent: "center",
						boxShadow: "0px -5px 5px rgba(0, 0, 0, .2)",
						marginTop: "1rem",
					}}
				>
					<Toolbar
						items={[
							{
								key: "preBtn",
								icon: <ArrowLeftIcon {...{ outline: true }} />,
								onClick: (e) => this.gotoPage(MY_PAGE_ACTION.PrePage)(e),
							},
							{
								key: "pageTxt",
								content: <>
									<Text content={`Total Pages ${this.props.pageCount ?? 0} , Page ${this.state.page} , `} />
									<span style={{ paddingLeft: 5, paddingRight: 5 }}>GO</span>
									<TextField styles={{ fieldGroup: { height: '20px' } }} style={{ width: '40px', height: '20px', lineHeight: '32px', paddingLeft: 0, border: '1px solid #ccc' }}
										onChange={this.goChange}
										value={this.state.goPage + ""}
										onKeyDown={this.onKeyDodwn}
									></TextField>
								</>,
								kind: "custom",
							},

							{
								key: "nextBtn",
								icon: <ArrowRightIcon {...{ outline: true }} />,
								onClick: (e) => this.gotoPage(MY_PAGE_ACTION.NextPage)(e),
							},
						]}
					/>
				</Box>
			</>
		);
	}
}


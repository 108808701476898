import React, { Component } from 'react';
import { Box, Button, Input, Text, Toolbar } from '@fluentui/react-northstar';
import { AddIcon, MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react/lib/Button';

export default class ImportBucketToolbar extends Component<any, any> {
	state = {
		searchText: '',
	};

	switchPortalNavClick = (event) => {
		this.props.onSwitchPortalNavClick();
	};

	newRecordBtnClick = (event) => {
		this.props.onNewRecordBtnClick();
	};

	searchTextChanged = (e) => {
		this.setState({
			searchText: e.target.value,
		});
	};

	searchClick = (e) => {
		if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
			if (this.props.searchClick) {
				const searchParams = {
					...this.state,
				};
				this.props.searchClick(searchParams);
			}
		}
	};

	render() {
		return (
			<>
				<div
					style={{
						width: '100%',
						height: '45px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						boxShadow: '0px 5px 5px rgba(0, 0, 0, .2)',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<MenuIcon
							{...{
								outline: true,
							}}
							styles={{
								fontWeight: 'bold',
								fontSize: '1rem',
								paddingLeft: '5px',
								cursor: 'pointer',
							}}
							onClick={this.switchPortalNavClick}
						/>
						<Text
							content='Import Buckets'
							styles={{
								fontWeight: 'bold',
								fontSize: '1rem',
								paddingLeft: '25px',
							}}
						/>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Button
							icon={<AddIcon />}
							text
							content='Import Buckets'
							onClick={this.newRecordBtnClick}
						/>
						{/* <TextField
							borderless
							placeholder='Search...'
							styles={{
								root: { marginLeft: '10px', borderBottom: 'solid 2px #6264a7' },
							}}
							onChange={(e, v) => {
								this.setState({ searchText: v });
							}}
							value={this.state.searchText}
							onKeyDown={this.searchClick}
						/>

						<IconButton
							iconProps={{ iconName: 'Search' }}
							styles={{ icon: { color: '#6264a7' } }}
							onClick={this.searchClick}
						/> */}
					</div>
				</div>
			</>
		);
	}
}

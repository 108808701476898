import { GET_CURRENTUSER_PROFILE } from '../constant';
import { getUserProfileApi } from '../../api';

export const getCurrentUser = (data) => ({
	type: GET_CURRENTUSER_PROFILE,
	data,
});


// export const getCurrentUserAsync = async () => {
// 	return (dispatch) => {
// 		return getUserProfileApi()
// 			.then((data) => {
// 				if (data.errorCode.trim().length === 0) {
// 					dispatch(getCurrentUser(data.value));
// 					return Promise.resolve(data.value);
// 				} else {
// 					return Promise.reject(data.errorMessage);
// 					console.error(`${data.errorCode}:: ${data.errorMessage}`);
// 				}
// 			})
// 			.catch((error) => {
// 				return Promise.reject(error);
// 				//	throw error;
// 			});
// 	};
// };

export const getCurrentUserAsync = () => async (dispatch) => {

	try {
		const response = await getUserProfileApi();
		if (response.errorCode.trim().length === 0) {
			console.log(':::::::::::updatePoDataAsync', response.value);
			dispatch(getCurrentUser(response.value));
			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

import { IAction } from '../../schemas';
import { GET_IMPORTHISTORY_LIST, GET_IMPORTHISTORY } from '../constant';

const initState: any = {};

export default function importHistoryReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case GET_IMPORTHISTORY_LIST:
			return { ...data };
		case GET_IMPORTHISTORY:
			const newdata = [data, ...preState.data];
			return { data: newdata, total: preState.total };
		default:
			return preState;
	}
}

import React, { Component } from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import moment from 'moment';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackStyles: IStackStyles = {
	root: {
		paddingTop: '1rem',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

export default class ImportBucketHistoryPanel extends Component<any> {
	closeDetailPanel = () => {
		console.log('-------------panel dismiss');
		this.props.onDismiss(false);
	};

	renderLogs = () => {
		if (this.props.logs && this.props.logs.length > 0) {
			return (
				<Stack styles={stackStyles} tokens={stackTokens}>
					<Stack.Item grow className='sub-header'>
						<Text variant={'large'}>Logs</Text>
					</Stack.Item>

					<ul>
						{this.props.logs.map((v) => {
							return (
								<li key={v.id}>
									{' '}
									<Stack.Item grow>
										<span>{v.message}</span>
									</Stack.Item>
								</li>
							);
						})}
					</ul>
				</Stack>
			);
		}
	};

	render() {
		const { createdByName, createdTime, importType, status } =
			this.props.detail;
		const utcCreatedTime =
			createdTime?.indexOf('Z') > -1 ? createdTime : createdTime + 'Z';
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closeDetailPanel}
					type={PanelType.medium}
					headerText='Import History'
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Import Date:{' '}
								</span>
								<span>
									{moment(utcCreatedTime).format('YYYY-MM-DD HH:mm:ss')}
								</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Import By:{' '}
								</span>
								<span>{createdByName}</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Import Type:{' '}
								</span>
								<span>{importType}</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Status:{' '}
								</span>
								<span>{status}</span>
							</Stack.Item>
						</Stack>

						{this.renderLogs()}
					</Stack>
				</Panel>
			</>
		);
	}
}

import React, { Component } from 'react';
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import { Redirect, Route, Switch } from 'react-router';
import { PortalTab, Login, LoginAuth, LoginAuthEnd } from './pages';
import { getUserProfileApi } from "./api/user"
export default class App extends Component {
	state = {
		theme: teamsTheme,
	};

	render() {
		return (
			<Provider theme={this.state.theme} lang='en-US'>
				<Switch>
					<Route path='/portal' component={PortalTab} />
					<Route path='/auth-end' exact component={LoginAuthEnd} />
					<Route path='/auth' exact component={LoginAuth} />
					<Route exact path='/JJVCBME2022' component={Login} />
					<Route exact path='/' component={LoginAuth} />
				</Switch>
			</Provider>
		);
	}
}
import axios from 'axios';

const commAxios = axios.create({
	baseURL: '/api',
	timeout: 100000,
});

/**
 * http request 拦截器
 */
commAxios.interceptors.request.use(
	(config) => {
		config.data = JSON.stringify(config.data);
		config.headers = {
			...config.headers,
			'Content-Type': 'application/json',
		};
		console.log('------------interceptors', config);
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

/**
 * http response 拦截器
 */
commAxios.interceptors.response.use(
	(response) => {
		console.log('----------------interceptors rep', response);
		if (response.data.errCode === 2) {
			console.log('过期');
		}
		return response;
	},
	(error) => {
		console.log('请求出错：', error);
	}
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get<T>(url: string, config: any = {}): Promise<T> {
	return new Promise((resolve, reject) => {
		commAxios
			.get<T>(url, config)
			.then((response) => {
				console.log('--------------response', response);
				if (response?.data) {
					resolve(response.data);
				} else {
					resolve({
						errorCode: '500',
						errorMessage: 'Server error',
					} as any);
				}
				// landing(url, config, response.data);
			})
			.catch((error) => {
				console.log('--------------response error', error);
				reject(error);
			});
	});
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post<D, T>(url: string, data: D, config: any = {}): Promise<T> {
	console.log('------------post', config);
	return new Promise((resolve, reject) => {
		commAxios.post<T>(url, data, config).then(
			(response) => {
				//关闭进度条
				resolve(response.data);
			},
			(err) => {
				reject(err);
			}
		);
	});
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url: string, data = {}) {
	return new Promise((resolve, reject) => {
		commAxios.patch(url, data).then(
			(response) => {
				resolve(response.data);
			},
			(err) => {
				msag(err);
				reject(err);
			}
		);
	});
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put<D, T>(url: string, data: D, config: any = {}): Promise<T> {
	return new Promise((resolve, reject) => {
		commAxios.put<T>(url, data, config).then(
			(response) => {
				//关闭进度条
				resolve(response.data);
			},
			(err) => {
				reject(err);
			}
		);
	});
}

/**
 * 封装delete请求
 * @param url
 * @returns {Promise}
 */
export function del<T>(url: string, config: any = {}): Promise<T> {
	return new Promise((resolve, reject) => {
		commAxios.delete<T>(url, config).then(
			(response) => {
				//关闭进度条
				resolve(response.data);
			},
			(err) => {
				reject(err);
			}
		);
	});
}

//统一接口处理，返回数据
export default function request(fecth: any, url: any, param: any) {
	let _data = '';
	return new Promise((resolve, reject) => {
		switch (fecth) {
			case 'get':
				console.log('begin a get request,and url:', url);
				get(url, param)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						console.log('get request GET failed.', error);
						reject(error);
					});
				break;
			case 'post':
				post(url, param)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						console.log('get request POST failed.', error);
						reject(error);
					});
				break;
			default:
				break;
		}
	});
}

//失败提示
function msag(err: any) {
	if (err && err.response) {
		switch (err.response.status) {
			case 400:
				alert(err.response.data.error.details);
				break;
			case 401:
				alert('未授权，请登录');
				break;

			case 403:
				alert('拒绝访问');
				break;

			case 404:
				alert('请求地址出错');
				break;

			case 408:
				alert('请求超时');
				break;

			case 500:
				alert('服务器内部错误');
				break;

			case 501:
				alert('服务未实现');
				break;

			case 502:
				alert('网关错误');
				break;

			case 503:
				alert('服务不可用');
				break;

			case 504:
				alert('网关超时');
				break;

			case 505:
				alert('HTTP版本不受支持');
				break;
			default:
		}
	}
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url: string, params: any, data: any) {
	console.log('--------------landing', data);
	if (data.code === -1) {
	}
}

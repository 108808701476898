export const SHARE_NAV_SWITCH = 'share_nav_switch';

export const ADD_TEMPLATE = 'add_template';

export const GET_BUDGET_LIST = 'get_budget_list';
export const ADD_BUDGET = 'add_budget';
export const UPDATE_BUDGET = 'update_budget';
export const DELETE_BUDGET = 'delete_budget';
export const UPDATE_SP_BUDGET = 'update_sp_budget';

export const GET_SPEAKERFEE_LIST = 'get_speakerfee_list';
export const ADD_SPEAKERFEE = 'add_speakerfee';
export const UPDATE_SPEAKERFEE = 'update_speakerfee';
export const DELETE_SPEAKERFEE = 'delete_speakerfee';

export const GET_REQUEST_LIST = 'get_request_list';
export const ADD_REQUEST = 'add_request';
export const UPDATE_REQUEST = 'update_request';
export const DELETE_REQUEST = 'delete_request';

export const GET_PODATA_LIST = 'get_podata_list';
export const UPDATE_PODATA = 'update_podata';

export const GET_SAPDATA_LIST = 'get_sapdata_list';
export const UPDATE_SAPDATA = 'update_sapdata';

export const GET_SPEAKER_LIST = 'get_speaker_list';
export const UPDATE_SPEAKERDATA = 'update_speakerdata';

export const GET_USER_LIST = 'get_user_list';
export const ADD_USER = 'add_user';
export const UPDATE_USER = 'update_user';
export const DELETE_USER = 'delete_user';

export const GET_SUBDEPARTMENT_LIST = 'get_subdepartment_list';
export const ADD_SUBDEPARTMENT = 'add_subdepartment';
export const UPDATE_SUBDEPARTMENT = 'update_subdepartment';
export const DELETE_SUBDEPARTMENT = 'delete_subdepartment';

export const GET_DICTTEAM_LIST = 'get_dictteam_list';
export const ADD_DICTTEAM = 'add_dictteam';
export const UPDATE_DICTTEAM = 'update_dictteam';
export const DELETE_DICTTEAM = 'delete_dictteam';

export const GET_DICTPRODUCT_LIST = 'get_dictproduct_list';
export const ADD_DICTPRODUCT = 'add_dictproduct';
export const UPDATE_DICTPRODUCT = 'update_dictproduct';
export const DELETE_DICTPRODUCT = 'delete_dictproduct';

export const GET_DICTEXPENSE_LIST = 'get_dictexpense_list';
export const ADD_DICTEXPENSE = 'add_dictexpense';
export const UPDATE_DICTEXPENSE = 'update_dictexpense';
export const DELETE_DICTEXPENSE = 'delete_dictexpense';

export const GET_DICTSUBEXPENSE_LIST = 'get_dictsubexpense_list';
export const ADD_DICTSUBEXPENSE = 'add_dictsubexpense';
export const UPDATE_DICTSUBEXPENSE = 'update_dictsubexpense';
export const DELETE_DICTSUBEXPENSE = 'delete_dictsubexpense';

export const GET_DICTBUDGETTYPE_LIST = 'get_dictbudgettype_list';
export const ADD_DICTBUDGETTYPE = 'add_dictbudgettype';
export const UPDATE_DICTBUDGETTYPE = 'update_dictbudgettype';
export const DELETE_DICTBUDGETTYPE = 'delete_dictbudgettype';

export const GET_DICTPLATFORM_LIST = 'get_dictplatform_list';
export const ADD_DICTPLATFORM = 'add_dictplatform';
export const UPDATE_DICTPLATFORM = 'update_dictplatform';
export const DELETE_DICTPLATFORM = 'delete_dictplatform';

export const GET_CURRENTUSER_PROFILE = 'get_currentuser_profile';

export const GET_COORDINATOR_LIST = 'get_coordinator_list';
export const GET_BUDGETOWNER_LIST = 'get_budgetowner_list';
export const GET_PIC_LIST = 'get_pic_list';

export const OPEN_LOADING = 'open_loading';
export const GET_TEAM_MAPPINGS = 'get_team_mappings';

export const GET_DICTIONARY = 'get_dictionary';

export const GET_IMPORTHISTORY_LIST = 'get_importhistory_list';
export const GET_IMPORTHISTORY = 'add_importhistory';

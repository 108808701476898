import rawHttp from './axios-http';

function uploadFileApi(file, onUploadProgress) {
	const token = localStorage.getItem('teamsAuthToken');
	let formData = new FormData();
	formData.append('file', file);

	return rawHttp.post('/import/visionengine', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
		onUploadProgress,
	});
}

function uploadPoFileApi(file, onUploadProgress): Promise<any> {
	const token = localStorage.getItem('teamsAuthToken');
	let formData = new FormData();
	formData.append('file', file);

	return rawHttp.post('/import/visionengine/po', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
		onUploadProgress,
	});
}

function uploadSapFileApi(file, onUploadProgress): Promise<any> {
	const token = localStorage.getItem('teamsAuthToken');
	let formData = new FormData();
	formData.append('file', file);

	return rawHttp.post('/import/visionengine/sap', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
		onUploadProgress,
	});
}

function uploadSpeakerFileApi(file, onUploadProgress): Promise<any> {
	const token = localStorage.getItem('teamsAuthToken');
	
	let formData = new FormData();
	formData.append('file', file);

	return rawHttp.post('/import/visionengine/speaker', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
		onUploadProgress,
	});
}
export { uploadFileApi, uploadPoFileApi, uploadSapFileApi,uploadSpeakerFileApi };

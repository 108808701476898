import { get } from './comm-http';
import { IBudget, IResponse } from '../schemas';

/**
 * Synconize the SPO data
 * @param params The params used for sync params
 */
async function getDashbordApi(): Promise<any> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<any>>('/dashboard/request', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export { getDashbordApi };

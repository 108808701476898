import { getDictTeaMappingsApi } from '../../api';
import {
	OPEN_LOADING,
	GET_TEAM_MAPPINGS
} from '../constant';


export const openLoading = (data) => ({
	type: OPEN_LOADING,
	data,
});

export const getTeamMappings = (data) => ({
	type: GET_TEAM_MAPPINGS,
	data,
});

export const openLoadingDialog = (params) => {
	return (dispatch) => {
		dispatch(
			openLoading(params)
		);
	}

};

export const getTeamMappingsAsync = (params) => {
	return (dispatch) => {
		return getDictTeaMappingsApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					// const data = [{ name: "CC", depatment: "CC-1" },
					// { name: "CC", depatment: "CC-2" },
					// { name: "NR", depatment: "NR-1" },
					// { name: "NR", depatment: "NR-2" },
					// { name: "NR", depatment: "NR-3" },]
					dispatch(getTeamMappings(data.value));
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};
import React, { Component } from 'react';
import {
	DatePicker,
	DayOfWeek,
	IDatePickerStyles,
	Dropdown,
	IDropdownOption,
	mergeStyles,
	defaultDatePickerStrings,
	IDropdownStyles,
	MessageBar,
	MessageBarType,
} from '@fluentui/react';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { SpinButton, ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { connect } from 'react-redux';
import { IStore } from '../../schemas';
import { addSpeakerFeeAsync, updateSpeakerFeeAsync } from '../../redux/actions';
import { getSpeakerApi } from '../../api/speaker';
import TextSearch from '../TextSearch/TextSearch';
import { kolStature, activityType } from "../options";
const moment = require('moment');

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

const spinButtonSytles: Partial<ISpinButtonStyles> = {
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	label: { fontWeight: 'normal', color: '#484644' },
	spinButtonWrapper: {
		borderColor: 'transparent',
		position: 'static',
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

class SpeakerFeeSubNewPanel extends Component<any, any> {
	state = {
		speakerfee: {} as any,
		messageBar: { show: false, msg: '' }
	};

	closePanel = () => {
		this.props.onDismiss('new');
	};

	onTextChanged = (e, v, name) => {
		const { speakerfee } = this.state;
		let value = 0;
		if (name == 'serviceHour') {
			this.setState({
				speakerfee: { ...speakerfee, serviceHour: (v + "").replace(/[^\d.]/g, '') },
			});
		} else if (name == "rate") {
			this.setState({
				speakerfee: { ...speakerfee, rate: (v + "").replace(/[^\d.]/g, '') },
			});
		} else if (name == "amount") {
			this.setState({
				speakerfee: { ...speakerfee, amount: (v + "").replace(/[^\d.]/g, '') },
			});
		} else if (name === "kolStature") {
			const val = v.text;
			let rangeOfRate = "";
			switch (val) {
				case "国际KOL":
					rangeOfRate = "RMB 0 - 3370 / 小时";
					break;
				case "全国KOL":
					rangeOfRate = "RMB 0 - 2400 / 小时";
					break;
				case "本地KOL":
					rangeOfRate = "RMB 0 - 1730 / 小时";
					break;
				case "非KOL":
					rangeOfRate = "RMB 0 - 1390 / 小时";
					break;
				default:
					rangeOfRate = "";
					break;
			}
			this.setState({
				speakerfee: { ...speakerfee, [name]: val, "rangeOfRate": rangeOfRate },
			});
		} else {
			this.setState({
				speakerfee: { ...speakerfee, [name]: v },
			});
		}
	};
	preCheck = () => {
		const { speakerfee } = this.state;
		const { budgetDetail } = this.props;
		console.log(budgetDetail)
		let check = true;
		if (!speakerfee.hccCode || speakerfee.hccCode == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "HCC Code"' } });
		} else if (!speakerfee.eventDate || speakerfee.eventDate == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "Event Date"' } });
		} else if (!speakerfee.activityType || speakerfee.activityType == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "Activity Type"' } });
		} else if (!speakerfee.sapVendorCode || speakerfee.sapVendorCode == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "SAP Vendor Code"' } });
		} else if (!speakerfee.kolName || speakerfee.kolName == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "KOL Name"' } });
		} else if (!speakerfee.hospitalName || speakerfee.hospitalName == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "Hospital"' } });
		} else if (!speakerfee.rate || speakerfee.rate == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "Rate per hour Range"' } });
		} else if (!speakerfee.serviceHour || speakerfee.serviceHour == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "Service Hours"' } });
		} else if (!speakerfee.amount || speakerfee.amount == "") {
			check = false;
			this.setState({ messageBar: { show: true, msg: 'Please enter the "Amount"' } });
		}

		return check;
	}
	onBtnSaveClick = (_) => {
		const { budgetDetail } = this.props;
		const { speakerfee } = this.state;
		if (!this.preCheck()) {
			return;
		}
		// speakerfee["SpeakerFeeBatchId"] = budgetDetail.SpeakerFeeBatchId;
		this.props.addSpeakerFeeAsync(speakerfee);
		this.props.onDismiss('new');
	};

	componentDidMount() {
		const { budgetDetail } = this.props;
		this.setState({
			speakerfee: {
				budgetId: budgetDetail.id,
				itemNo: budgetDetail.itemNo,
			},
		});
	}

	afterSaved = (items, searchType) => {
		const data = items[0];
		const { speakerfee } = this.state;
		speakerfee['kolName'] = data['name'];
		speakerfee['hospitalName'] = data['company'];
		speakerfee['hcpSpecialty'] = data['expertiseArea'];
		let val = data['expertLevel'];
		let rangeOfRate = "";
		switch (val) {
			case "国际KOL":
				rangeOfRate = "RMB 0 - 3370 / 小时";

				break;
			case "全国KOL":
				rangeOfRate = "RMB 0 - 2400 / 小时";
				break;
			case "本地KOL":
				rangeOfRate = "RMB 0 - 1730 / 小时";
				break;
			case "非KOL":
				rangeOfRate = "RMB 0 - 1390 / 小时";
				break;
			default:
				rangeOfRate = "";
				break;
		}
		speakerfee['kolStature'] = val;
		speakerfee['rangeOfRate'] = rangeOfRate;
		// speakerfee['ratePerHourRange'] = data['ratePerHourRange'] ?? '';
		speakerfee['specialSegment'] = data['expertise'] ?? '';
		speakerfee['sapVendorCode'] = data['vendorCode'] ?? '';
		this.setState({
			speakerfee: { ...speakerfee },
		});
	};

	render() {
		const { speakerfee } = this.state;

		return (
			<>
				{this.state.messageBar.show ?
					<MessageBar
						messageBarType={MessageBarType.error}
						isMultiline={false}
						onDismiss={() => { this.setState({ messageBar: { show: false } }) }}
						dismissButtonAriaLabel="Close"
						styles={{ root: { position: 'fixed', zIndex: 10000, width: '750px' } }}
					>
						{this.state.messageBar.msg}

					</MessageBar>
					:
					null}
				<Stack
					tokens={verticalStackTokens}
					styles={{ root: { height: 'calc(100% - 50px)' } }}
				>
					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow style={{ width: '33%' }}>
							<TextField
								label='Item No.'
								disabled
								borderless
								styles={textFieldSytles}
								value={speakerfee.itemNo}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '33%' }}>
							<TextField
								label='HCC Code'
								borderless
								styles={textFieldSytles}
								// onChange={this.onTextChanged('hccCode')}
								onChange={(e, v) => this.onTextChanged(e, v, 'hccCode')}
								value={speakerfee.hccCode}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '33%' }}>
							<DatePicker
								label='Event Date'
								// styles={datePickerSytles}
								formatDate={(d: any) => {
									return moment(d).format('YYYY-MM-DD')
								}}
								firstDayOfWeek={DayOfWeek.Sunday}
								// DatePicker uses English strings by default. For localized apps, you must override this prop.
								strings={defaultDatePickerStrings}
								onSelectDate={(date) => {
									const formatedDate = moment(date).format('YYYY-MM-DD');
									this.setState({
										speakerfee: { ...speakerfee, eventDate: formatedDate },
									});
								}}
								value={
									speakerfee.eventDate
										? new Date(speakerfee.eventDate)
										: undefined
								}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow style={{ width: '33%' }}>
							{/* <TextField
								label='Activity Type'
								borderless
								styles={textFieldSytles}
								// onChange={this.onTextChanged('activityType')}
								onChange={(e, v) => this.onTextChanged(e, v, 'activityType')}
								value={speakerfee.activityType}
							/> */}

							<Dropdown
								label='Activity Type'
								key="ActivityType"
								options={activityType}
								styles={dropdownStyles}
								selectedKey={speakerfee.activityType}
								onChange={(_, data: any) =>
									this.setState({
										speakerfee: { ...speakerfee, activityType: data.key },
									})
								}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '33%' }}>
							<TextSearch
								searchType='vendorcode'
								label='SAP Vendor Code'
								afterSaved={this.afterSaved}
								value={speakerfee.sapVendorCode}
								onChange={(v, searchType) => {
									this.setState({
										speakerfee: { ...speakerfee, sapVendorCode: v },
									});
								}}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '33%' }}>
							<TextSearch
								searchType='kolname'
								label='KOL Name'
								afterSaved={this.afterSaved}
								value={speakerfee.kolName}
								onChange={(v, searchType) => {
									this.setState({
										speakerfee: { ...speakerfee, kolName: v },
									});
								}}
							/>
							{/* <TextField
								label='KOL Name'
								disabled
								borderless
								styles={textFieldSytles}
								// onChange={this.onTextChanged('kolName')}
								onChange={(e, v) => this.onTextChanged(e, v, 'kolName')}

								value={speakerfee.kolName}
							/> */}
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow>
							<TextSearch
								searchType='hospital'
								label='Hospital'
								afterSaved={this.afterSaved}
								value={speakerfee.hospitalName}
								onChange={(v, searchType) => {
									this.setState({
										speakerfee: { ...speakerfee, hospitalName: v },
									});
								}}
							/>
							{/* <TextField
								label='Hospital'
								borderless
								disabled
								styles={textFieldSytles}
								onChange={this.onTextChanged('hospital')}
								value={speakerfee.hospital}
							/> */}
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='HCP Specialty'
								borderless
								disabled
								styles={textFieldSytles}
								// onChange={this.onTextChanged('hcpSpecialty')}
								onChange={(e, v) => this.onTextChanged(e, v, 'hcpSpecialty')}
								value={speakerfee.hcpSpecialty}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='SpecialSegment'
								borderless
								disabled
								styles={textFieldSytles}
								// onChange={this.onTextChanged('specialSegment')}
								onChange={(e, v) => this.onTextChanged(e, v, 'specialSegment')}
								value={speakerfee.specialSegment}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow style={{ width: '33%' }}>
							{/* <TextField
								label='KOL Stature'
								disabled
								borderless
								styles={textFieldSytles}
								// onChange={this.onTextChanged('kolStature')}
								onChange={(e, v) => this.onTextChanged(e, v, 'kolStature')}
								value={speakerfee.kolStature}
							/> */}
							<Dropdown
								label='KOL Stature'
								key="KOLStature"
								options={kolStature}
								disabled
								styles={dropdownStyles}
								selectedKey={speakerfee.kolStature}
							//onChange={(e, v) => this.onTextChanged(e, v, 'kolStature')}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '33%' }}>
							<TextField
								label='Range of Rate'
								borderless
								disabled
								styles={textFieldSytles}
								// onChange={this.onTextChanged('rangeOfRateF')}
								//onChange={(e, v) => this.onTextChanged(e, v, 'rangeOfRate')}
								value={speakerfee.rangeOfRate}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '33%' }}>
							<TextField
								label='Rate per hour Range'
								borderless
								styles={textFieldSytles}
								// onChange={this.onTextChanged('rate')}
								onChange={(e, v) =>
									this.onTextChanged(e, v, 'rate')
								}
								value={speakerfee.rate ?? ''}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>


						<Stack.Item grow={1} style={{ width: '33%' }}>
							{/* <SpinButton
								label='Service Hours'
								styles={spinButtonSytles}
								value={speakerfee.serviceHour}
								min={1}
								max={100}
								step={1}
								onChange={(e, v) => this.onTextChanged(e, v, 'serviceHour')}
							// onDecrement={(v, e) => this.onDecrement(v, e, 'serviceHour')}
							/> */}

							{/* 
	<Number
		label='Service Hours'
		borderless
		styles={textFieldSytles}
		onChange={this.onTextChanged('serviceHour')}
		value={speakerfee.serviceHour}
	/> */}

							<TextField
								label='Service Hours'
								borderless
								styles={textFieldSytles}
								onChange={(e, v) => this.onTextChanged(e, v, 'serviceHour')}
								value={speakerfee.serviceHour ?? ""}
							/>
						</Stack.Item>

						<Stack.Item grow={1} style={{ width: '33%' }}>
							<TextField
								label='Amount'
								borderless
								styles={textFieldSytles}
								onChange={(e, v) => this.onTextChanged(e, v, 'amount')}
								value={speakerfee.amount}
							/>
						</Stack.Item>

						<Stack.Item grow={1} style={{ width: '33%' }}>
							<div>&nbsp;</div>
						</Stack.Item>

					</Stack>

				</Stack>

				<div
					style={{
						paddingTop: '1rem',
						display: 'flex',
						justifyContent: 'end',
					}}
				>
					<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
						Cancel
					</DefaultButton>
					<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
						Save
					</PrimaryButton>
				</div>
			</>
		);
	}
}

export default connect((state: IStore) => ({}), {
	addSpeakerFeeAsync,
})(SpeakerFeeSubNewPanel);

import React, { Component } from 'react';
import { Box, Input, Text, Toolbar } from '@fluentui/react-northstar';
import { MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';

export default class DashboardToolbar extends Component<any, any> {
  switchPortalNavClick = (event) => {
    this.props.onSwitchPortalNavClick();
  };

  newRecordBtnClick = (event) => {
    this.props.onNewRecordBtnClick();
  };

  render() {
    return (
      <>
        <Box
          styles={{
            display: 'flex',
            height: '3rem',
            justifyContent: 'space-between',
            boxShadow: '0px 5px 5px rgba(0, 0, 0, .2)',
          }}
        >
          <Toolbar
            items={[
              {
                icon: (
                  <MenuIcon
                    {...{
                      outline: true,
                    }}
                  />
                ),
                key: 'menu',
                onClick: this.switchPortalNavClick,
              },
              {
                key: 'custom-focusable-text',
                content: (
                  <Text
                    content='My Dashboard'
                    styles={{ fontWeight: 'bold', fontSize: '1rem' }}
                  />
                ),
                // focusable: true,
                kind: 'custom',
                // role: 'button',
              },
            ]}
          />
        </Box>
      </>
    );
  }
}

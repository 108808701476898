import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { updateUserAsync } from '../../redux/actions';
import { IUser, IStore } from '../../schemas';

import * as OPTIONS from '../options';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class UserPanelEdit extends Component<any, any> {
	state = {
		user: {} as IUser,
		isSpeakerFee: false,
	};

	/**
	 * Close the add user panel
	 */
	closePanel = () => {
		this.props.onDismiss(false);
	};

	doSubAction = (actionType) => {
		return () => this.props.onSubAction(actionType, this.state.user);
	};

	onBtnSaveClick = (_) => {
		const { user } = this.state;
		this.props.updateUserAsync(user);
		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
					Cancel
				</DefaultButton>
				{this.state.isSpeakerFee ? (
					<PrimaryButton
						styles={buttonStyle}
						onClick={this.doSubAction('openSpeakerFee')}
					>
						Speaker Fee
					</PrimaryButton>
				) : null}
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
					Save
				</PrimaryButton>
			</div>
		);
	};

	onTextChanged = (name) => {
		const { user } = this.state;

		return (_, data) => {
			this.setState({
				user: { ...user, [name]: data },
			});
		};
	};

	onDropDownChanged = (name) => {
		const { user } = this.state;

		return (_, data) => {
			const optionObj = {
				[`${name}`]: data.text,
			};
			this.setState({
				user: { ...user, ...optionObj },
			});
		};
	};

	componentDidMount() {
		const { detail } = this.props;
		this.setState({
			user: { ...detail },
		});
	}

	render() {
		const { dictionary } = this.props;
		const { user } = this.state;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Edit User'
					isFooterAtBottom={true}
					isLightDismiss={false}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtUserName'
									label='User Name'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('userName')}
									value={user.userName}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtUserEmail'
									label='Email'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('userEmail')}
									value={user.userEmail}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpTeam'
									label='Team'
									options={[{ key: '', text: '' }].concat(
										dictionary.teams.map((item) => {
											return { key: item.itemValue, text: item.itemName };
										})
									)}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('team')}
									selectedKey={user.team}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpRole'
									label='Role'
									options={OPTIONS.roleOptions}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('role')}
									selectedKey={user.role}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		dictionary: state.dictionary,
	}),
	{
		updateUserAsync,
	}
)(UserPanelEdit);

import { IAction, IDictionary } from '../../schemas';
import { GET_DICTIONARY } from '../constant';

const initState: IDictionary = {
	teams: [],
	budgetTypes: [],
	platforms: [],
	expenses: [],
	subExpenses: [],
	categories: [],
};

export default function dictionaryReducer(
	preState = initState,
	action: IAction<any>
): IDictionary {
	const { type, data } = action;
	switch (type) {
		case GET_DICTIONARY:
			return { ...data };
		default:
			return preState;
	}
}

import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IBudget, IDictItem, IStore } from '../../schemas';

import * as OPTIONS from '../options';
import { connect } from 'react-redux';
import numbro from 'numbro';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class BudgetPanelDetail extends Component<any, any> {
	closePanel = () => {
		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		const { status } = this.props.detail;
		let btnLabel = '';
		let actionType = '';
		if (status === 'Draft') {
			btnLabel = 'Edit';
			actionType = 'openEditPanel';
		} else if (status === 'Approved') {
			btnLabel = 'Modified';
			actionType = 'openModifyPanel';
		} else {
			btnLabel = '';
			actionType = '';
		}
		return btnLabel !== '' ? (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<PrimaryButton
					styles={buttonStyle}
					onClick={this.doSubAction(actionType)}
				>
					{btnLabel}
				</PrimaryButton>
			</div>
		) : null;
	};

	doSubAction = (actionType) => {
		return () => this.props.onSubAction(actionType, {});
	};

	render() {
		const { dictionary, detail, coordinators, budgetowners, pics, common } =
			this.props;
		let subDepts = [];
		let subDeptsNew: any = [];

		if (
			common.teamMappings &&
			common.teamMappings.length > 0 &&
			detail.teamName &&
			detail.teamName != ''
		) {
			subDepts = common.teamMappings.filter((v) => {
				if (detail.teamName === 'Finance') {
					return true;
				} else {
					return v.name == detail.teamName;
				}
			});
			if (subDepts.length > 0) {
				subDeptsNew = subDepts.map((c: any) => {
					return { key: c.department, text: c.department };
				});
			}
		}
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					// onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Budget Details'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtItemNo'
									label='Item No.'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.itemNo}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtBudgetYear'
									label='Budget Year'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.budgetYear}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtBucketTitle'
									label='Bucket'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.bucketTitle}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpCoodinator'
									label='Coordinator'
									options={coordinators.map((item) => {
										return { key: item.userEmail, text: item.userName };
									})}
									styles={dropdownStyles}
									selectedKey={detail.coodinatorId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<TextField
									key='txtTeam'
									label='Team'
									borderless
									styles={textFieldSytles}
									value={detail.teamName}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpSubDepartment'
									label='Sub Department'
									options={subDeptsNew}
									styles={dropdownStyles}
									selectedKey={detail.subDepartmentName}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpBudgetType'
									label='Type'
									// options={OPTIONS.typeOptions}
									options={dictionary.budgetTypes.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={detail.budgetTypeId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpPlatform'
									label='Platform/Customer'
									// options={OPTIONS.platformOptions}
									options={dictionary.platforms.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={detail.platformId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpCategory'
									label='Product Category'
									// options={OPTIONS.categoryOptions}
									options={dictionary.categories.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={detail.categoryId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpExpense'
									label='Expense Category'
									// options={OPTIONS.expenseOptions}
									options={dictionary.expenses.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={detail.expenseId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpSubExpense'
									label='SubExpense Category'
									// options={OPTIONS.expenseOptions}
									options={[{ key: '', text: '' }].concat(
										dictionary.subExpenses.map((data) => {
											return { key: data.itemName, text: data.itemName };
										})
									)}
									styles={dropdownStyles}
									selectedKey={detail.subExpenseId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpBudgetOwner'
									label='Budget Owner'
									options={budgetowners.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									selectedKey={detail.budgetOwnerId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtBudgetOwnerId'
									label='Budget Owner ID'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.budgetOwnerId}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpPic'
									label='PIC'
									options={pics.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									selectedKey={detail.picId}
									disabled
								/>
							</Stack.Item>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtPicId'
									label='PIC ID'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.picId}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'xLarge'}>Original Version</Text>
						</Stack.Item>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtInitQ1Value'
									label='Q1'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.initQ1Value}
									defaultValue={numbro(detail.initQ1Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitQ2Value'
									label='Q2'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.initQ2Value}
									defaultValue={numbro(detail.initQ2Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitQ3Value'
									label='Q3'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.initQ3Value}
									defaultValue={numbro(detail.initQ3Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitQ4Value'
									label='Q4'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.initQ4Value}
									defaultValue={numbro(detail.initQ4Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitFyValue'
									label='FY'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.initFyValue}
									defaultValue={numbro(detail.initFyValue).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'xLarge'}>Live Version</Text>
						</Stack.Item>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtLiveQ1Value'
									label='Q1'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.liveQ1Value}
									defaultValue={numbro(detail.liveQ1Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveQ2Value'
									label='Q2'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.liveQ2Value}
									defaultValue={numbro(detail.liveQ2Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveQ3Value'
									label='Q3'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.liveQ3Value}
									defaultValue={numbro(detail.liveQ3Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveQ4Value'
									label='Q4'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.liveQ4Value}
									defaultValue={numbro(detail.liveQ4Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveFyValue'
									label='FY'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.liveFyValue}
									defaultValue={numbro(detail.liveFyValue).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow>
							<TextField
								key='txtComments'
								label='Comments'
								multiline={true}
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.comments}
							/>
						</Stack.Item>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect((state: IStore) => ({
	dictionary: state.dictionary,
	coordinators: state.coordinators,
	budgetowners: state.budgetowners,
	pics: state.pics,
	common: state.common,
}))(BudgetPanelDetail);

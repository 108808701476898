import { get, post, put, del } from './comm-http';
import { IDictItem, IResponse } from '../schemas';

/**
 * Get the dictBudgetType list
 * @param params The params used to query dictBudgetType
 * @returns DictBudgetType list
 */
async function getDictBudgetTypesApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/budgettype', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getDictBudgetTypeByIdApi(
	id: string
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem>>(`/dict/budgettype/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new dictBudgetType
 * @param data DictBudgetType data
 * @returns DictBudgetType data from backend service after the dictBudgetType is created
 */
async function addDictBudgetTypeApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IDictItem, IResponse<IDictItem>>('/dict/budgettype', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a dictBudgetType
 * @param data DictBudgetType data
 * @returns DictBudgetType data from backend service after the dictBudgetType is updated
 */
async function updateDictBudgetTypeApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IDictItem, IResponse<IDictItem>>(
		`/dict/budgettype/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a dictBudgetType
 * @param data DictBudgetType data
 * @returns DictBudgetType data from backend service after the dictBudgetType is updated
 */
async function deleteDictBudgetTypeApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IDictItem>>(`/dict/budgettype/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getDictBudgetTypesApi,
	getDictBudgetTypeByIdApi,
	addDictBudgetTypeApi,
	updateDictBudgetTypeApi,
	deleteDictBudgetTypeApi,
};

import { get, post, put, del } from './comm-http';
import { ISpeakerFee, IResponse,ISpeaker } from '../schemas';

/**
 * Get the speakerFee list
 * @param params The params used to query speakerFee
 * @returns SpeakerFee list
 */
async function getSpeakerApi(params): Promise<IResponse<ISpeakerFee[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<ISpeakerFee[]>>('/speaker', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}
async function getSpeakerDataApi(params): Promise<IResponse<ISpeaker[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<ISpeaker[]>>('/speaker', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}
export {
	getSpeakerApi,getSpeakerDataApi
};

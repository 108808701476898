import { get, put } from './comm-http';
import { IPoData, IResponse } from '../schemas';

/**
 * Get the poData list
 * @param params The params used to query poData
 * @returns PoData list
 */
async function getPoDatasApi(params): Promise<IResponse<IPoData[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IPoData[]>>('/poData', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getPoDataByIdApi(id: string): Promise<IResponse<IPoData>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IPoData>>(`/poData/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a poData
 * @param data PoData data
 * @returns PoData data from backend service after the poData is updated
 */
async function updatePoDataApi(data: IPoData): Promise<IResponse<IPoData>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IPoData, IResponse<IPoData>>(`/poData/${data.id}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export { getPoDatasApi, getPoDataByIdApi, updatePoDataApi };

import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import numbro from 'numbro';
import { activityType } from '../options';
const moment = require('moment');

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const options: IDropdownOption[] = [
	{ key: 'Ella', text: 'Ella' },
	{ key: 'Candy', text: 'Candy' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

export default class SpeakerFeeSubDetailPanel extends Component<any> {
	closeDetailPanel = () => {
		this.props.onDismiss('details');
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle}>Cancel</DefaultButton>
				<PrimaryButton styles={buttonStyle}>Save</PrimaryButton>
			</div>
		);
	};

	render() {
		console.log('-------detail panel', this.props);
		const { detail } = this.props;
		return (
			<>
				<Stack
					tokens={verticalStackTokens}
					styles={{ root: { height: 'calc(100% - 50px)' } }}
				>
					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow>
							<TextField
								label='Item No.'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.itemNo}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='HCC Code'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.hccCode}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='Event Date'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.eventDate == "" ? "" : moment(detail.eventDate).format('YYYY-MM-DD')}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow>
							<TextField
								label='Activity Type'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.activityType}
							/>
							{/* <Dropdown
								label='Activity Type'
								key="ActivityType"
								options={activityType}
								styles={dropdownStyles}
								selectedKey={detail.activityType}
								onChange={(_, data: any) =>
									this.setState({
										speakerfee: { ...detail, activityType: data.key },
									})
								}
							/> */}
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='SAP Vendor Code'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.sapVendorCode}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='KOL Name'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.kolName}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow>
							<TextField
								label='Hospital'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.hospital}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='HCP Specialty'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.hcpSpecialty}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='SpecialSegment'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.specialSegment}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow>
							<TextField
								label='KOL Stature'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.kolStature}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='Range of Rate'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.rangeOfRate}
							/>
						</Stack.Item>

						<Stack.Item grow>
							<TextField
								label='Rate per hour Range'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.rate}
							/>
						</Stack.Item>
					</Stack>

					<Stack horizontal tokens={stackTokens}>
						<Stack.Item grow={1} style={{ width: '33%' }}>
							<TextField
								label='Service Hours'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.serviceHour}
							/>
						</Stack.Item>

						<Stack.Item grow={1} style={{ width: '33%' }}>
							<TextField
								label='Amount'
								disabled
								borderless
								styles={textFieldSytles}
								value={detail.amount}
							//value={numbro(detail.amount).format({ thousandSeparated: true, mantissa: 2 })}

							/>
						</Stack.Item>

						<Stack.Item grow={1} style={{ width: '33%' }}>
							<div>&nbsp;</div>
						</Stack.Item>
					</Stack>
				</Stack>

				<div
					style={{
						paddingTop: '1rem',
						display: 'flex',
						justifyContent: 'end',
					}}
				>
					<DefaultButton text='Back' onClick={this.closeDetailPanel} />
				</div>
			</>
		);
	}
}

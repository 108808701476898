import { OPEN_LOADING, GET_TEAM_MAPPINGS } from '../constant';
import { IAction } from '../../schemas';

const initState: any = {};

export default function commonReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case OPEN_LOADING:
			return { ...preState, ...data };
		case GET_TEAM_MAPPINGS:
			return { ...preState, teamMappings: data }
		default:
			return preState;
	}
}

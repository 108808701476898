import React, { Component } from 'react';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import moment from 'moment';

class MyContextMenu extends React.Component<any, any> {
	acttionbtnStyle = {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	};

	_menuItemClick = (_, item) => {
		this.props.onMenuItemClick(item?.key);
	};

	_getMenuProps = () => {
		return {
			shouldFocusOnMount: true,
			shouldFocusOnContainer: true,
			items: [
				{
					key: 'history',
					text: 'History',
					iconProps: { iconName: 'History' },
					onClick: this._menuItemClick,
				},
			],
		};
	};

	render() {
		const menuProps = this._getMenuProps();
		return (
			<IconButton
				persistMenu={false}
				menuProps={menuProps}
				style={this.acttionbtnStyle}
			/>
		);
	}
}

export interface IImportHistoryState {
	columns: IColumn[];
	selectionDetails: string;
	isModalSelection: boolean;
	isCompactMode: boolean;
	announcedMessage?: string;
}

export interface IDocument {
	key: string;
	importDate: string;
	importBy: string;
	importRecord: number;
	status: string;
}

export default class ImportBucketList extends Component<
	any,
	IImportHistoryState
> {
	private _selection: Selection;

	_renderItemColumn = (item?: any, index?: number, column?: any) => {
		if (item && column) {
			const fieldContent = item[column.fieldName];
			switch (column.key) {
				case 'createdTime':
					const utcTimeStr =
						fieldContent.indexOf('Z') > -1 ? fieldContent : fieldContent + 'Z';
					return (
						<span>{moment(utcTimeStr).format('YYYY-MM-DD HH:mm:ss')}</span>
					);

				default:
					return <span>{fieldContent}</span>;
			}
		} else {
			return '';
		}
	};

	constructor(props: {}) {
		super(props);

		const columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				fieldName: 'optionMenu',

				onRender: (item: IDocument) => (
					<MyContextMenu item={item} onMenuItemClick={this._onMenuItemClick} />
				),
			},
			{
				key: 'createdTime',
				name: 'Import Time',
				fieldName: 'createdTime',
				minWidth: 150,
				maxWidth: 150,
				isRowHeader: true,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'createdByName',
				name: 'Import By',
				fieldName: 'createdByName',
				minWidth: 150,
				maxWidth: 150,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'status',
				name: 'Status',
				fieldName: 'status',
				minWidth: 100,
				maxWidth: 100,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
		];

		this._selection = new Selection({
			onSelectionChanged: () => {
				this.setState({
					selectionDetails: this._getSelectionDetails(),
				});
			},
		});

		this.state = {
			columns: columns,
			selectionDetails: this._getSelectionDetails(),
			isModalSelection: false,
			isCompactMode: false,
			announcedMessage: undefined,
		};
	}

	render() {
		const { columns, isCompactMode } = this.state;
		const { items } = this.props;

		return (
			<div
				style={{
					height: 'calc(100vh - 7rem)',
					width: '100%',
					overflow: 'auto',
					padding: '10px',
				}}
			>
				<DetailsList
					items={items}
					compact={isCompactMode}
					columns={columns}
					onRenderItemColumn={this._renderItemColumn}
					selectionMode={SelectionMode.single}
					getKey={this._getKey}
					setKey='none'
					selection={this._selection}
					layoutMode={DetailsListLayoutMode.fixedColumns}
					isHeaderVisible={true}
					// onItemInvoked={this._onItemInvoked}
				/>
			</div>
		);
	}

	componentDidUpdate(previousProps: any, previousState: IImportHistoryState) {
		if (
			previousState.isModalSelection !== this.state.isModalSelection &&
			!this.state.isModalSelection
		) {
			this._selection.setAllSelected(false);
		}
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	private _onItemInvoked(item: any): void {
		alert(`Item invoked: ${item.name}`);
	}

	private _getSelectionDetails(): any {
		const selectionCount = this._selection.getSelectedCount();

		switch (selectionCount) {
			case 0:
				return null;
			case 1:
				return this._selection.getSelection()[0];
			default:
				return null;
		}
	}

	private _onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		const { columns } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				this.setState({
					announcedMessage: `${currColumn.name} is sorted ${
						currColumn.isSortedDescending ? 'descending' : 'ascending'
					}`,
				});
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		this.setState({
			columns: newColumns,
		});
	};

	itemOptionsClick = (data) => {
		return (e) => {
			console.log('----------------button', e, data);
		};
	};

	_onMenuItemClick = (menuKey) => {
		const selectItem = this._getSelectionDetails();
		this.setState({
			selectionDetails: selectItem,
		});
		this.props.onOptionMenuClick(menuKey, selectItem);
	};
}

function _copyAndSort<T>(
	items: T[],
	columnKey: string,
	isSortedDescending?: boolean
): T[] {
	const key = columnKey as keyof T;
	return items
		.slice(0)
		.sort((a: T, b: T) =>
			(isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
		);
}

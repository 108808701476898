import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getSpeakerFeesAsync,
	openLoadingDialog,
	sharedSwithPortalNav,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	SpeakerFeeDetailPanel,
	SpeakerFeeList,
	SpeakerFeePagination,
	SpeakerFeeToolbar,
} from '../../components';
import './index.scss';

class SpeakerFees extends Component<any, any> {
	private _pagination: any = null;
	private _searchParams: any = {};

	state = {
		isDetailOpened: false,
		selectedRow: {},
		searchText: '',
		page: 1,
	};

	optionMenuClick = (menuKey, dataItem: any) => {
		switch (menuKey) {
			case 'details':
				this.setState({ isDetailOpened: true, selectedRow: { ...dataItem } });
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		this.setState({ isDetailOpened: isOpen });
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isDetailOpened: true });
	};

	componentDidMount() {
		this.getData();
	}

	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ page: 1 });
		this.setState({ searchText, page: 1 });
		this.getData();
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props.getSpeakerFeesAsync(this._searchParams).then(() => {
			this.props.openLoadingDialog({ loadingOpen: false });
		});
	};

	render() {
		const { speakerfees } = this.props;
		return (
			<>
				<SpeakerFeeToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
				/>

				<SpeakerFeeList
					items={speakerfees.data ?? speakerfees}
					onOptionMenuClick={this.optionMenuClick}
				/>

				<SpeakerFeeDetailPanel
					isOpen={this.state.isDetailOpened}
					detail={this.state.selectedRow}
					onDismiss={this.closeDetailPanel}
				/>

				<SpeakerFeePagination
					ref={(v) => {
						{
							this._pagination = v;
						}
					}}
					onPageClick={this.pageClick}
					pageCount={this.props.speakerfees.total ?? 10}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		speakerfees: state.speakerfees,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{ getSpeakerFeesAsync, sharedSwithPortalNav, openLoadingDialog }
)(SpeakerFees);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IDictItem, IStore } from '../../schemas';
import { PageTitle } from './Constant';
import {
	updateDictBudgetTypeAsync,
	updateDictExpenseAsync,
	updateDictPlatformAsync,
	updateDictProductAsync,
	updateDictSubExpenseAsync,
	updateDictTeamAsync,
} from '../../redux/actions';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class DictPanelEdit extends Component<any, any> {
	state = {
		dictType: '',
		detail: {} as IDictItem,
	};

	/**
	 * Close the add detail panel
	 */
	closePanel = () => {
		this.props.onDismiss(false);
	};

	doSubAction = (actionType) => {
		return () => this.props.onSubAction(actionType, this.state.detail);
	};

	onBtnSaveClick = (_) => {
		const { detail, dictType } = this.state;
		switch (dictType) {
			case 'team':
				this.props.updateDictTeamAsync &&
					this.props.updateDictTeamAsync(detail);
				break;
			case 'product':
				this.props.updateDictProductAsync &&
					this.props.updateDictProductAsync(detail);
				break;
			case 'expense':
				this.props.updateDictExpenseAsync &&
					this.props.updateDictExpenseAsync(detail);
				break;
			case 'subexpense':
				this.props.updateDictSubExpenseAsync &&
					this.props.updateDictSubExpenseAsync(detail);
				break;
			case 'budgettype':
				this.props.updateDictBudgetTypeAsync &&
					this.props.updateDictBudgetTypeAsync(detail);
				break;
			case 'platform':
				this.props.updateDictPlatformAsync &&
					this.props.updateDictPlatformAsync(detail);
				break;
		}

		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
					Cancel
				</DefaultButton>
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
					Save
				</PrimaryButton>
			</div>
		);
	};

	onTextChanged = (e, data, name) => {
		const { detail } = this.state;
		this.setState({
			detail: { ...detail, itemName: data, itemValue: data },
		});
	};

	componentDidMount() {
		const { detail, dictType } = this.props;
		this.setState({
			detail: { ...detail },
			dictType: dictType,
		});
	}

	render() {
		const { detail, dictType } = this.state;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText={`Edit ${PageTitle[dictType]}`}
					isFooterAtBottom={true}
					isLightDismiss={false}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtItemName'
									label='Name'
									borderless
									styles={textFieldSytles}
									onChange={(e, data) =>
										this.onTextChanged(e, data, 'itemName')
									}
									value={detail.itemName}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect((state: IStore) => ({}), {
	updateDictTeamAsync,
	updateDictProductAsync,
	updateDictExpenseAsync,
	updateDictSubExpenseAsync,
	updateDictBudgetTypeAsync,
	updateDictPlatformAsync,
})(DictPanelEdit);

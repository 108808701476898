import React, { Component } from 'react';
import './index.scss';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Redirect } from 'react-router-dom';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { TextField } from '@fluentui/react';
import { getUserValidateApi } from '../../api/user';
import { IStore } from '../../schemas';
import {
	getCurrentUserAsync,
	openLoadingDialog
} from '../../redux/actions';
import { getauthorizationCodeApi } from '../../api/user'
import { connect } from 'react-redux';
interface ILoginState {
	logined?: boolean;
	hideLoginDialog?: boolean;
	code?: string;
	errorMsg?: string;
	currentUser?: any;

}

interface ILoginProps {

	getCurrentUserAsync: () => void;
	currentUser: any
	openLoadingDialog: (data) => void;
	common?: any;
}

class Login extends Component<ILoginProps, ILoginState> {
	private _token = '';
	public state: ILoginState = {
		logined: false,
		hideLoginDialog: true,
		code: '',
		errorMsg: "",
		currentUser: {},

	};
	componentDidMount() {
		const tokenCode = localStorage.getItem('teamsAuthToken');
		if (tokenCode) {
			console.log(tokenCode)
		} else {
			this.props.openLoadingDialog({ loadingOpen: false });
			//window.location.assign("/auth_end?code=lli241@its.jnj.com")
			//window.location.href = "/auth_end?code=lli241@its.jnj.com"

			// getauthorizationCodeApi({}).then(v => {
			// 	console.log(v)
			// })
			//	window.fetch()
		}
	}





	login = async () => {
		const result = await getUserValidateApi({ code: this.state.code });

		if (result && result.value) {
			this.props.getCurrentUserAsync();
			this.setState({ hideLoginDialog: true });
			localStorage.setItem('teamsAuthToken', this._token);
			this.setState({ logined: true });
		} else {
			this.setState({ errorMsg: 'Error Code' })
		}
	};

	openDialog = (token) => {
		this._token = token;
		this.setState({ hideLoginDialog: false });
	};

	handleClose = () => {
		this._token = '';
		this.setState({ hideLoginDialog: true });
	};

	onChanged = (e, v) => {
		this.setState({ code: v });
	};
	render() {
		let html: any = null;
		if (this.state.logined) {
			html = <Redirect to='/portal' />
		} else {
			html = <div className='login'>
				<h1>Login</h1>
				<PrimaryButton
					text='Admin LanLan'
					disabled={false}
					onClick={() => {
						this.openDialog('lli241@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='HP-Coordinator LongYao'
					disabled={false}
					onClick={() => {
						this.openDialog('lzhan131@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='NR-Coordinator RuiXu'
					disabled={false}
					onClick={() => {
						this.openDialog('rzhou18@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='CC-Coordinator LinFeng'
					disabled={false}
					onClick={() => {
						this.openDialog('flin21@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='HP-Bu Head ZiQi'
					disabled={false}
					onClick={() => {
						this.openDialog('zzhao46@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='NR-Bu Head Scott'
					disabled={false}
					onClick={() => {
						this.openDialog('xxiaoxia@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='CC-BU Head Patty'
					disabled={false}
					onClick={() => {
						this.openDialog('phan4@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='Finance Cindy'
					disabled={false}
					onClick={() => {
						this.openDialog('qliu64@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='FD Elisa'
					disabled={false}
					onClick={() => {
						this.openDialog('ywu112@its.jnj.com');
					}}
				/>
				<PrimaryButton
					text='GM Steven'
					disabled={false}
					onClick={() => {
						this.openDialog('shu41@its.jnj.com');
					}}
				/>
			</div>
		}
		return (
			<>
				{
					this.props.common.loadingOpen
						?
						<div>loading...</div>
						:
						<div>{html}</div>
				}


				< Dialog
					hidden={this.state.hideLoginDialog}
					maxWidth={700}
					onDismiss={this.handleClose}
					dialogContentProps={{
						type: DialogType.normal,
						title: '',
					}}
				>
					<div>
						<TextField
							label='Code'
							onChange={this.onChanged}
							value={this.state.code ?? ''}
							errorMessage={this.state.errorMsg}
						/>
					</div>
					<DialogFooter>
						<PrimaryButton onClick={this.login} text='OK' />
						<DefaultButton onClick={this.handleClose} text='Close' />
					</DialogFooter>
				</Dialog >
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		currentUser: state.currentUser,
		common: state.common
	}),
	{
		getCurrentUserAsync,
		openLoadingDialog
	}
)(Login);

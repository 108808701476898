import { get, post, put, del } from './comm-http';
import { IRequest, IResponse, IGroupRequest } from '../schemas';

/**
 * Get the request list
 * @param params The params used to query request
 * @returns Request list
 */
async function getRequestsApi(params): Promise<IResponse<IRequest[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IRequest[]>>('/request/getGroupRequests', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

// async function getRequestByIdApi(id: string): Promise<IResponse<IRequest>> {
// 	const token = localStorage.getItem('teamsAuthToken');
// 	return await get<IResponse<IRequest>>(`/request/${id}`, {
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	});
// }

/**
 * Add a new request
 * @param data Request data
 * @returns Request data from backend service after the request is created
 */
async function addRequestApi(data: IGroupRequest): Promise<IResponse<IRequest>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IGroupRequest, IResponse<IRequest>>('/request/addGroupRequest', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a request
 * @param data Request data
 * @returns Request data from backend service after the request is updated
 */
// async function updateRequestApi(data: IRequest): Promise<IResponse<IRequest>> {
// 	const token = localStorage.getItem('teamsAuthToken');
// 	return await put<IRequest, IResponse<IRequest>>(`/request/${data.id}`, data, {
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	});
// }

/**
 * Delete a request
 * @param data Request data
 * @returns Request data from backend service after the request is updated
 */
// async function deleteRequestApi(data: IRequest): Promise<IResponse<IRequest>> {
// 	const token = localStorage.getItem('teamsAuthToken');
// 	return await del<IResponse<IRequest>>(`/request/${data.id}`, {
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	});
// }

/**
 * Submit a request
 * @param data Request data
 * @returns Request data from backend service after the request is submitted.
 */
// async function submitRequestApi(data: IRequest): Promise<IResponse<IRequest>> {
// 	const token = localStorage.getItem('teamsAuthToken');
// 	return await post<IRequest, IResponse<IRequest>>(`/request/submit`, data, {
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	});
// }

/**
 * Get the request list for approval
 * @param params The params used to query request
 * @returns Request list
 */
async function getRequestsForApprovalApi(
	params
): Promise<IResponse<IRequest[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IRequest[]>>('/approvalhistory', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}


async function getApprovalHistoryApi(params): Promise<IResponse<IRequest[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IRequest[]>>(`/approvalhistory/${params.requestId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

async function getRequestDestailsApi(params): Promise<IResponse<IRequest[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IRequest[]>>(`/request/getRequestDestails/${params.requestId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getRequestsApi,
	// getRequestByIdApi,
	addRequestApi,
	// updateRequestApi,
	// deleteRequestApi,
	// submitRequestApi,
	getRequestsForApprovalApi,
	getApprovalHistoryApi,
	getRequestDestailsApi
};

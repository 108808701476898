import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { IStore } from '../../schemas';
import { PageTitle } from './Constant';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

class DictPanelDetail extends Component<any, any> {
	closePanel = () => {
		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return null;
	};

	render() {
		const { dictType, detail } = this.props;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText={`${PageTitle[dictType]} Details`}
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtItemName'
									label='Name'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.itemName}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect((state: IStore) => ({}))(DictPanelDetail);

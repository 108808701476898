import React, { Component } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react/lib/Button';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Button, Text } from '@fluentui/react-northstar';
import { FilesUploadIcon, MenuIcon } from '@fluentui/react-icons-northstar';

export default class DataMaintanceToolbar extends Component<any, any> {
	public state = {
		searchValue: '',
	};
	switchPortalNavClick = (event) => {
		this.props.onSwitchPortalNavClick();
	};

	uploadBtnClick = (event) => {
		this.props.onUploadBtnClick();
	};

	searchClick = (e) => {
		if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
			if (this.props.searchClick) {
				const searchParams = {
					...this.state,
				};
				this.props.searchClick(searchParams);
			}
		}
	};
	searchChange = (e) => {
		this.setState({ searchValue: e.target.value });
	};

	render() {
		return (
			<>
				<div
					style={{
						width: '100%',
						height: '45px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						boxShadow: '0px 5px 5px rgba(0, 0, 0, .2)',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<MenuIcon
							{...{
								outline: true,
							}}
							styles={{
								fontWeight: 'bold',
								fontSize: '1rem',
								paddingLeft: '5px',
								cursor: 'pointer',
							}}
							onClick={this.switchPortalNavClick}
						/>
						<Text
							content='Data Maintance'
							styles={{
								fontWeight: 'bold',
								fontSize: '1rem',
								paddingLeft: '25px',
								paddingRight: '25px',
							}}
						/>
						<Pivot
							aria-label='Basic Pivot Example'
							onLinkClick={(item, ev) => {
								if (item?.props.headerText === 'PO Data') {
									this.props.onSwitchTabClick('po');
								} else if (item?.props.headerText === 'SAP Data') {
									this.props.onSwitchTabClick('sap');
								}
							}}
						>
							<PivotItem key='poTab' headerText='PO Data'></PivotItem>
							<PivotItem key='sapTab' headerText='SAP Data'></PivotItem>
						</Pivot>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Button
							icon={<FilesUploadIcon />}
							text
							content='Upload File'
							onClick={this.uploadBtnClick}
						/>
						<TextField
							borderless
							placeholder='Search...'
							styles={{
								root: { marginLeft: '10px', borderBottom: 'solid 2px #6264a7' },
							}}
							onChange={(e, v) => {
								this.setState({ searchValue: v });
							}}
							value={this.state.searchValue}
							onKeyDown={this.searchClick}
						/>

						<IconButton
							iconProps={{ iconName: 'Search' }}
							styles={{ icon: { color: '#6264a7' } }}
							onClick={this.searchClick}
						/>
					</div>
				</div>
			</>
		);
	}
}

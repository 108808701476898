import {
	ADD_DICTSUBEXPENSE,
	UPDATE_DICTSUBEXPENSE,
	DELETE_DICTSUBEXPENSE,
	GET_DICTSUBEXPENSE_LIST,
} from '../constant';
import {
	getDictSubExpensesApi,
	addDictSubExpenseApi,
	updateDictSubExpenseApi,
	deleteDictSubExpenseApi,
} from '../../api';

export const getDictSubExpenses = (data) => ({
	type: GET_DICTSUBEXPENSE_LIST,
	data,
});

export const addDictSubExpense = (data) => ({
	type: ADD_DICTSUBEXPENSE,
	data,
});

export const updateDictSubExpense = (data) => ({
	type: UPDATE_DICTSUBEXPENSE,
	data,
});

export const deleteDictSubExpense = (data) => ({
	type: DELETE_DICTSUBEXPENSE,
	data,
});

export const getDictSubExpensesAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getDictSubExpensesApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getDictSubExpenses({
							data: (data.value as any)?.data ?? [],
							total: totalpage,
						})
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

export const addDictSubExpenseAsync = (data) => async (dispatch) => {
	try {
		const response = await addDictSubExpenseApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(addDictSubExpense(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateDictSubExpenseAsync = (data) => async (dispatch) => {
	try {
		const response = await updateDictSubExpenseApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(updateDictSubExpense(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteDictSubExpenseAsync = (data) => async (dispatch) => {
	try {
		const response = await deleteDictSubExpenseApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(deleteDictSubExpense(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

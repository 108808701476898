import {
	ADD_REQUEST,
	UPDATE_REQUEST,
	DELETE_REQUEST,
	GET_REQUEST_LIST,
} from '../constant';
import {
	getRequestsApi,
	addRequestApi,
	getRequestsForApprovalApi,
} from '../../api';
import { updateBudget, updateSPBudget } from '.';

export const getRequests = (data) => ({
	type: GET_REQUEST_LIST,
	data,
});

export const addRequest = (data) => ({
	type: ADD_REQUEST,
	data,
});

export const updateRequest = (data) => ({
	type: UPDATE_REQUEST,
	data,
});

export const deleteRequest = (data) => ({
	type: DELETE_REQUEST,
	data,
});

export const getRequestsAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getRequestsApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.totalCount ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getRequests({ data: (data.value as any)?.dataSource ?? [], total: totalpage })
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

export const addRequestAsync = (data) => async (dispatch) => {
	try {
		const response = await addRequestApi(data);
		if (response.errorCode.trim().length === 0) {
			// dispatch(addRequest(response.value));
			//dispatch(updateBudget(response.value));
			dispatch(updateSPBudget(response.value));


			return Promise.resolve({ value: response.value });
		} else {
			return Promise.resolve({ errorCode: response.errorMessage });
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

// export const updateRequestAsync = (data) => async (dispatch) => {
// 	try {
// 		const response = await updateRequestApi(data);
// 		if (response.errorCode.trim().length === 0) {
// 			console.log(':::::::::::updateRequestAsync', response.value);
// 			dispatch(updateRequest(response.value));

// 			return Promise.resolve(response.value);
// 		} else {
// 			return Promise.reject(response.errorMessage);
// 		}
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// export const deleteRequestAsync = (data) => async (dispatch) => {
// 	try {
// 		const response = await deleteRequestApi(data);
// 		if (response.errorCode.trim().length === 0) {
// 			dispatch(deleteRequest(response.value));

// 			return Promise.resolve(response.value);
// 		} else {
// 			return Promise.reject(response.errorMessage);
// 		}
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// export const submitRequestAsync = (data) => async (dispatch) => {
// 	try {
// 		const response = await submitRequestApi(data);
// 		if (response.errorCode.trim().length === 0) {
// 			console.log(':::::::::::submitRequestAsync', response.value);
// 			dispatch(updateRequest(response.value));

// 			return Promise.resolve(response.value);
// 		} else {
// 			return Promise.reject(response.errorMessage);
// 		}
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

export const getRequestsForApprovalAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getRequestsForApprovalApi(params)
			.then((data) => {

				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getRequests({ data: (data.value as any)?.data ?? [], total: totalpage })
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

import { IAction } from "../../schemas";
import { SHARE_NAV_SWITCH } from "../constant";

const initState: boolean = true;

export default function shareNavSwitchReducer(
  preState = initState,
  action: IAction<boolean>
): boolean {
  const { type } = action;
  switch (type) {
    case SHARE_NAV_SWITCH:
      return !preState;
    default:
      return preState;
  }
}

import {
    ADD_SUBDEPARTMENT,
    UPDATE_SUBDEPARTMENT,
    DELETE_SUBDEPARTMENT,
    GET_SUBDEPARTMENT_LIST,
} from '../constant';
import {
    getSubDepartmentsApi,
    addSubDepartmentApi,
    updateSubDepartmentApi,
    deleteSubDepartmentApi,
} from '../../api';

export const getSubDepartments = (data) => ({
    type: GET_SUBDEPARTMENT_LIST,
    data,
});

export const addSubDepartment = (data) => ({
    type: ADD_SUBDEPARTMENT,
    data,
});

export const updateSubDepartment = (data) => ({
    type: UPDATE_SUBDEPARTMENT,
    data,
});

export const deleteSubDepartment = (data) => ({
    type: DELETE_SUBDEPARTMENT,
    data,
});

export const getSubDepartmentsAsync = (params) => {
    const pageSize = 15;
    params = { ...params, pageSize };
    return (dispatch) => {
        return getSubDepartmentsApi(params)
            .then((data) => {
                if (data.errorCode.trim().length === 0) {
                    const totalCount = (data.value as any)?.total ?? 1;
                    const totalpage =
                        parseInt(parseInt(totalCount) / pageSize + '') +
                        (totalCount % pageSize > 0 ? 1 : 0);
                    console.log('::::::::::::::::::', totalpage);
                    dispatch(
                        getSubDepartments({ data: (data.value as any).data ?? data.value, total: totalpage })
                    );
                } else {
                    console.error(`${data.errorCode}:: ${data.errorMessage}`);
                }
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const addSubDepartmentAsync = (data) => async (dispatch) => {
    try {
        const response = await addSubDepartmentApi(data);
        if (response.errorCode.trim().length === 0) {
            dispatch(addSubDepartment(response.value));

            return Promise.resolve(response.value);
        } else {
            return Promise.reject(response.errorMessage);
        }
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateSubDepartmentAsync = (data) => async (dispatch) => {
    try {
        const response = await updateSubDepartmentApi(data);
        if (response.errorCode.trim().length === 0) {
            console.log(':::::::::::updateSubDepartmentAsync', response.value);
            dispatch(updateSubDepartment(response.value));

            return Promise.resolve(response.value);
        } else {
            return Promise.reject(response.errorMessage);
        }
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteSubDepartmentAsync = (data) => async (dispatch) => {
    try {
        const response = await deleteSubDepartmentApi(data);
        if (response.errorCode.trim().length === 0) {
            dispatch(deleteSubDepartment(response.value));

            return Promise.resolve(response.value);
        } else {
            return Promise.reject(response.errorMessage);
        }
    } catch (error) {
        return Promise.reject(error);
    }
};

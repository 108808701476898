import { IAction, IDictData } from '../../schemas';
import {
	GET_DICTTEAM_LIST,
	ADD_DICTTEAM,
	UPDATE_DICTTEAM,
	DELETE_DICTTEAM,
} from '../constant';

const initState: IDictData = {
	data: [],
	total: 0,
};

export default function dictteamsReducer(
	preState = initState,
	action: IAction<any>
): IDictData {
	const { type, data } = action;
	switch (type) {
		case GET_DICTTEAM_LIST:
			return { ...data };
		case ADD_DICTTEAM:
			const newData = [data, ...preState.data];
			return { data: newData, total: preState.total };
		case UPDATE_DICTTEAM:
			const index = preState.data.findIndex(
				(item) => item.id === action.data.id
			);
			const updateData = [
				...preState.data.slice(0, index),
				{
					...data,
				},
				...preState.data.slice(index + 1),
			];
			return { data: updateData, total: preState.total };
		case DELETE_DICTTEAM:
			return {
				data: preState.data.filter((d) => d.id !== action.data.id),
				total: preState.total,
			};
		default:
			return preState;
	}
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { IUser, IDictItem, IStore } from '../../schemas';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import * as OPTIONS from '../options';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class SubDepartmentPanelDetail extends Component<any, any> {
	closePanel = () => {
		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return null;
	};

	render() {
		const { detail, dictionary } = this.props;

		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='SubDepartment Details'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtTeam'
									label='Team'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.name}
								/>
							</Stack.Item>
							<Stack.Item grow>
								<TextField
									key='txtDepartment'
									label='Department'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.department}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect((state: IStore) => ({
	dictionary: state.dictionary,
}))(SubDepartmentPanelDetail);

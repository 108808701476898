// import { IStackTokens, Stack } from '@fluentui/react';
// import React, { Component } from 'react';

// export default class Dashboard extends Component {
//   render() {
//     return (
//       <>
//         <Stack wrap>
//           <Stack wrap>
//             <Stack.Item
//               styles={{ root: { width: '100%', padding: '2rem 1rem 0 1rem' } }}
//             >
//               <div
//                 style={{
//                   padding: '1rem',
//                   backgroundColor: 'skyblue',
//                   borderRadius: '3px',
//                   height: '200px',
//                   width: '100%',
//                 }}
//               ></div>
//             </Stack.Item>
//           </Stack>

//           <Stack wrap>
//             <span>Section 2</span>
//           </Stack>
//         </Stack>
//       </>
//     );
//   }
// }

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sharedSwithPortalNav } from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	DashboardToolbar,
	RequestDetailPanel,
	RequestList,
	RequestPagination,
	RequestToolbar,
} from '../../components';
import './index.scss';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { getDashbordApi } from '../../api';
import numbro from 'numbro';

class Dashboard extends Component<any, any> {
	state = {
		isDetailOpened: false,
		selectedRow: {},
		dashbordData: {
			dataRequest: {
				myRequest: { inProcess: '-', approved: '-', rejected: '-' },
				myApproval: { inProcess: '-', approved: '-' },
			},
			dataBudget: {
				myBudget: { currentValue: '-', totalValue: '-' },
				departmentBudget: [],
				companyBudget: [],
			},
		},
	};

	optionMenuClick = (menuItem: any) => {
		console.log('-------------optionMenuClick', menuItem);
		this.setState({ isDetailOpened: true, selectedRow: { ...menuItem } });
	};

	closeDetailPanel = (isOpen) => {
		console.log('-------------panel dismiss');
		this.setState({ isDetailOpened: isOpen });
	};

	pageClick = (page) => {
		console.log('--------------pageClick', page);
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isDetailOpened: true });
	};

	componentDidMount() {
		getDashbordApi()
			.then((result) => {
				if (result && result.value) {
					this.setState({ dashbordData: result.value });
				}
			})
			.catch(() => {});
	}

	renderMyRequet = () => {
		return (
			<Stack
				styles={{
					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
				}}
				tokens={{ childrenGap: 10 }}
			>
				<Stack>
					<span style={{ fontWeight: 'bold' }}>My Requests</span>
				</Stack>
				<Stack horizontal>
					<Stack horizontal grow tokens={{ childrenGap: 10 }}>
						<Separator vertical />

						<Stack
							onClick={() => this.props.history.push('/portal/request')}
							styles={{ root: { cursor: 'pointer' } }}
						>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									color: '#6264A7',
								}}
							>
								{this.state.dashbordData.dataRequest.myRequest.inProcess}
							</span>
							<span>In Progress</span>
						</Stack>
					</Stack>

					<Stack horizontal grow tokens={{ childrenGap: 10 }}>
						<Separator vertical />

						<Stack
							onClick={() => this.props.history.push('/portal/request')}
							styles={{ root: { cursor: 'pointer' } }}
						>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									color: '#6264A7',
								}}
							>
								{this.state.dashbordData.dataRequest.myRequest.approved}
							</span>
							<span>Approved</span>
						</Stack>
					</Stack>

					<Stack horizontal grow tokens={{ childrenGap: 10 }}>
						<Separator vertical />

						<Stack
							onClick={() => this.props.history.push('/portal/request')}
							styles={{ root: { cursor: 'pointer' } }}
						>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									color: '#6264A7',
								}}
							>
								{this.state.dashbordData.dataRequest.myRequest.rejected}
							</span>
							<span>Rejected</span>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		);
	};
	renderMyApproval = () => {
		return (
			<Stack
				styles={{
					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
				}}
				tokens={{ childrenGap: 10 }}
			>
				<Stack>
					<span style={{ fontWeight: 'bold' }}>My Approvals</span>
				</Stack>
				<Stack horizontal>
					<Stack horizontal grow tokens={{ childrenGap: 10 }}>
						<Separator vertical />

						<Stack
							onClick={() => this.props.history.push('/portal/approval')}
							styles={{ root: { cursor: 'pointer' } }}
						>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									color: '#6264A7',
								}}
							>
								{this.state.dashbordData.dataRequest.myApproval.inProcess}
							</span>
							<span>In Progress</span>
						</Stack>
					</Stack>

					<Stack horizontal grow tokens={{ childrenGap: 10 }}>
						<Separator vertical />

						<Stack
							onClick={() => this.props.history.push('/portal/approval')}
							styles={{ root: { cursor: 'pointer' } }}
						>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '1.5rem',
									color: '#6264A7',
								}}
							>
								{this.state.dashbordData.dataRequest.myApproval.approved}
							</span>
							<span>Approved</span>
						</Stack>
					</Stack>

					<Stack grow></Stack>
				</Stack>
			</Stack>
		);
	};
	renderMyBudget = () => {
		const cutrent = this.state.dashbordData.dataBudget.myBudget.currentValue;
		const total = this.state.dashbordData.dataBudget.myBudget.totalValue;
		let percent = '0%';
		let str = '-/-';
		if (total !== '-' && cutrent !== '-') {
			str =
			numbro(cutrent).format({ thousandSeparated: true, mantissa: 2 }) +
			'/' +
			numbro(total).format({ thousandSeparated: true, mantissa: 2 });
		}

		if (total !== '-' && +cutrent > 0 && +total > 0) {
			percent = (+cutrent / +total) * 100 + '%';
		}
		// if (total != '-' && +total != 0) {
		// 	percent = (+cutrent / +total) * 100 + '%';
		// 	str =
		// 		numbro(cutrent).format({ thousandSeparated: true, mantissa: 2 }) +
		// 		'/' +
		// 		numbro(total).format({ thousandSeparated: true, mantissa: 2 });
		// }
		return (
			<Stack
				styles={{
					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
				}}
				tokens={{ childrenGap: 10 }}
			>
				<Stack
					onClick={() => this.props.history.push('/portal/budget')}
					styles={{ root: { cursor: 'pointer' } }}
				>
					<span style={{ fontWeight: 'bold' }}>My Budget</span>
				</Stack>
				<Stack>
					<span style={{ textAlign: 'right' }}>{str}</span>
					<div
						style={{
							backgroundColor: '#E7DFFD',
							borderRadius: '2px',
							height: '2rem',
							width: '100%',
						}}
					>
						<div
							style={{
								backgroundColor: '#C0ABFB',
								borderRadius: '2px',
								height: '100%',
								width: percent,
							}}
						></div>
					</div>
					<Stack
						horizontal
						verticalAlign={'center'}
						styles={{
							root: {
								paddingTop: '1rem',
							},
						}}
						tokens={{ childrenGap: 10 }}
					>
						<div
							style={{
								height: '10px',
								width: '10px',
								backgroundColor: '#E7DFFD',
								borderRadius: '10px',
							}}
						></div>
						<span>Total Budget Amount</span>
						<div
							style={{
								height: '10px',
								width: '10px',
								backgroundColor: '#C0ABFB',
								borderRadius: '10px',
							}}
						></div>
						<span>Spending Amount</span>
					</Stack>
				</Stack>
			</Stack>
		);
	};
	// 	renderDeptBudget = () => {
	// 		const beautyMktCurrent = this.state.dashbordData.dataBudget.departmentBudget.beautyMkt.currentValue;
	// 		const beautyMktTotal = this.state.dashbordData.dataBudget.departmentBudget.beautyMkt.totalValue;
	// 		let beautyMktPercent = "0%";
	// 		let beautyMktStr = "-/-"
	// 		if (beautyMktTotal != "-" && +beautyMktTotal != 0) {
	// 			beautyMktPercent = (+beautyMktCurrent / +beautyMktTotal) * 100 + "%";
	// 			beautyMktStr = numbro(beautyMktCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(beautyMktTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		const cehCurrent = this.state.dashbordData.dataBudget.departmentBudget.ceh.currentValue;
	// 		const cehtTotal = this.state.dashbordData.dataBudget.departmentBudget.ceh.totalValue;

	// 		let cehPercent = "0%";
	// 		let cehStr = "-/-"
	// 		if (cehtTotal != "-" && +cehtTotal != 0) {
	// 			cehPercent = (+cehCurrent / +cehtTotal) * 100 + "%";
	// 			cehStr = numbro(cehCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(cehtTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		const channelCurrent = this.state.dashbordData.dataBudget.departmentBudget.channel.currentValue;
	// 		const channelTotal = this.state.dashbordData.dataBudget.departmentBudget.channel.totalValue;

	// 		let channelPercent = "0%";
	// 		let channelStr = "-/-"
	// 		if (channelTotal != "-" && +channelTotal != 0) {
	// 			channelPercent = (+channelCurrent / +channelTotal) * 100 + "%";
	// 			channelStr = numbro(channelCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(channelTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		const clearMktCurrent = this.state.dashbordData.dataBudget.departmentBudget.clearMkt.currentValue;
	// 		const clearMktTotal = this.state.dashbordData.dataBudget.departmentBudget.clearMkt.totalValue;

	// 		let clearMktPercent = "0%";
	// 		let clearMktStr = "-/-"
	// 		if (clearMktTotal != "-" && +clearMktTotal != 0) {
	// 			clearMktPercent = (+clearMktCurrent / +clearMktTotal) * 100 + "%";
	// 			clearMktStr = numbro(clearMktCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(clearMktTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		const otherCurrent = this.state.dashbordData.dataBudget.departmentBudget.other.currentValue;
	// 		const otherTotal = this.state.dashbordData.dataBudget.departmentBudget.other.totalValue;

	// 		let otherPercent = "0%";
	// 		let otherStr = "-/-"
	// 		if (otherTotal != "-" && +otherTotal != 0) {
	// 			otherPercent = (+otherCurrent / +otherTotal) * 100 + "%";
	// 			otherStr = numbro(otherCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(otherTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		const totalAcuvueCurrent = this.state.dashbordData.dataBudget.departmentBudget.totalAcuvue.currentValue;
	// 		const totalAcuvueTotal = this.state.dashbordData.dataBudget.departmentBudget.totalAcuvue.totalValue;

	// 		let totalAcuvuePercent = "0%";
	// 		let totalAcuvueStr = "-/-"
	// 		if (totalAcuvueTotal != "-" && +totalAcuvueTotal != 0) {
	// 			totalAcuvuePercent = (+totalAcuvueCurrent / +totalAcuvueTotal) * 100 + "%";
	// 			totalAcuvueStr = numbro(totalAcuvueCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(totalAcuvueTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		const tvciCurrent = this.state.dashbordData.dataBudget.departmentBudget.tvci.currentValue;
	// 		const tvciTotal = this.state.dashbordData.dataBudget.departmentBudget.tvci.totalValue;

	// 		let tvciPercent = "0%";
	// 		let tvciStr = "-/-";
	// 		if (tvciTotal != "-" && +tvciTotal != 0) {
	// 			tvciPercent = (+tvciCurrent / +tvciTotal) * 100 + "%";
	// 			tvciStr = numbro(tvciCurrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(tvciTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		return (
	// 			<Stack
	// 				styles={{
	// 					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
	// 				}}
	// 				tokens={{ childrenGap: 10 }}
	// 			>
	// 				<Stack>
	// 					<span style={{ fontWeight: 'bold' }}>Department Budget</span>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{beautyMktStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '2rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: beautyMktPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.6rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							Beauty
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{cehStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: cehPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							CC
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{channelStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: channelPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							Hospital
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{clearMktStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: clearMktPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							Clear
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{otherStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: otherPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							NR
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{totalAcuvueStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: totalAcuvuePercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							Total Acuvue
	// 						</div>
	// 					</div>
	// 				</Stack>
	// 				{/*
	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{tvciStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: tvciPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							TVCI
	// 						</div>
	// 					</div>
	// 				</Stack>

	//  */}

	// 				<Stack
	// 					horizontal
	// 					verticalAlign={'center'}
	// 					styles={{
	// 						root: {
	// 							paddingTop: '1rem',
	// 						},
	// 					}}
	// 					tokens={{ childrenGap: 10 }}
	// 				>
	// 					<div
	// 						style={{
	// 							height: '10px',
	// 							width: '10px',
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '10px',
	// 						}}
	// 					></div>
	// 					<span>Total Budget Amount</span>
	// 					<div
	// 						style={{
	// 							height: '10px',
	// 							width: '10px',
	// 							backgroundColor: '#C0ABFB',
	// 							borderRadius: '10px',
	// 						}}
	// 					></div>
	// 					<span>Spending Amount</span>
	// 				</Stack>

	// 			</Stack>
	// 		)

	// 	}
	// 	renderCompanytBudget = () => {

	// 		const nrCutrent = this.state.dashbordData.dataBudget.companyBudget.nr.currentValue;
	// 		const nrTotal = this.state.dashbordData.dataBudget.companyBudget.nr.totalValue;

	// 		let nrPercent = "0%";
	// 		let nrStr = "";
	// 		if (nrTotal != "-" && +nrTotal != 0) {
	// 			nrPercent = (+nrCutrent / +nrTotal) * 100 + "%";
	// 			nrStr = numbro(nrCutrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(nrTotal).format({ thousandSeparated: true, mantissa: 2 });

	// 		}

	// 		const ccCutrent = this.state.dashbordData.dataBudget.companyBudget.cc.currentValue;
	// 		const ccTotal = this.state.dashbordData.dataBudget.companyBudget.cc.totalValue;

	// 		let ccPercent = "0%";
	// 		let ccStr = "-/-";
	// 		if (ccTotal != "-" && +ccTotal != 0) {
	// 			ccPercent = (+ccCutrent / +ccTotal) * 100 + "%";

	// 			ccStr = numbro(ccCutrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(ccTotal).format({ thousandSeparated: true, mantissa: 2 });

	// 		}

	// 		const hospitalCutrent = this.state.dashbordData.dataBudget.companyBudget.hospital.currentValue;
	// 		const hospitalTotal = this.state.dashbordData.dataBudget.companyBudget.hospital.totalValue;
	// 		let hospitalPercent = "0%";
	// 		let hospitalStr = "-/-";
	// 		if (hospitalTotal != "-" && +hospitalTotal != 0) {
	// 			hospitalPercent = (+hospitalCutrent / +hospitalTotal) * 100 + "%";
	// 			hospitalStr = numbro(hospitalCutrent).format({ thousandSeparated: true, mantissa: 2 }) + "/" + numbro(hospitalTotal).format({ thousandSeparated: true, mantissa: 2 })

	// 		}

	// 		return (
	// 			<Stack
	// 				styles={{
	// 					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
	// 				}}
	// 				tokens={{ childrenGap: 10 }}
	// 			>
	// 				<Stack>
	// 					<span style={{ fontWeight: 'bold' }}>Company Budget</span>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{nrStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '2rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: nrPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.6rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							NR
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{ccStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: ccPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							CC
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack>
	// 					<span style={{ textAlign: 'right' }}>{hospitalStr}</span>
	// 					<div
	// 						style={{
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '2px',
	// 							height: '1.6rem',
	// 							width: '100%',
	// 						}}
	// 					>
	// 						<div
	// 							style={{
	// 								backgroundColor: '#C0ABFB',
	// 								borderRadius: '2px',
	// 								height: '100%',
	// 								width: hospitalPercent,
	// 							}}
	// 						></div>
	// 						<div
	// 							style={{
	// 								position: 'absolute',
	// 								marginTop: '-1.4rem',
	// 								marginLeft: '1rem',
	// 								color: 'white',
	// 							}}
	// 						>
	// 							Hospital
	// 						</div>
	// 					</div>
	// 				</Stack>

	// 				<Stack
	// 					horizontal
	// 					verticalAlign={'center'}
	// 					styles={{
	// 						root: {
	// 							paddingTop: '1rem',
	// 						},
	// 					}}
	// 					tokens={{ childrenGap: 10 }}
	// 				>
	// 					<div
	// 						style={{
	// 							height: '10px',
	// 							width: '10px',
	// 							backgroundColor: '#E7DFFD',
	// 							borderRadius: '10px',
	// 						}}
	// 					></div>
	// 					<span>Total Budget Amount</span>
	// 					<div
	// 						style={{
	// 							height: '10px',
	// 							width: '10px',
	// 							backgroundColor: '#C0ABFB',
	// 							borderRadius: '10px',
	// 						}}
	// 					></div>
	// 					<span>Spending Amount</span>
	// 				</Stack>
	// 			</Stack>
	// 		)

	// 	}

	renderDeptBudget1 = () => {
		let html;

		if (
			this.state.dashbordData.dataBudget.departmentBudget &&
			this.state.dashbordData.dataBudget.departmentBudget.length > 0
		) {
			html = this.state.dashbordData.dataBudget.departmentBudget.map(
				(v: any) => {
					const name = v.name;
					const current = v.currentValue;
					const total = v.totalValue;
					let percent = '0%';
					let str = '-/-';
					if (total !== '-' && current !== '-') {
						str =
						numbro(current).format({ thousandSeparated: true, mantissa: 2 }) +
						'/' +
						numbro(total).format({ thousandSeparated: true, mantissa: 2 });
					}

					if (total !== '-' && +current > 0 && +total > 0) {
						percent = (+current / +total) * 100 + '%';
					}
					// if (total != '-' && +total != 0) {
					// 	percent = (+current / +total) * 100 + '%';
					// 	str =
					// 		numbro(current).format({ thousandSeparated: true, mantissa: 2 }) +
					// 		'/' +
					// 		numbro(total).format({ thousandSeparated: true, mantissa: 2 });
					// }
					return (
						<Stack key={name}>
							<span style={{ textAlign: 'right' }}>{str}</span>
							<div
								style={{
									backgroundColor: '#E7DFFD',
									borderRadius: '2px',
									height: '2rem',
									width: '100%',
								}}
							>
								<div
									style={{
										backgroundColor: '#C0ABFB',
										borderRadius: '2px',
										height: '100%',
										width: percent,
									}}
								></div>
								<div
									style={{
										position: 'absolute',
										marginTop: '-1.6rem',
										marginLeft: '1rem',
										color: 'white',
									}}
								>
									{name}
								</div>
							</div>
						</Stack>
					);
				}
			);
		} else {
			html = <div></div>;
		}

		return (
			<Stack
				styles={{
					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
				}}
				tokens={{ childrenGap: 10 }}
			>
				<Stack>
					<span style={{ fontWeight: 'bold' }}>Department Budget</span>
				</Stack>
				{html}
				<Stack
					horizontal
					verticalAlign={'center'}
					styles={{
						root: {
							paddingTop: '1rem',
						},
					}}
					tokens={{ childrenGap: 10 }}
				>
					<div
						style={{
							height: '10px',
							width: '10px',
							backgroundColor: '#E7DFFD',
							borderRadius: '10px',
						}}
					></div>
					<span>Total Budget Amount</span>
					<div
						style={{
							height: '10px',
							width: '10px',
							backgroundColor: '#C0ABFB',
							borderRadius: '10px',
						}}
					></div>
					<span>Spending Amount</span>
				</Stack>
			</Stack>
		);
	};
	renderCompanytBudget1 = () => {
		let html;

		if (
			this.state.dashbordData.dataBudget.companyBudget &&
			this.state.dashbordData.dataBudget.companyBudget.length > 0
		) {
			html = this.state.dashbordData.dataBudget.companyBudget.map((v: any) => {
				const name = v.name;
				const current = v.currentValue;
				const total = v.totalValue;
				let percent = '0%';
				let str = '-/-';
				if (total !== '-' && current !== '-') {
					str =
					numbro(current).format({ thousandSeparated: true, mantissa: 2 }) +
					'/' +
					numbro(total).format({ thousandSeparated: true, mantissa: 2 });
				}
				
				if (total !== '-' && +current > 0 && +total > 0) {
					percent = (+current / +total) * 100 + '%';
				}
				// if (total != '-' && +total != 0) {
				// 	percent = (+current / +total) * 100 + '%';
				// 	str =
				// 		numbro(current).format({ thousandSeparated: true, mantissa: 2 }) +
				// 		'/' +
				// 		numbro(total).format({ thousandSeparated: true, mantissa: 2 });
				// }
				return (
					<Stack key={name}>
						<span style={{ textAlign: 'right' }}>{str}</span>
						<div
							style={{
								backgroundColor: '#E7DFFD',
								borderRadius: '2px',
								height: '2rem',
								width: '100%',
							}}
						>
							<div
								style={{
									backgroundColor: '#C0ABFB',
									borderRadius: '2px',
									height: '100%',
									width: percent,
								}}
							></div>
							<div
								style={{
									position: 'absolute',
									marginTop: '-1.6rem',
									marginLeft: '1rem',
									color: 'white',
								}}
							>
								{name}
							</div>
						</div>
					</Stack>
				);
			});
		} else {
			html = <div></div>;
		}

		return (
			<Stack
				styles={{
					root: { backgroundColor: '#FEFEFE', padding: '1rem' },
				}}
				tokens={{ childrenGap: 10 }}
			>
				<Stack>
					<span style={{ fontWeight: 'bold' }}>Company Budget</span>
				</Stack>

				{html}
				<Stack
					horizontal
					verticalAlign={'center'}
					styles={{
						root: {
							paddingTop: '1rem',
						},
					}}
					tokens={{ childrenGap: 10 }}
				>
					<div
						style={{
							height: '10px',
							width: '10px',
							backgroundColor: '#E7DFFD',
							borderRadius: '10px',
						}}
					></div>
					<span>Total Budget Amount</span>
					<div
						style={{
							height: '10px',
							width: '10px',
							backgroundColor: '#C0ABFB',
							borderRadius: '10px',
						}}
					></div>
					<span>Spending Amount</span>
				</Stack>
			</Stack>
		);
	};

	returnContent = () => {
		let html = <></>;
		const role = this.props.currentUser?.role;
		if (role) {
			switch (role) {
				case 'BU Coordinator':
					html = (
						<>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyRequet()}
								</div>
							</div>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyBudget()}
								</div>
							</div>
						</>
					);
					break;
				case 'Budget Owner':
					html = (
						<>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyRequet()}
								</div>
							</div>
						</>
					);
					break;
				case 'BU Head':
					html = (
						<>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyApproval()}
								</div>
							</div>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg6'>
									{this.renderMyBudget()}
								</div>
								<div className='ms-Grid-col ms-sm12 ms-lg6'>
									{this.renderDeptBudget1()}
								</div>
							</div>
						</>
					);
					break;
				case 'PIC':
					html = (
						<>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyRequet()}
								</div>
							</div>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyBudget()}
								</div>
							</div>
						</>
					);
					break;
				case 'FD':
				case 'GM':
				case 'Finance':
					html = (
						<>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderMyApproval()}
								</div>
							</div>
							<div
								className='ms-Grid-row'
								style={{ padding: '2rem 0.5rem 0 1rem' }}
							>
								<div className='ms-Grid-col ms-sm12 ms-lg12'>
									{this.renderCompanytBudget1()}
								</div>
							</div>
						</>
					);
					break;
			}
		}
		return html;
	};

	render() {
		return (
			<>
				<DashboardToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
				/>

				<div className='ms-Grid'>{this.returnContent()}</div>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		currentUser: state.currentUser,
	}),
	{ sharedSwithPortalNav }
)(Dashboard);

import { IAction, IUser } from '../../schemas';
import { GET_CURRENTUSER_PROFILE } from '../constant';

const initState: IUser = {};

export default function currentUserReducer(
	preState = initState,
	action: IAction<any>
): IUser {
	const { type, data } = action;
	switch (type) {
		case GET_CURRENTUSER_PROFILE:
			return data;
		default:
			return preState;
	}
}

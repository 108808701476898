import { get, post, put, del } from './comm-http';
import { IDictItem, IResponse } from '../schemas';

/**
 * Get the dictSubExpense list
 * @param params The params used to query dictSubExpense
 * @returns DictSubExpense list
 */
async function getDictSubExpensesApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/subexpense', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getDictSubExpenseByIdApi(
	id: string
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem>>(`/dict/subexpense/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new dictSubExpense
 * @param data DictSubExpense data
 * @returns DictSubExpense data from backend service after the dictSubExpense is created
 */
async function addDictSubExpenseApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IDictItem, IResponse<IDictItem>>('/dict/subexpense', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a dictSubExpense
 * @param data DictSubExpense data
 * @returns DictSubExpense data from backend service after the dictSubExpense is updated
 */
async function updateDictSubExpenseApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IDictItem, IResponse<IDictItem>>(
		`/dict/subexpense/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a dictSubExpense
 * @param data DictSubExpense data
 * @returns DictSubExpense data from backend service after the dictSubExpense is updated
 */
async function deleteDictSubExpenseApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IDictItem>>(`/dict/subexpense/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getDictSubExpensesApi,
	getDictSubExpenseByIdApi,
	addDictSubExpenseApi,
	updateDictSubExpenseApi,
	deleteDictSubExpenseApi,
};

import React, { Component } from 'react';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import { IDictItem } from '../../schemas';

class MyContextMenu extends React.Component<any, any> {
	acttionbtnStyle = {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	};

	_menuItemClick = (_, item) => {
		this.props.onMenuItemClick(item?.key);
	};

	_getMenuProps = () => {
		return {
			shouldFocusOnMount: true,
			shouldFocusOnContainer: true,
			items: [
				{
					key: 'details',
					text: 'Details',
					iconProps: { iconName: 'Search' },
					onClick: this._menuItemClick,
				},
				{
					key: 'edit',
					text: 'Edit',
					iconProps: { iconName: 'Edit' },
					onClick: this._menuItemClick,
				},
				{
					key: 'delete',
					text: 'Delete',
					iconProps: { iconName: 'Delete' },
					onClick: this._menuItemClick,
				},
			],
		};
	};

	render() {
		const menuProps = this._getMenuProps();
		return (
			<IconButton
				persistMenu={false}
				menuProps={menuProps}
				style={this.acttionbtnStyle}
			/>
		);
	}
}

export interface IDictsState {
	columns: IColumn[];
	selectionDetails: string;
	announcedMessage?: string;
}

export default class DictList extends Component<any, IDictsState> {
	private _selection: Selection;

	constructor(props: {}) {
		super(props);

		const columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				fieldName: 'optionMenu',

				onRender: (item: IDictItem) => (
					<MyContextMenu item={item} onMenuItemClick={this._onMenuItemClick} />
				),
			},
			{
				key: 'itemName',
				name: 'Name',
				fieldName: 'itemName',
				minWidth: 100,
				maxWidth: 100,
				isRowHeader: true,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
		];

		this._selection = new Selection({
			onSelectionChanged: () => {
				this.setState({
					selectionDetails: this._getSelectionDetails(),
				});
			},
		});

		this.state = {
			columns: columns,
			selectionDetails: this._getSelectionDetails(),
			announcedMessage: undefined,
		};
	}

	render() {
		const { columns } = this.state;

		const { items } = this.props;

		return (
			<div
				style={{
					height: 'calc(100vh - 7rem)',
					width: '100%',
					overflow: 'auto',
					padding: '10px',
				}}
			>
				<DetailsList
					items={items}
					columns={columns}
					selectionMode={SelectionMode.single}
					getKey={this._getKey}
					setKey='none'
					selection={this._selection}
					layoutMode={DetailsListLayoutMode.fixedColumns}
					isHeaderVisible={true}
					// onItemInvoked={this._onItemInvoked}
				/>
			</div>
		);
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	private _onItemInvoked(item: any): void {
		alert(`Item invoked: ${item.name}`);
	}

	private _getSelectionDetails(): any {
		const selectionCount = this._selection.getSelectedCount();

		switch (selectionCount) {
			case 0:
				return null;
			case 1:
				return this._selection.getSelection()[0];
			default:
				return null;
		}
	}

	private _onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		const { columns } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				this.setState({
					announcedMessage: `${currColumn.name} is sorted ${
						currColumn.isSortedDescending ? 'descending' : 'ascending'
					}`,
				});
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		this.setState({
			columns: newColumns,
		});
	};

	_onMenuItemClick = (menuKey) => {
		const selectItem = this._getSelectionDetails();
		this.setState({
			selectionDetails: selectItem,
		});
		this.props.onOptionMenuClick(menuKey, selectItem);
	};
}

function _copyAndSort<T>(
	items: T[],
	columnKey: string,
	isSortedDescending?: boolean
): T[] {
	const key = columnKey as keyof T;
	return items
		.slice(0)
		.sort((a: T, b: T) =>
			(isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
		);
}

import React, { Component } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { Announced } from '@fluentui/react/lib/Announced';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import {
	ContextualMenuItemType,
	IContextualMenuProps,
} from '@fluentui/react/lib/ContextualMenu';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TooltipHost } from '@fluentui/react';
import { IIconProps } from '@fluentui/react';
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { useConst } from '@fluentui/react-hooks';
import { connect } from 'react-redux';
import { IStore } from '../../schemas';
import numbro from 'numbro';

const iconStyle = {
	root: {
		color: 'black',
	},
};

class MyContextMenu extends React.Component<any, any> {
	acttionbtnStyle = {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	};

	_menuItemClick = (_, item) => {
		this.props.onMenuItemClick(item?.key);
	};

	_getMenuProps = () => {
		const { item } = this.props;
		const initMenuStatus = {
			details: false,
			edit: false,
			modify: false,
			delete: false,
			history: false,
			submit: false,
		};
		let menuStatus = { ...initMenuStatus };
		if (item.status === 'Draft' || item.status === 'Rejected') {
			menuStatus = {
				...menuStatus,
				details: true,
				edit: true,
				delete: true,
				modify: false,
				history: false,
				submit: true,
			};
		} else if (item.status === 'Submitted') {
			menuStatus = {
				...menuStatus,
				details: true,
				edit: false,
				delete: false,
				modify: false,
				history: false,
				submit: false,
			};
		} else if (item.status === 'Approved') {
			menuStatus = {
				...menuStatus,
				details: true,
				edit: false,
				delete: false,
				modify:
					this.props.currentUser.role == 'BU Coordinator' ||
					this.props.currentUser.role == 'Finance'
						? true
						: false,
				history: false,
				submit: false,
			};
		} else if (item.status === 'Modified') {
			menuStatus = {
				details: true,
				edit: false,
				delete: false,
				modify: false,
				history: false,
				submit: false,
			};
		}

		return {
			shouldFocusOnMount: true,
			shouldFocusOnContainer: true,
			items: [
				{
					key: 'details',
					text: 'Details',
					iconProps: { iconName: 'Search' },
					hidden: !menuStatus.details,
					onClick: this._menuItemClick,
				},
				{
					key: 'edit',
					text: 'Edit',
					iconProps: { iconName: 'Edit' },
					hidden: !menuStatus.edit,
					onClick: this._menuItemClick,
				},
				{
					key: 'delete',
					text: 'Delete',
					iconProps: { iconName: 'Delete' },
					hidden: !menuStatus.delete,
					onClick: this._menuItemClick,
				},
				{
					key: 'modify',
					text: 'Modify',
					iconProps: { iconName: 'EditNote' },
					hidden: !menuStatus.modify,
					onClick: this._menuItemClick,
				},
				{
					key: 'history',
					text: 'History',
					iconProps: { iconName: 'History' },
					hidden: !menuStatus.history,
					onClick: this._menuItemClick,
				},
				{ key: 'divider_1', itemType: ContextualMenuItemType.Divider },
				{
					key: 'submit',
					text: 'Submit',
					iconProps: { iconName: 'SetAction' },
					hidden: !menuStatus.submit,
					onClick: this._menuItemClick,
				},
			],
		};
	};

	render() {
		const menuProps = this._getMenuProps();
		return (
			<IconButton
				persistMenu={false}
				menuProps={menuProps}
				style={this.acttionbtnStyle}
			/>
		);
	}
}

export interface IDetailsListDocumentsExampleState {
	columns: IColumn[];
	selectionDetails: string;
	isModalSelection: boolean;
	isCompactMode: boolean;
	announcedMessage?: string;
}

export interface IDocument {
	key: string;
	itemNo: string;
	bucket: string;
	year: number;
	q1Value: number;
	q2Value: number;
	q3Value: number;
	q4Value: number;
	fyValue: number;
	ytdBalance: number;
	status: string;
}

class BudgetList extends Component<any, IDetailsListDocumentsExampleState> {
	private _selection: Selection;

	constructor(props: {}) {
		super(props);

		const columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				fieldName: 'optionMenu',

				onRender: (item: IDocument) => (
					<MyContextMenu
						item={item}
						currentUser={this.props.currentUser}
						onMenuItemClick={this._onMenuItemClick}
					/>
				),
			},
			{
				key: 'itemNo',
				name: 'Item No.',
				fieldName: 'itemNo',
				minWidth: 80,
				isRowHeader: true,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'bucketTitle',
				name: 'Bucket',
				fieldName: 'bucketTitle',
				minWidth: 100,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'budgetYear',
				name: 'Year',
				fieldName: 'budgetYear',
				minWidth: 30,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'number',
			},
			{
				key: 'liveQ1Value',
				name: 'Q1',
				fieldName: 'liveQ1Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveQ2Value',
				name: 'Q2',
				fieldName: 'liveQ2Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveQ3Value',
				name: 'Q3',
				fieldName: 'liveQ3Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveQ4Value',
				name: 'Q4',
				fieldName: 'liveQ4Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveFyValue',
				name: 'FY',
				fieldName: 'liveFyValue',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'balanceAmtYtd',
				name: 'YTD Balance to go',
				fieldName: 'balanceAmtYtd',
				minWidth: 120,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'status',
				name: 'Status',
				fieldName: 'status',
				minWidth: 60,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			},
		];

		this._selection = new Selection({
			onSelectionChanged: () => {
				this.setState({
					selectionDetails: this._getSelectionDetails(),
				});
			},
		});

		this.state = {
			columns: columns,
			selectionDetails: this._getSelectionDetails(),
			isModalSelection: false,
			isCompactMode: false,
			announcedMessage: undefined,
		};
	}

	getColumns = () => {
		let role = this.props.currentUser.role;

		let columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				fieldName: 'optionMenu',

				onRender: (item: IDocument) => (
					<MyContextMenu
						item={item}
						currentUser={this.props.currentUser}
						onMenuItemClick={this._onMenuItemClick}
					/>
				),
			},
			{
				key: 'itemNo',
				name: 'Item No.',
				fieldName: 'itemNo',
				minWidth: 80,
				isRowHeader: true,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'bucketTitle',
				name: 'Bucket',
				fieldName: 'bucketTitle',
				minWidth: 100,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'budgetYear',
				name: 'Year',
				fieldName: 'budgetYear',
				minWidth: 30,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'number',
			},
			{
				key: 'liveQ1Value',
				name: 'Q1',
				fieldName: 'liveQ1Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveQ2Value',
				name: 'Q2',
				fieldName: 'liveQ2Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveQ3Value',
				name: 'Q3',
				fieldName: 'liveQ3Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveQ4Value',
				name: 'Q4',
				fieldName: 'liveQ4Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'liveFyValue',
				name: 'FY',
				fieldName: 'liveFyValue',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'balanceAmtYtd',
				name: 'YTD Balance to go',
				fieldName: 'balanceAmtYtd',
				minWidth: 120,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
		];

		if (role != 'FD' && role != 'GM') {
			columns.push({
				key: 'status',
				name: 'Status',
				fieldName: 'status',
				minWidth: 60,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			});
		}

		this.setState({ columns: columns });
	};

	componentDidMount() {
		//let role=this.props.currentUser.role;
		//this.getColumns();
	}

	_renderItemColumn = (item: any, index: number, column: any) => {
		const fieldContent = item[column.fieldName];
		//return <span>1111</span>
		switch (column.key) {
			case 'liveQ1Value':
			case 'liveQ2Value':
			case 'liveQ3Value':
			case 'liveQ4Value':
			case 'liveFyValue':
				return (
					<span>
						{numbro(+fieldContent).format({
							thousandSeparated: true,
							mantissa: 2,
						})}
					</span>
				);
			case 'balanceAmtYtd':
				const actualAmtYtd = item['actualAmtYtd'] ?? 0;
				const liveFyValue = item['liveFyValue'] ?? 0;
				// const value = numbro(liveFyValue - actualAmtYtd)
				return (
					<span>
						{numbro(+liveFyValue - +actualAmtYtd).format({
							thousandSeparated: true,
							mantissa: 2,
						})}
					</span>
				);
			default:
				return <span>{fieldContent}</span>;
		}
	};

	render() {
		let { columns, isCompactMode, isModalSelection } = this.state;

		const { items, currentUser } = this.props;

		return (
			<div
				style={{
					height: 'calc(100vh - 7rem)',
					width: '100%',
					overflow: 'auto',
					padding: '10px',
				}}
			>
				<DetailsList
					items={items}
					compact={isCompactMode}
					columns={columns}
					selectionMode={SelectionMode.single}
					getKey={this._getKey}
					setKey='none'
					selection={this._selection}
					layoutMode={DetailsListLayoutMode.fixedColumns}
					isHeaderVisible={true}
					// onItemInvoked={this._onItemInvoked}
					onRenderItemColumn={(item: any, index: any, column: any) =>
						this._renderItemColumn(item, index, column)
					}
				/>
			</div>
		);
	}

	componentDidUpdate(previousProps: any, previousState: any) {
		if (
			previousState.isModalSelection !== this.state.isModalSelection &&
			!this.state.isModalSelection
		) {
			this._selection.setAllSelected(false);
		}

		if (previousProps.items != this.props.items) {
			this.getColumns();
		}
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	private _onChangeCompactMode = (
		ev: React.MouseEvent<HTMLElement>,
		checked?: boolean
	): void => {
		this.setState({ isCompactMode: checked || false });
	};

	private _onChangeModalSelection = (
		ev: React.MouseEvent<HTMLElement>,
		checked?: boolean
	): void => {
		this.setState({ isModalSelection: checked || false });
	};

	private _onItemInvoked(item: any): void {
		alert(`Item invoked: ${item.name}`);
	}

	private _getSelectionDetails(): any {
		const selectionCount = this._selection.getSelectedCount();

		switch (selectionCount) {
			case 0:
				return null;
			case 1:
				return this._selection.getSelection()[0];
			default:
				return null;
		}
	}

	private _onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		const { columns } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				this.setState({
					announcedMessage: `${currColumn.name} is sorted ${
						currColumn.isSortedDescending ? 'descending' : 'ascending'
					}`,
				});
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		this.setState({
			columns: newColumns,
		});
	};

	_onMenuItemClick = (menuKey) => {
		const selectItem = this._getSelectionDetails();
		this.setState({
			selectionDetails: selectItem,
		});
		this.props.onOptionMenuClick(menuKey, selectItem);
	};
}

export default connect((state: IStore) => ({
	currentUser: state.currentUser,
}))(BudgetList);

function _copyAndSort<T>(
	items: T[],
	columnKey: string,
	isSortedDescending?: boolean
): T[] {
	const key = columnKey as keyof T;
	return items
		.slice(0)
		.sort((a: T, b: T) =>
			(isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
		);
}

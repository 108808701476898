import { IAction, IRequest } from '../../schemas';
import {
	GET_REQUEST_LIST,
	ADD_REQUEST,
	UPDATE_REQUEST,
	DELETE_REQUEST,
} from '../constant';

const initState: any = {};

export default function requestsReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case GET_REQUEST_LIST:
			return { ...data };
		case ADD_REQUEST:
			const newdata = [...preState.data, data];
			return { data: newdata, total: preState.total };
		case UPDATE_REQUEST:
			const index = preState.data.findIndex(
				(item) => item.id === action.data.id
			);
			const updateData = [
				...preState.data.slice(0, index),
				{
					...data,
				},
				...preState.data.slice(index + 1),
			];
			return { data: updateData, total: preState.total };
		case DELETE_REQUEST:
			return {
				data: preState.data.filter((d) => d.id !== action.data.id),
				total: preState.total,
			};
		default:
			return preState;
	}
}

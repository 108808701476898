import React, { Component } from 'react';
import './index.scss';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Redirect } from 'react-router-dom';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { getUserValidateApi } from '../../api/user';
import { IStore } from '../../schemas';
import {
	openLoadingDialog
} from '../../redux/actions';
import { connect } from 'react-redux';
import { getauthorizationCodeApi } from '../../api'
interface ILoginState {
	logined?: boolean;
	loading?: boolean;

}

interface ILoginProps {
	currentUser: any
	openLoadingDialog: (data) => void;
	common?: any;
}

class LoginAuth extends Component<ILoginProps, ILoginState> {
	private _token = '';
	public state: ILoginState = {
		logined: false,
	};
	componentDidMount() {
		const tokenCode = localStorage.getItem('teamsAuthToken');
		if (tokenCode) {
			this.setState({ logined: true })
		}
	}

	getAuthCode = () => {
		this.props.openLoadingDialog({ loadingOpen: true })
		window.location.assign("https://fedlogin.jnj.com/as/authorization.oauth2?grant_type=authorization_code&client_id=acuvue&response_type=code&scope=openid&redirect_uri=https://bme.acuvue.com.cn/auth-end")
	}

	render() {
		let html: any = null;
		if (this.state.logined) {
			html = <Redirect to='/portal' />
		} else {
			html = <div className='loginAuth' >
				<img src="/bg.png" style={{ marginTop: '100px', marginBottom: '20px', height: '400px' }}></img>
				<PrimaryButton
					text='Click to Authentication'
					disabled={false}
					onClick={() => {
						this.getAuthCode();
					}}
				/>

			</div>
		}
		return (
			<>
				<div>{html}</div>
				{this.props.common.loadingOpen ? (
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							top: 0,
							left: 0,
							backgroundColor: 'rgba(0, 0, 0, 0.6)',
							zIndex: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Spinner
							size={SpinnerSize.large}
							label='Authorization...'
							styles={{
								label: {
									color: 'white',
									fontSize: '1rem',
									fontWeight: 'bold',
								},
							}}
						/>
					</div>
				) : null}
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		currentUser: state.currentUser,
		common: state.common
	}),
	{
		openLoadingDialog
	}
)(LoginAuth);

import React, { Component } from 'react';
import { IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { ISapData, IStore } from '../../schemas';
import { connect } from 'react-redux';
import { updateSapDataAsync } from '../../redux/actions';
import numbro from 'numbro';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class SapDetailPanel extends Component<any, any> {
	state = {
		sapdata: {} as ISapData,
		ItemNoErrorMsg: '',
	};

	closePanel = () => {
		this.setState({ ItemNoErrorMsg: '' });

		this.props.onDismiss(false);
	};

	onBtnSaveClick = (_) => {
		const { sapdata } = this.state;
		if (!sapdata.itemNo || sapdata.itemNo == '') {
			this.setState({ ItemNoErrorMsg: 'Please enter the "Item No."' });
		} else {
			this.props.updateSapDataAsync(sapdata);
			this.props.onDismiss(false);
		}
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
					Cancel
				</DefaultButton>
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
					Save
				</PrimaryButton>
			</div>
		);
	};

	onTextChanged = (name) => {
		const { sapdata } = this.state;

		return (_, data) => {
			this.setState({
				sapdata: { ...sapdata, [name]: data },
			});
		};
	};

	componentDidMount() {
		const { detail } = this.props;
		this.setState({
			sapdata: { ...detail },
		});
	}

	render() {
		const { sapdata } = this.state;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Maintain SAP Data'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Item No.'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('itemNo')}
									value={sapdata.itemNo}
									errorMessage={this.state.ItemNoErrorMsg}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Account'
									disabled
									defaultValue={sapdata.account}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Text'
									disabled
									defaultValue={sapdata.text}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						{/* <Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Requisition Title'
									disabled
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack> */}

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Document Header Text'
									disabled
									defaultValue={sapdata.documentHeaderText}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Year/Month'
									disabled
									defaultValue={sapdata.yearMonth}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Cost Ctr'
									disabled
									defaultValue={sapdata.costCtr}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
							<Stack.Item grow>
								<TextField
									label='Annaul in local cur.'
									disabled
									defaultValue={sapdata.amount}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect((state: IStore) => ({}), {
	updateSapDataAsync,
})(SapDetailPanel);

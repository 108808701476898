import React, { Component } from 'react';
import {
	SpeakerFeeSubToolbar,
	SpeakerFeeSubList,
	SpeakerFeeSubDetailPanel,
	SpeakerFeeSubNewPanel,
	SpeakerFeeSubEditPanel,
} from '../../components';
import { IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { connect } from 'react-redux';
import { IStore } from '../../schemas';
import {
	addSpeakerFeeAsync,
	deleteSpeakerFeeAsync,
	getSpeakerFeesAsync,
	submitSpeakerFeeAsync,
} from '../../redux/actions';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
	root: {
		color: 'black',
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',
	},
	rootHovered: {
		color: 'grey',
	},
};

class SpeakerFeePopModal extends Component<any, any> {
	state = {
		isDetailPanelOpened: false,
		isPanelEditOpened: false,
		isPanelNewOpened: false,
		selectedRow: {},
		currentBudget: {},
	};

	/**
	 * Triggered when option menu is clicked
	 * @param menuKey The key of the option menu item
	 * @param dataItem The data item of select row
	 */
	optionMenuClick = (menuKey, dataItem: any) => {
		console.log('-------------speaker fee optionMenuClick', menuKey, dataItem);
		switch (menuKey) {
			case 'details':
				this.setState({
					isDetailPanelOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'edit':
				this.setState({
					isPanelEditOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'delete':
				this.props.deleteSpeakerFeeAsync(dataItem);
				break;
			default:
				break;
		}
	};

	closePanel = (panelName) => {
		switch (panelName) {
			case 'details':
				this.setState({
					isDetailPanelOpened: false,
				});
				break;
			case 'new':
				this.setState({
					isPanelNewOpened: false,
				});
				break;
			case 'edit':
				this.setState({
					isPanelEditOpened: false,
				});
				break;
			default:
				break;
		}
	};

	closeModal = () => {
		this.props.onDismiss();
	};

	onBtnAddItemClick = (data) => {
		this.props.onBtnAddItemClick(data);
	};

	createSpeakerFee = () => {
		console.log(':::::::::::::::::budget:::::::', this.state.currentBudget);
		this.setState({
			isPanelNewOpened: true,
		});
	};

	componentDidMount() {
		const { budget } = this.props;
		const params = { budgetId: budget.id };
		this.props.getSpeakerFeesAsync(params);

		this.setState({
			currentBudget: budget,
		});
	}

	render() {
		const { speakerfees } = this.props;
		return (
			<>
				<div
					style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}
				>
					<span id='speakerFeeModal' style={{ fontWeight: 'bold' }}>
						Speaker Fee
					</span>
					<IconButton
						styles={iconButtonStyles}
						iconProps={cancelIcon}
						onClick={this.closeModal}
					/>
				</div>
				{this.state.isDetailPanelOpened ? (
					<div
						style={{
							height: '540px',
							overflowY: 'auto',
							padding: '1rem 2rem 0',
						}}
					>
						<SpeakerFeeSubDetailPanel
							detail={this.state.selectedRow}
							onDismiss={this.closePanel}
						/>
					</div>
				) : null}

				{this.state.isPanelNewOpened ? (
					<div
						style={{
							height: '540px',
							overflowY: 'auto',
							padding: '1rem 2rem 0',
						}}

					>
						<SpeakerFeeSubNewPanel
							budgetDetail={this.state.currentBudget}
							onDismiss={this.closePanel}
						/>
					</div>
				) : null
				}

				{
					this.state.isPanelEditOpened ? (
						<div
							style={{
								height: '540px',
								overflowY: 'auto',
								padding: '1rem 2rem 0',
							}}
						>
							<SpeakerFeeSubEditPanel
								detail={this.state.selectedRow}
								onDismiss={this.closePanel}
								onBtnAddItemClick={this.onBtnAddItemClick}
							/>
						</div>
					) : null
				}

				{
					this.state.isDetailPanelOpened ||
						this.state.isPanelNewOpened ||
						this.state.isPanelEditOpened ? null : (
						<>
							<SpeakerFeeSubToolbar onNewRecordBtnClick={this.createSpeakerFee} />
							<SpeakerFeeSubList
								items={speakerfees.data ?? speakerfees}
								onOptionMenuClick={this.optionMenuClick}
							/>
						</>
					)
				}
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		speakerfees: state.speakerfees,
	}),
	{
		getSpeakerFeesAsync,
		addSpeakerFeeAsync,
		deleteSpeakerFeeAsync,
		submitSpeakerFeeAsync,
	}
)(SpeakerFeePopModal);

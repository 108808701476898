import { get, post, put, del } from './comm-http';
import { ISpeakerFee, IResponse } from '../schemas';

/**
 * Get the speakerFee list
 * @param params The params used to query speakerFee
 * @returns SpeakerFee list
 */
async function getSpeakerFeesApi(params): Promise<IResponse<ISpeakerFee[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<ISpeakerFee[]>>('/speakerFee', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getSpeakerFeeByIdApi(
	id: string
): Promise<IResponse<ISpeakerFee>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<ISpeakerFee>>(`/speakerFee/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new speakerFee
 * @param data SpeakerFee data
 * @returns SpeakerFee data from backend service after the speakerFee is created
 */
async function addSpeakerFeeApi(
	data: ISpeakerFee
): Promise<IResponse<ISpeakerFee>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<ISpeakerFee, IResponse<ISpeakerFee>>('/speakerFee', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a speakerFee
 * @param data SpeakerFee data
 * @returns SpeakerFee data from backend service after the speakerFee is updated
 */
async function updateSpeakerFeeApi(
	data: ISpeakerFee
): Promise<IResponse<ISpeakerFee>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<ISpeakerFee, IResponse<ISpeakerFee>>(
		`/speakerFee/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a speakerFee
 * @param data SpeakerFee data
 * @returns SpeakerFee data from backend service after the speakerFee is updated
 */
async function deleteSpeakerFeeApi(
	data: ISpeakerFee
): Promise<IResponse<ISpeakerFee>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<ISpeakerFee>>(`/speakerFee/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Submit a speakerFee
 * @param data SpeakerFee data
 * @returns SpeakerFee data from backend service after the speakerFee is submitted.
 */
async function submitSpeakerFeeApi(
	data: ISpeakerFee
): Promise<IResponse<ISpeakerFee>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<ISpeakerFee, IResponse<ISpeakerFee>>(
		`/speakerFee/submit`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

export {
	getSpeakerFeesApi,
	getSpeakerFeeByIdApi,
	addSpeakerFeeApi,
	updateSpeakerFeeApi,
	deleteSpeakerFeeApi,
	submitSpeakerFeeApi,
};

import { IAction, IUser } from '../../schemas';
import { GET_BUDGETOWNER_LIST } from '../constant';

const initState: IUser[] = [];

export default function budgetownerReducer(
	preState = initState,
	action: IAction<any>
): IUser[] {
	const { type, data } = action;
	switch (type) {
		case GET_BUDGETOWNER_LIST:
			return [...data];
		default:
			return preState;
	}
}

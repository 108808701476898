import {
	ADD_SPEAKERFEE,
	UPDATE_SPEAKERFEE,
	DELETE_SPEAKERFEE,
	GET_SPEAKERFEE_LIST,
} from '../constant';
import {
	getSpeakerFeesApi,
	addSpeakerFeeApi,
	updateSpeakerFeeApi,
	deleteSpeakerFeeApi,
	submitSpeakerFeeApi,
} from '../../api';

export const getSpeakerFees = (data) => ({
	type: GET_SPEAKERFEE_LIST,
	data,
});

export const addSpeakerFee = (data) => ({
	type: ADD_SPEAKERFEE,
	data,
});

export const updateSpeakerFee = (data) => ({
	type: UPDATE_SPEAKERFEE,
	data,
});

export const deleteSpeakerFee = (data) => ({
	type: DELETE_SPEAKERFEE,
	data,
});

export const getSpeakerFeesAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getSpeakerFeesApi(params)
			.then((data) => {
				// if (data.errorCode.trim().length === 0) {
				// 	dispatch(getSpeakerFees(data.value));
				// } else {
				// 	console.error(`${data.errorCode}:: ${data.errorMessage}`);
				// }

				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getSpeakerFees({ data: (data.value as any)?.data ?? [], total: totalpage })
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

export const addSpeakerFeeAsync = (data) => async (dispatch) => {
	try {
		const response = await addSpeakerFeeApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(addSpeakerFee(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateSpeakerFeeAsync = (data) => async (dispatch) => {
	try {
		const response = await updateSpeakerFeeApi(data);
		if (response.errorCode.trim().length === 0) {
			console.log(':::::::::::updateSpeakerFeeAsync', response.value);
			dispatch(updateSpeakerFee(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteSpeakerFeeAsync = (data) => async (dispatch) => {
	try {
		const response = await deleteSpeakerFeeApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(deleteSpeakerFee(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const submitSpeakerFeeAsync = (data) => async (dispatch) => {
	try {
		const response = await submitSpeakerFeeApi(data);
		if (response.errorCode.trim().length === 0) {
			console.log(':::::::::::submitSpeakerFeeAsync', response.value);
			dispatch(updateSpeakerFee(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

import { Component } from 'react';
import { Link } from '@fluentui/react/lib/Link';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import * as OPTIONS from '../options';
import { connect } from 'react-redux';
import { IBudget, IRequest, IStore, IRequestItem } from '../../schemas';
import { addRequestAsync } from '../../redux/actions/requests';
import { SearchableDropdown } from '..';
import numbro from 'numbro';
import {
	MessageBar,
	MessageBarType,
	Spinner,
	SpinnerSize,
} from '@fluentui/react';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { isNumber, isEmptyStr } from '../../utilities';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const dropdownStyles1: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	dropdownItem: { textOverflow: 'hidden', minHeight: 46 },
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const requestItemsStyle = {
	root: {
		marginTop: 8,
	}
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};


class BudgetPanelModify extends Component<any> {
	state = {
		request: {} as IRequest,
		requestItems: [{} as IRequestItem] as IRequestItem[],
		toBudget: {} as IBudget,
		modifyTypeItems: [],
		amountErrorMsg: '',
		isSaving: false,
		messageBar: { show: false, msg: '' },
		isRequestItemsOpened: false,
	};

	closePanel = () => {
		if (!this.state.isRequestItemsOpened) {
			this.props.onDismiss(false);
		}
	};

	closeRequestItems = () => {
		console.log('closeRequestItems');
		this.setState({ isRequestItemsOpened: false });
	};

	preCheck = () => {
		const { request, requestItems } = this.state;
		if (request.requestType == 'Bucket Change' || request.requestType == 'Bucket Change Cross Owners') {
			if (requestItems.length == 0) {
				this.setState({
					messageBar: { show: true, msg: 'Please add adjustment item' }
				});
				return false;
			}

			var checkItemsResult = true;
			for (var i = 0; i < requestItems.length; i++) {
				var item = requestItems[i];
				if (isEmptyStr(item.toBucketId)) {
					checkItemsResult = false;
				}
				if (isEmptyStr(item.fromQuater)) {
					checkItemsResult = false;
				}
				if (isEmptyStr(item.toQuater)) {
					checkItemsResult = false;
				}
				if (!isNumber(item.adjustValue)) {
					checkItemsResult = false;
				}
			}

			if (!checkItemsResult) {
				this.setState({
					messageBar: { show: true, msg: 'request details are incomplete' }
				});
				return false;
			}

			// const fromQuater = requestItems[0].fromQuater;
			// for (var i = 0; i < requestItems.length; i++) {
			// 	if (requestItems[i].fromQuater != fromQuater) {
			// 		this.setState({
			// 			messageBar: { show: true, msg: 'fromQuater must be consistent' }
			// 		});
			// 		return false;
			// 	}
			// }

			for (var i = 0; i < requestItems.length; i++) {
				let amount = 0;
				let fromQuater = requestItems[i].fromQuater;
				let liveValue = request[`live${fromQuater}Value`];

				requestItems.forEach(o => {
					if (o.fromQuater == fromQuater) {
						amount += Number(o.adjustValue)
					}
				});
				if (amount > liveValue) {
					this.setState({
						messageBar: { show: true, msg: `amount must less than live quarter(${fromQuater})` }
					});
					return false;
				}
			}

			return true;
		}

		if (!request.requestType || request.requestType == '') {
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Modify Type"' },
			});
			return false;
		}
		if (!request.toBucketId || request.toBucketId == '') {
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "To Bucket"' },
			});
			return false;
		}
		if (!request.fromQuater || request.fromQuater == '') {
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "From Quarter"' },
			});
			return false;
		}
		if (!request.toQuater || request.toQuater == '') {
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "To Quarter"' },
			});
			return false;
		}
		if (!request.adjustValue || request.adjustValue == '') {
			this.setState({
				messageBar: { show: true, msg: 'Please enter the "Amount"' },
			});
			return false;
		}

		if (request.fromQuater && request.adjustValue) {
			const liveValue = request[`live${request.fromQuater}Value`];
			var amount = request.adjustValue;
			if (amount > liveValue) {
				this.setState({
					messageBar: { show: true, msg: 'amount must less than live quarter' }
				});
				return false;
			}
		}
		return true;
	};
	onBtnSaveClick = () => {
		if (!this.preCheck()) {
			return;
		}
		const { request, requestItems } = this.state;
		// const expenseName =
		// 	(this.state.request as any).toBucketexpenseName === 'Speaker Fee' ?? null;
		// if (expenseName) {
		// 	request["SpeakerFeeBatchId"] = this.props.speakerFeeBatchId;
		// }
		this.setState({
			isSaving: true,
		});

		var groupRequest = { requests: [] as IRequestItem[] };
		if (request.requestType != 'Bucket Change' && request.requestType != 'Bucket Change Cross Owners') {
			let item = {
				requestType: request.requestType,
				fromBucketId: request.fromBucketId,
				toBucketId: request.toBucketId,
				fromQuater: request.fromQuater,
				toQuater: request.toQuater,
				adjustValue: request.adjustValue,
				adjustComments: request.adjustComments
			} as IRequestItem
			groupRequest.requests.push(item);
		}
		else {
			requestItems.forEach(o => {
				let item = {
					requestType: request.requestType,
					fromBucketId: request.fromBucketId,
					toBucketId: o.toBucketId,
					fromQuater: o.fromQuater,
					toQuater: o.toQuater,
					adjustValue: o.adjustValue,
					adjustComments: request.adjustComments
				} as IRequestItem
				groupRequest.requests.push(item);
			})
		}

		this.props.addRequestAsync(groupRequest).then((result) => {
			if (result && result.errorCode && result.errorCode.length > 0) {
				this.setState({
					messageBar: { show: true, msg: result.errorCode },
				});
				this.setState({
					isSaving: false,
				});
			} else if (result) {
				this.setState({
					isSaving: false,
				});
				this.props.onDismiss(false);
			}
		});
	};
	onTextChanged = (name) => {
		const { request } = this.state;
		return (_, data) => {
			this.setState({
				request: { ...request, [name]: data },
			});
		};
	};
	onDropDownChanged1 = (e, data, name) => {
		if (isNaN(data)) {
			const { request } = this.state;
			this.setState({
				request: {
					...request,
					toBucketId: data.key,
					toBucketexpenseName: isNaN(data) ? data.expenseName : e.expenseName,
				},
				toBudget: {
					...data.data,
				},
			});
		}
	};
	onDropDownChanged = (name) => {
		const { request } = this.state;

		return (_, data) => {
			console.log('--------searchabledropdown', data);
			if (name === 'toBucket') {
				this.setState({
					request: {
						...request,
						toBucketId: data.key,
						toBucketexpenseName: data.expenseName,
					},
					toBudget: {
						...data.data,
					},
				});
			} else {
				const optionObj = {
					[`${name}`]: data.text,
				};
				this.setState({
					request: { ...request, ...optionObj },
					requestItems: [{} as IRequestItem],
				});
			}
		};
	};

	onDropDownItemChanged = (d, item, i, name) => {
		const { requestItems } = this.state;
		const optionObj = {
			[`${name}`]: d.text,
		};
		item = { ...item, ...optionObj }
		requestItems[i] = { ...item }
		this.setState({
			requestItems: requestItems,
		});
		// console.log("onDropDownItemChanged", i, requestItems[i], optionObj)
	};
	onDropDownItemChanged2 = (d, item, i) => {
		if (isNaN(d)) {
			const { requestItems } = this.state;
			const optionObj = {
				toBucketId: d.key,
				toBucketexpenseName: d.expenseName,
				toBucketText: d.text,
			};
			item = { ...item, ...optionObj }
			requestItems[i] = { ...item }
			this.setState({
				requestItems: requestItems,
			});
		}
	};
	onTextItemChanged = (v, item, i, name) => {
		const { requestItems } = this.state;
		const optionObj = {
			[`${name}`]: v,
		};
		item = { ...item, ...optionObj }
		requestItems[i] = { ...item }
		this.setState({
			requestItems: requestItems,
		});
		// console.log("onTextItemChanged", i, requestItems[i], optionObj)
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		const expenseName = (this.state.request as any).toBucketexpenseName === 'Speaker Fee' ?? null;
		console.log('expenseName', (this.state.request as any).toBucketexpenseName);
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
					Cancel
				</DefaultButton>
				{expenseName ? (
					<PrimaryButton
						styles={buttonStyle}
						onClick={() => this.doSubAction('openSpeakerFee')}
					>
						Speaker Fee
					</PrimaryButton>
				) : null}
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
					Submit
				</PrimaryButton>
			</div>
		);
	};
	doSubAction = (actionType) => {
		//console.log(this.props)
		console.log('::::::::::::::toBudget', this.state.toBudget);
		return this.props.onSubAction(actionType, this.state.toBudget);
	};

	componentDidMount() {
		const { detail, currentUser } = this.props;
		this.setState({
			request: { ...detail, fromBucketId: detail.id },
		});

		switch (currentUser.role) {
			case 'BU Coordinator':
				this.setState({ modifyTypeItems: OPTIONS.modifyTypeBuOptions });
				break;
			default:
				this.setState({ modifyTypeItems: OPTIONS.modifyTypeOptions });
				break;
		}
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	render() {
		const {
			dictionary,
			coordinators,
			budgetowners,
			currentUser,
			pics,
			common,
		} = this.props;
		const { request } = this.state;
		const items = currentUser.role;
		let subDepts = [];
		let subDeptsNew: any = [];
		let columns: IColumn[] = [
			{
				key: 'fromQuarter',
				name: 'From Quarter',
				fieldName: 'fromQuater',
				minWidth: 90,
				onRender: (item, index) => (
					<Dropdown
						options={ooption}
						styles={dropdownStyles}
						selectedKey={item.fromQuater}
						onChange={(e, d, i) => this.onDropDownItemChanged(d, item, index, 'fromQuater')
						}
					/>)
			},
			{
				key: 'toBucket',
				name: 'To Bucket',
				fieldName: 'itemNo',
				minWidth: 180,
				onRender: (item, index) => (<SearchableDropdown
					// placeholder='select a bucket'
					api='getBudgetsApi'
					options={[]}
					styles={dropdownStyles1}
					multiSelect={false}
					selectedKey={item.toBucketId}
					onChange={(e, v) => this.onDropDownItemChanged2(v, item, index)}
				/>)
			},
			{
				key: 'toQuarter',
				name: 'To Quarter',
				fieldName: 'toQuater',
				minWidth: 90,
				onRender: (item, index) => (<Dropdown
					options={ooption}
					styles={dropdownStyles}
					selectedKey={item.toQuater}
					onChange={(e, d, i) => this.onDropDownItemChanged(d, item, index, 'toQuater')}
				/>)
			},
			{
				key: 'amount',
				name: 'Amount',
				fieldName: 'adjustValue',
				minWidth: 70,
				onRender: (item, index) => (<TextField
					key='txtModifyAmount'
					borderless
					styles={textFieldSytles}
					value={item.adjustValue}
					errorMessage={this.state.amountErrorMsg}
					onChange={(e, v) => this.onTextItemChanged(v, item, index, 'adjustValue')}
				/>)
			},
			{
				key: 'action',
				name: 'Action',
				fieldName: '',
				minWidth: 50,
				isResizable: false,
				onRender: (item, index) => (
					<>
						<Link style={{ border: 'none', fontSize: 20, color: 'green', textDecoration: 'none', width: 24, height: 20 }}
							onClick={() => {
								const { requestItems } = this.state;
								requestItems.push({} as IRequestItem);
								this.setState({
									requestItems: requestItems
								});
								console.log("requestItems", requestItems);
							}}>+</Link>

						<Link style={{ border: 'none', fontSize: 20, color: 'red', textDecoration: 'none', width: 24, height: 20 }}
							onClick={() => {
								const { requestItems } = this.state;
								requestItems.splice(Number(index), 1);
								this.setState({
									requestItems: requestItems
								});
							}}>-</Link>
					</>
				),
			}
		];

		if (
			common.teamMappings &&
			common.teamMappings.length > 0 &&
			request.teamName &&
			request.teamName != ''
		) {
			subDepts = common.teamMappings.filter((v) => {
				if (request.teamName === 'Finance') {
					return true;
				} else {
					return v.name === request.teamName;
				}
			});
			if (subDepts.length > 0) {
				subDeptsNew = subDepts.map((c: any) => {
					return { key: c.department, text: c.department };
				});
			}
		}
		let ooption: any = [];
		let monthData = new Date();
		var month = monthData.getMonth() + 1;
		if (month == 1 || month == 2 || month == 3) {
			ooption = [
				{ key: 'Q1', text: 'Q1' },
				{ key: 'Q2', text: 'Q2' },
				{ key: 'Q3', text: 'Q3' },
				{ key: 'Q4', text: 'Q4' }]
		} else if (month == 4 || month == 5 || month == 6) {
			ooption = [
				{ key: 'Q2', text: 'Q2' },
				{ key: 'Q3', text: 'Q3' },
				{ key: 'Q4', text: 'Q4' }]
		} else if (month == 7 || month == 8 || month == 9) {
			ooption = [
				{ key: 'Q3', text: 'Q3' },
				{ key: 'Q4', text: 'Q4' }]
		} else {
			ooption = [{ key: 'Q4', text: 'Q4' }]
		}
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Modify Budget Bucket'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					{this.state.messageBar.show ? (
						<MessageBar
							messageBarType={MessageBarType.error}
							isMultiline={false}
							onDismiss={() => {
								this.setState({ messageBar: { show: false } });
							}}
							dismissButtonAriaLabel='Close'
							styles={{
								root: {
									position: 'fixed',
									zIndex: 10000,
									width: 'calc(100% - 50px)',
								},
							}}
						>
							{this.state.messageBar.msg}
						</MessageBar>
					) : null}
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtItemNo'
									label='Item No.'
									disabled
									borderless
									styles={textFieldSytles}
									value={request.itemNo}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtBudgetYear'
									label='Budget Year'
									disabled
									borderless
									styles={textFieldSytles}
									value={request.budgetYear}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtBucketTitle'
									label='Bucket'
									disabled
									borderless
									styles={textFieldSytles}
									value={request.bucketTitle}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpCoodinator'
									label='Coordinator'
									options={coordinators.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									selectedKey={request.coodinatorId}
									disabled={true}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpTeam'
									label='Team'
									options={dictionary.teams.map((item) => {
										return { key: item.itemValue, text: item.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={request.teamId}
									disabled={true}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpSubDepartment'
									label='Sub Department'
									options={subDeptsNew}
									styles={dropdownStyles}
									selectedKey={request.subDepartmentName}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpBudgetType'
									label='Type'
									// options={OPTIONS.typeOptions}
									options={dictionary.budgetTypes.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={request.budgetTypeId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpPlatform'
									label='Platform/Customer'
									// options={OPTIONS.platformOptions}
									options={dictionary.platforms.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={request.platformId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpCategory'
									label='Product Category'
									// options={OPTIONS.categoryOptions}
									options={dictionary.categories.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={request.categoryId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpExpense'
									label='Expense Category'
									// options={OPTIONS.expenseOptions}
									options={dictionary.expenses.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={request.expenseId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpSubExpense'
									label='SubExpense Category'
									// options={OPTIONS.expenseOptions}
									options={[{ key: '', text: '' }].concat(
										dictionary.subExpenses.map((data) => {
											return { key: data.itemName, text: data.itemName };
										})
									)}
									styles={dropdownStyles}
									selectedKey={request.subExpenseId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpBudgetOwner'
									label='Budget Owner'
									options={budgetowners.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									styles={dropdownStyles}
									selectedKey={request.budgetOwnerId}
									disabled
								/>
							</Stack.Item>

							<Stack.Item styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtBudgetOwnerId'
									label='Budget Owner ID'
									disabled
									borderless
									styles={textFieldSytles}
									value={request.budgetOwnerId}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item styles={{ root: { width: '50%' } }}>
								<Dropdown
									key='dpPic'
									label='PIC'
									options={pics.map((data) => {
										return { key: data.userEmail, text: data.userName, data };
									})}
									disabled={true}
									styles={dropdownStyles}
									// onChange={(_, data) => this.onDropDownChanged2('pic', data)}
									selectedKey={request.picId}
								/>
							</Stack.Item>

							<Stack.Item styles={{ root: { width: '50%' } }}>
								<TextField
									key='txtPicId'
									label='PIC ID'
									disabled
									borderless
									styles={textFieldSytles}
									value={request.picId}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'xLarge'}>Original Version</Text>
						</Stack.Item>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtInitQ1Value'
									label='Q1'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.initQ1Value}
									defaultValue={numbro(request.initQ1Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitQ2Value'
									label='Q2'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.initQ2Value}
									defaultValue={numbro(request.initQ2Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitQ3Value'
									label='Q3'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.initQ3Value}
									defaultValue={numbro(request.initQ3Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitQ4Value'
									label='Q4'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.initQ4Value}
									defaultValue={numbro(request.initQ4Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtInitFyValue'
									label='FY'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.initFyValue}
									defaultValue={numbro(request.initFyValue).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'xLarge'}>Live Version</Text>
						</Stack.Item>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									key='txtLiveQ1Value'
									label='Q1'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.liveQ1Value}
									defaultValue={numbro(request.liveQ1Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveQ2Value'
									label='Q2'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.liveQ2Value}
									defaultValue={numbro(request.liveQ2Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveQ3Value'
									label='Q3'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.liveQ3Value}
									defaultValue={numbro(request.liveQ3Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveQ4Value'
									label='Q4'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.liveQ4Value}
									defaultValue={numbro(request.liveQ4Value).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									key='txtLiveFyValue'
									label='FY'
									disabled
									borderless
									styles={textFieldSytles}
									// value={request.liveFyValue}
									defaultValue={numbro(request.liveFyValue).format({ thousandSeparated: true, mantissa: 2 })}
								/>
							</Stack.Item>
						</Stack>

						<Stack.Item grow>
							<TextField
								key='txtComments'
								label='Comments'
								multiline={true}
								disabled
								borderless
								styles={textFieldSytles}
								value={request.comments}
							/>
						</Stack.Item>

						<Stack.Item grow className='sub-header'>
							<Text variant={'xLarge'}>Adjustments</Text>
						</Stack.Item>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpModifyType'
									label='Modify Type'
									options={this.state.modifyTypeItems}
									styles={dropdownStyles}
									onChange={this.onDropDownChanged('requestType')}
								/>
							</Stack.Item>

							<Stack.Item grow={1} styles={stackItemStyles}>
								<Dropdown
									key='dpModifyExpense'
									label='Expense Category'
									// options={OPTIONS.expenseOptions}
									options={dictionary.expenses.map((data) => {
										return { key: data.itemName, text: data.itemName };
									})}
									styles={dropdownStyles}
									selectedKey={request.expenseId}
									disabled
								/>
							</Stack.Item>
						</Stack>

						{
							(this.state.request.requestType == 'Bucket Change' || this.state.request.requestType == 'Bucket Change Cross Owners') ?
								(
									<DetailsList
										items={this.state.requestItems}
										compact={true}
										styles={requestItemsStyle}
										columns={columns}
										selectionMode={SelectionMode.none}
										getKey={this._getKey}
										setKey='none'
										layoutMode={DetailsListLayoutMode.justified}
										isHeaderVisible={true}
									/>) : null
						}
						{
							(this.state.request.requestType != 'Bucket Change' && this.state.request.requestType != 'Bucket Change Cross Owners') ?
								(<>
									<Stack.Item grow>
										<SearchableDropdown
											placeholder='Select an option'
											api='getBudgetsApi'
											label='To Bucket'
											options={[]}
											styles={dropdownStyles}
											multiSelect={false}
											onChange={(e, v) => this.onDropDownChanged1(e, v, 'toBucket')}
										/>
									</Stack.Item>

									<Stack horizontal tokens={stackTokens}>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<Dropdown
												key='dpModifyFromQuater'
												label='From Quarter'
												//options={[
												//	{ key: 'q1', text: 'Q1' },
												//	{ key: 'q2', text: 'Q2' },
												//	{
												//		key: 'q3',
												//		text: 'Q3',
												//	},
												//	{ key: 'q4', text: 'Q4' },
												//]}
												options={ooption}
												styles={dropdownStyles}
												onChange={this.onDropDownChanged('fromQuater')}
											/>
										</Stack.Item>

										<Stack.Item grow={1} styles={stackItemStyles}>
											<Dropdown
												key='dpModifyToQuater'
												label='To Quarter'
												options={ooption}
												//options={[
												//	{ key: 'q1', text: 'Q1' },
												//	{ key: 'q2', text: 'Q2' },
												//	{
												//		key: 'q3',
												//		text: 'Q3',
												//	},
												//	{ key: 'q4', text: 'Q4' },
												//]}
												styles={dropdownStyles}
												onChange={this.onDropDownChanged('toQuater')}
											/>
										</Stack.Item>
									</Stack>

									<Stack.Item grow>
										<TextField
											key='txtModifyAmount'
											label='Amount'
											borderless
											styles={textFieldSytles}
											onChange={this.onTextChanged('adjustValue')}
											errorMessage={this.state.amountErrorMsg}
										/>
									</Stack.Item>
								</>) : null
						}

						<Stack.Item grow>
							<TextField
								key='txtModifyNote'
								label='Note'
								multiline={true}
								borderless
								styles={textFieldSytles}
								onChange={this.onTextChanged('adjustComments')}
							/>
						</Stack.Item>
					</Stack>
					{this.state.isSaving ? (
						<div className='panel-loading'>
							<Spinner
								size={SpinnerSize.large}
								label='Saving...'
								styles={{
									label: {
										color: 'white',
										fontSize: '1rem',
										fontWeight: 'bold',
									},
								}}
							/>
						</div>
					) : null}
				</Panel>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		dictionary: state.dictionary,
		coordinators: state.coordinators,
		budgetowners: state.budgetowners,
		currentUser: state.currentUser,
		pics: state.pics,
		common: state.common,
	}),
	{
		addRequestAsync,
	}
)(BudgetPanelModify);

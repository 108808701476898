import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getPoDatasAsync,
	getSapDatasAsync,
	openLoadingDialog,
	sharedSwithPortalNav,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	PoDetailPanel,
	PoList,
	PoPagination,
	DataMaintanceToolbar,
	SapList,
	SapDetailPanel,
	SapPagination,
} from '../../components';
import './index.scss';
import { uploadPoFileApi, uploadSapFileApi } from '../../api';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';

class DataMaintance extends Component<any, any> {
	fileInputRef: React.RefObject<HTMLInputElement>;
	private _searchParams: any = {};
	state = {
		isDetailOpened: false,
		currentTab: 'po',
		selectedRow: {},
		poPage: 1,
		sapPage: 1,
		messageBar: { messageBarType: MessageBarType.error, show: false, msg: '' },
	};

	private _poPagination: any = null;
	private _sapPagination: any = null;
	private _toobar: any = null;

	/**
	 * Triggered when option menu is clicked
	 * @param menuKey The key of the option menu item
	 * @param dataItem The data item of select row
	 */
	optionMenuClick = (menuKey, dataItem: any) => {
		console.log('-------------optionMenuClick', menuKey, dataItem);
		switch (menuKey) {
			case 'edit':
				this.setState({ isDetailOpened: true, selectedRow: { ...dataItem } });
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		console.log('-------------panel dismiss');
		this.setState({ isDetailOpened: isOpen });
	};

	pageClickPo = (page) => {
		this.setState({ poPage: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	pageClickSap = (page) => {
		this.setState({ sapPage: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	onUploadBtnClick = () => {
		this.fileInputRef.current?.click();
	};

	onFileChange = (e) => {
		console.log('-------------onFileChange', e);

		const currentFile = e.target.files[0];

		let uploadApi: Function | undefined;
		if (this.state.currentTab === 'po') {
			uploadApi = uploadPoFileApi;
		} else if (this.state.currentTab === 'sap') {
			uploadApi = uploadSapFileApi;
		}

		if (uploadApi) {
			this.props.openLoadingDialog({ loadingOpen: true });
			uploadApi(currentFile, (event) => {
				// this.setState({
				// 	progress: Math.round((100 * event.loaded) / event.total),
				// });
			})
				.then((response) => {
					console.log('--------------onFileChange response', response);
					if (this.fileInputRef.current) {
						this.fileInputRef.current.value = '';
					}
					this.props.openLoadingDialog({ loadingOpen: false });
					if (response.data?.errorCode && response.data?.errorCode.length > 0) {
						this.setState({
							messageBar: {
								messageBarType: MessageBarType.error,
								show: true,
								msg: response.data?.errorMessage,
							},
						});
					} else {
						this.setState({
							messageBar: {
								messageBarType: MessageBarType.success,
								show: true,
								msg: response.data?.errorMessage,
							},
						});
					}
				})
				.catch((e) => {
					console.log('--------------onFileChange error', e);
					if (this.fileInputRef.current) {
						this.fileInputRef.current.value = '';
					}
					this.props.openLoadingDialog({ loadingOpen: false });
				});
		}
	};

	constructor(props) {
		super(props);
		this.fileInputRef = React.createRef();
	}

	componentDidMount() {
		this.getData();
	}

	switchTab = (tab) => {
		(this._toobar as any).setState({ searchValue: '' });
		this._searchParams = { searchText: '', pageNumber: 1 };
		this.setState({ currentTab: tab }, () => this.getData());
	};

	searchClick = (data) => {
		const { searchValue } = data;
		this._searchParams = { searchText: searchValue, pageNumber: 1 };
		if (this.state.currentTab === 'po') {
			this._poPagination.setState({ page: 1 });
		} else if (this.state.currentTab === 'sap') {
			this._sapPagination.setState({ page: 1 });
		}
		this.setState({ searchValue, page: 1 });
		this.getData();
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		if (this.state.currentTab === 'po') {
			this.props.getPoDatasAsync(this._searchParams).then(() => {
				this.props.openLoadingDialog({ loadingOpen: false });
			});
		} else if (this.state.currentTab === 'sap') {
			this.props.getSapDatasAsync(this._searchParams).then(() => {
				this.props.openLoadingDialog({ loadingOpen: false });
			});
		}
	};

	render() {
		const { podatas, sapdatas } = this.props;
		return (
			<>
				{this.state.messageBar.show ? (
					<MessageBar
						messageBarType={this.state.messageBar.messageBarType}
						isMultiline={false}
						onDismiss={() => {
							this.setState({ messageBar: { show: false } });
						}}
						dismissButtonAriaLabel='Close'
					>
						{this.state.messageBar.msg}
					</MessageBar>
				) : null}

				<DataMaintanceToolbar
					ref={(v) => {
						this._toobar = v;
					}}
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onUploadBtnClick={this.onUploadBtnClick}
					onSwitchTabClick={this.switchTab}
					searchClick={this.searchClick}
					//searchValue={this.state.searchText}
					// searchChange={this.searchChange}
				/>

				{/* <DataMaintanceList onOptionMenuClick={this.optionMenuClick} /> */}
				{this.state.currentTab === 'po' ? (
					<>
						<PoList
							items={podatas.data ?? podatas}
							onOptionMenuClick={this.optionMenuClick}
						/>
						<PoPagination
							ref={(v) => {
								{
									this._poPagination = v;
								}
							}}
							pageCount={this.props.podatas.total ?? 10}
							onPageClick={this.pageClickPo}
						/>
					</>
				) : null}
				{this.state.currentTab === 'sap' ? (
					<>
						<SapList
							items={sapdatas.data ?? sapdatas}
							onOptionMenuClick={this.optionMenuClick}
						/>
						<SapPagination
							ref={(v) => {
								{
									this._sapPagination = v;
								}
							}}
							pageCount={this.props.sapdatas.total ?? 10}
							onPageClick={this.pageClickSap}
						/>
					</>
				) : null}

				{this.state.currentTab === 'po' && this.state.isDetailOpened ? (
					<PoDetailPanel
						isOpen={this.state.isDetailOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closeDetailPanel}
					/>
				) : null}

				{this.state.currentTab === 'sap' && this.state.isDetailOpened ? (
					<SapDetailPanel
						isOpen={this.state.isDetailOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closeDetailPanel}
					/>
				) : null}

				<input
					type='file'
					accept='.xlsx'
					ref={this.fileInputRef}
					style={{ display: 'none' }}
					onChange={this.onFileChange}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		podatas: state.podatas,
		sapdatas: state.sapdatas,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{ getPoDatasAsync, getSapDatasAsync, sharedSwithPortalNav, openLoadingDialog }
)(DataMaintance);

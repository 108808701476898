import { get, post, put, del } from './comm-http';
import { IBudget, IBudgetValidationInfo, IResponse } from '../schemas';

/**
 * Get the budget list
 * @param params The params used to query budget
 * @returns Budget list
 */
async function getBudgetsApi(params): Promise<IResponse<IBudget[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IBudget[]>>('/budget', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

/**
 * Get the approved budget list
 * @param params The params used to query budget
 * @returns Approved Budget list
 */
async function getApprovedBudgetsApi(params): Promise<IResponse<IBudget[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IBudget[]>>('/budget/approved', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getBudgetByIdApi(id: string): Promise<IResponse<IBudget>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IBudget>>(`/budget/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new budget
 * @param data Budget data
 * @returns Budget data from backend service after the budget is created
 */
async function addBudgetApi(data: IBudget): Promise<IResponse<IBudget>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IBudget, IResponse<IBudget>>('/budget', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a budget
 * @param data Budget data
 * @returns Budget data from backend service after the budget is updated
 */
async function updateBudgetApi(data: IBudget): Promise<IResponse<IBudget>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IBudget, IResponse<IBudget>>(`/budget/${data.id}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Delete a budget
 * @param data Budget data
 * @returns Budget data from backend service after the budget is updated
 */
async function deleteBudgetApi(data: IBudget): Promise<IResponse<IBudget>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IBudget>>(`/budget/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Submit a budget
 * @param data Budget data
 * @returns Budget data from backend service after the budget is submitted.
 */
async function submitBudgetApi(data: IBudget): Promise<IResponse<IBudget>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IBudget, IResponse<IBudget>>(`/budget/submit`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Check if the Item No. of the budget is valid.
 * Rules: 1. Can not be duplicate
 * @param data Budget data
 */
async function validateBudgetApi(
	data: IBudget
): Promise<IResponse<IBudgetValidationInfo>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IBudget, IResponse<IBudgetValidationInfo>>(
		`/budget/validate`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

export {
	getBudgetsApi,
	getApprovedBudgetsApi,
	getBudgetByIdApi,
	addBudgetApi,
	updateBudgetApi,
	deleteBudgetApi,
	submitBudgetApi,
	validateBudgetApi,
};

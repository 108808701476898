import { get, post, put, del } from './comm-http';
import { IDictItem, IResponse } from '../schemas';

/**
 * Get the dictPlatform list
 * @param params The params used to query dictPlatform
 * @returns DictPlatform list
 */
async function getDictPlatformsApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/platform', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getDictPlatformByIdApi(
	id: string
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem>>(`/dict/platform/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new dictPlatform
 * @param data DictPlatform data
 * @returns DictPlatform data from backend service after the dictPlatform is created
 */
async function addDictPlatformApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IDictItem, IResponse<IDictItem>>('/dict/platform', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a dictPlatform
 * @param data DictPlatform data
 * @returns DictPlatform data from backend service after the dictPlatform is updated
 */
async function updateDictPlatformApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IDictItem, IResponse<IDictItem>>(
		`/dict/platform/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a dictPlatform
 * @param data DictPlatform data
 * @returns DictPlatform data from backend service after the dictPlatform is updated
 */
async function deleteDictPlatformApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IDictItem>>(`/dict/platform/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getDictPlatformsApi,
	getDictPlatformByIdApi,
	addDictPlatformApi,
	updateDictPlatformApi,
	deleteDictPlatformApi,
};

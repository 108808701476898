//@ts-nocheck
import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownProps,
} from '@fluentui/react/lib/Dropdown';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { getBudgetsApi, getApprovedBudgetsApi, getSpeakerApi } from '../../api';
import { debounce } from '../../utilities';

interface ISearchableDropdownProps extends IDropdownProps {
	api?: string;
}

export default class SearchableDropdown extends Component<
	ISearchableDropdownProps,
	any
> {
	private _v: Dropdown;
	state = {
		searchText: '',
		searchOptions: [],
		searchOption: {},
	};

	onDropDownChange = (_, data) => {
		console.log('::::::::::::::::::onDropDownChange', data);
		this.props.onChange(_, data);
	};

	getBudgetOptions = debounce((event, searchText) => {
		console.log(this.props.api);
		// Fix the issue of trigger twice when text changed
		if (event.type === 'change') {
			const params = { searchText };
			this.setState({
				searchOptions: [],
			});
			if (this.props.api === 'getBudgetsApi') {
				getApprovedBudgetsApi(params)
					//getBudgetsApi(params)
					.then((data) => {
						if (data.errorCode.trim().length === 0) {
							console.log(':::::::::getbudgetapi:::::', data.value);

							const options = data.value.data.map((d) => {
								return {
									key: d.id,
									text: d.bucketTitle,
									expenseName: d.expenseName,
									data: d,
								};
							});

							options.unshift({ key: '', text: '', expenseName: '', data });
							console.log(':::::::::options:::::', options);
							this.setState({
								searchOptions: [...options],
							});
						} else {
							console.error(`${data.errorCode}:: ${data.errorMessage}`);
						}
					})
					.catch((error) => {
						throw error;
					});
			} else {
				getSpeakerApi(params)
					.then((data) => {
						if (data.errorCode.trim().length === 0) {
							const options = data.value.data.map((d) => {
								return {
									key: d.id,
									text: d.vendorCode,
									data: d,
								};
							});
							this.setState({
								searchOptions: [...options],
							});
						} else {
							console.error(`${data.errorCode}:: ${data.errorMessage}`);
						}
					})
					.catch((error) => {
						throw error;
					});
			}
		}
	});

	renderOption = (option: IDropdownOption) => {
		return option.itemType === DropdownMenuItemType.Header &&
			option.key === 'FilterHeader' ? (
			<SearchBox
				onChange={this.getBudgetOptions}
				underlined={true}
				placeholder='Search options'
			/>
		) : (
			<>{option.text}</>
		);
	};

	render() {
		const { searchOptions } = this.state;
		var x = [
			{
				key: 'FilterHeader',
				text: '-',
				itemType: DropdownMenuItemType.Header,
			},
			{
				key: 'divider_filterHeader',
				text: '-',
				itemType: DropdownMenuItemType.Divider,
			},
			...searchOptions,
		];
		const xxx = x.map((v) => {
			return v;
		});
		return (
			<Dropdown
				className='tobucket'
				ref={(v) => {
					this._v = v;
				}}
				{...this.props}
				options={xxx}
				// options={searchOptions}
				calloutProps={{ shouldRestoreFocus: false, setInitialFocus: false }} //not working
				onRenderOption={this.renderOption}
				notifyOnReselect={true}
				onDismiss={() => {
					// console.log(this._v)
					// if (this.state.searchOptions && this.state.searchOptions.length == 2) {
					// 	this.onDropDownChange("", this.state.searchOptions[1])
					// }
					this.setState({ searchText: '' });
				}}
				onChange={this.onDropDownChange}
			/>
		);
	}
}

import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IPoData, IStore } from '../../schemas';
import { connect } from 'react-redux';
import { updatePoDataAsync } from '../../redux/actions';
import numbro from 'numbro';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

class PoDetailPanel extends Component<any, any> {
	state = {
		podata: {} as IPoData,
		AmountErrorMsg: '',
		ItemNoErrorMsg: '',
	};

	closePanel = () => {
		this.setState({ ItemNoErrorMsg: '', AmountErrorMsg: '' });
		this.props.onDismiss(false);
	};

	onBtnSaveClick = (_) => {
		const { podata } = this.state;
		if (!podata.itemNo || podata.itemNo == '') {
			this.setState({ ItemNoErrorMsg: 'Please enter the "Item No."' });
		} else if (!podata.cnyAmount || podata.cnyAmount == '') {
			this.setState({ AmountErrorMsg: 'Please enter the "CNY Amount"' });
		} else {
			this.props.updatePoDataAsync(podata);
			this.props.onDismiss(false);
		}
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closePanel}>
					Cancel
				</DefaultButton>
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnSaveClick}>
					Save
				</PrimaryButton>
			</div>
		);
	};

	onTextChanged = (name) => {
		const { podata } = this.state;

		return (_, data) => {
			this.setState({
				podata: { ...podata, [name]: data },
			});
		};
	};

	componentDidMount() {
		const { detail } = this.props;
		console.log('::::::::::::::: podetailpanel ::::', this.props);
		this.setState({
			podata: { ...detail },
		});
	}

	render() {
		const { podata } = this.state;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closePanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Maintain PO Data'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Item No.'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('itemNo')}
									value={podata.itemNo}
									errorMessage={this.state.ItemNoErrorMsg}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='CNY Amount'
									borderless
									styles={textFieldSytles}
									onChange={this.onTextChanged('cnyAmount')}
									value={podata.cnyAmount}
									errorMessage={this.state.AmountErrorMsg}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Order ID'
									disabled
									defaultValue={podata.orderID}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Requisition ID'
									disabled
									defaultValue={podata.requisitionId}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Requisition Title'
									disabled
									defaultValue={podata.requisitionTitle}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Order Date'
									disabled
									defaultValue={podata.orderedDate}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Preparer'
									disabled
									defaultValue={podata.preparerUserName}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Original Currency Code'
									disabled
									defaultValue={podata.origCurrencyCode}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
							<Stack.Item grow>
								<TextField
									label='Account'
									disabled
									defaultValue={podata.accountName}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow style={{ width: '50%' }}>
								<TextField
									label='Cost Center'
									disabled
									defaultValue={podata.costCenterName}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>

							<Stack.Item grow style={{ width: '50%' }}>
								<TextField
									label='Sum(PO Spend in Original Currency)'
									disabled
									//	defaultValue={numbro(podata.totalOrigAmount).format({ thousandSeparated: true, mantissa: 2 })}
									defaultValue={podata.totalOrigAmount}
									borderless
									styles={textFieldSytles}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

export default connect((state: IStore) => ({}), {
	updatePoDataAsync,
})(PoDetailPanel);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Dropdown,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Button } from '@fluentui/react-northstar';
import {
	FilesUploadIcon,
	ExcelColorIcon,
	CloseIcon,
} from '@fluentui/react-icons-northstar';
import XLSX from 'xlsx';
import { IStore } from '../../schemas';
import { initBucketsAsync, updateBucketsAsync } from '../../redux/actions';

const mappingFields = {
	Bucket: 'bucketTitle',
	'Budget Owner': 'budgetOwnerName',
	'Budget Owner ID': 'budgetOwnerId',
	Comments: 'comments',
	Coordinator: 'coodinatorName',
	'Coordinator ID': 'coodinatorId',
	'Expense Category': 'expenseName',
	'Item No.': 'itemNo',
	LiveFY: 'liveFyValue',
	LiveQ1: 'liveQ1Value',
	LiveQ2: 'liveQ2Value',
	LiveQ3: 'liveQ3Value',
	LiveQ4: 'liveQ4Value',
	OriginalFY: 'initFyValue',
	OriginalQ1: 'initQ1Value',
	OriginalQ2: 'initQ2Value',
	OriginalQ3: 'initQ3Value',
	OriginalQ4: 'initQ4Value',
	PIC: 'pic',
	'PIC ID': 'picId',
	'Platform/Customer': 'platformName',
	'Product Category': 'categoryName',
	'Sub Department': 'subDepartmentName',
	'Sub expense category': 'subExpenseName',
	Team: 'teamName',
	Type: 'budgetTypeName',
	Year: 'budgetYear',
};

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const options: IDropdownOption[] = [
	{ key: 'Bucket Initialize', text: 'Bucket Initialize' },
	{ key: 'Bucket Update', text: 'Bucket Update' },
];

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

const fileStackStyles: IStackStyles = {
	root: {
		alignItems: 'center',
	},
};

const fileStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

class ImportBucketPanel extends Component<any> {
	state = {
		progress: 0,
		uploadInfo: '',
		fileInfos: [],
		importType: '',
		importBuckets: [],
		messageBar: { show: false, msg: '' },
		isSaving: false,
	};

	fileInputRef: React.RefObject<HTMLInputElement>;

	constructor(props) {
		super(props);
		this.fileInputRef = React.createRef();
	}

	closeDetailPanel = () => {
		if (this.fileInputRef.current) {
			this.fileInputRef.current.value = '';
		}
		this.setState({ fileInfos: [] });

		this.props.onDismiss(false);
	};

	preCheck = () => {
		const { importType, fileInfos } = this.state;
		let check = true;
		if (importType === '') {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Select the "Import Type"' },
			});
		} else if (fileInfos.length === 0) {
			check = false;
			this.setState({
				messageBar: { show: true, msg: 'Select the "Excel file"' },
			});
		}

		return check;
	};

	onDropDownChanged = (name, item?) => {
		if (name === 'importType') {
			this.setState({
				importType: item.text,
			});
		}
	};

	onBtnImportClick = async (_) => {
		if (!this.preCheck()) {
			return;
		}

		this.setState({
			isSaving: true,
		});

		const { importType, importBuckets } = this.state;

		if (importType === 'Bucket Initialize') {
			await this.props.initBucketsAsync(importBuckets);
		} else if (importType === 'Bucket Update') {
			await this.props.updateBucketsAsync(importBuckets);
		}
		this.setState({
			isSaving: false,
		});
		this.closeDetailPanel();
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle} onClick={this.closeDetailPanel}>
					Cancel
				</DefaultButton>
				<PrimaryButton styles={buttonStyle} onClick={this.onBtnImportClick}>
					Import
				</PrimaryButton>
			</div>
		);
	};

	onUploadClick = (e) => {
		this.setState({
			progress: 0,
		});
		this.fileInputRef.current?.click();
	};

	onFileChange = (e) => {
		console.log('-------------onFileChange', e);
		const currentFile = e.target.files[0];

		this.setState({
			uploadInfo: 'Uploading template, please wait...',
		});

		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			try {
				const fileArrayBuffer = event.target?.result;
				const workbook = XLSX.read(fileArrayBuffer, { type: 'binary' });
				let data = [];
				for (const sheet in workbook.Sheets) {
					if (workbook.Sheets.hasOwnProperty(sheet)) {
						data = data.concat(
							XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
						);
						break;
					}
				}
				console.log(':::::::::: excel data', data);

				let mappingData = data.map((item) => {
					let mappingItem = {};

					Object.keys(item).forEach((key) => {
						const mappingKey = mappingFields[key.trim()];
						if (mappingKey) {
							mappingItem[mappingKey] = (item[key] as any).toString();
						}
					});
					return mappingItem;
				});

				console.log(':::::::::: excel mapping data', mappingData);
				this.setState({
					fileInfos: [currentFile.name],
					importBuckets: mappingData,
				});
			} catch (error) {
				console.log(':::::::::: excel error', error);
			}
		};

		fileReader.readAsBinaryString(currentFile);
	};

	onDeleteFileClick = (event) => {
		if (this.fileInputRef.current) {
			this.fileInputRef.current.value = '';
		}

		this.setState({
			fileInfos: [],
			progress: 0,
			uploadInfo: '',
		});
	};

	render() {
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closeDetailPanel}
					onRenderFooterContent={this.onRenderFooterContent}
					type={PanelType.medium}
					headerText='Import Buckets'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						{this.state.messageBar.show ? (
							<MessageBar
								messageBarType={MessageBarType.error}
								isMultiline={false}
								onDismiss={() => {
									this.setState({ messageBar: { show: false } });
								}}
								dismissButtonAriaLabel='Close'
							>
								{this.state.messageBar.msg}
							</MessageBar>
						) : null}

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow styles={stackItemStyles}>
								<Dropdown
									label='Import Type'
									defaultSelectedKey='Bucket Initialization'
									options={options}
									styles={dropdownStyles}
									onChange={(_, data) =>
										this.onDropDownChanged('importType', data)
									}
								/>
							</Stack.Item>
						</Stack>

						<Stack>
							{this.state.progress === 0 ? (
								<Stack.Item>
									<Button
										icon={<FilesUploadIcon />}
										text
										content='Select the Excel file to import'
										disabled={this.state.progress > 0}
										onClick={this.onUploadClick}
									/>
									<input
										type='file'
										accept='.xlsx, .xls'
										ref={this.fileInputRef}
										style={{ display: 'none' }}
										onChange={this.onFileChange}
									/>
								</Stack.Item>
							) : null}

							{this.state.progress > 0 && this.state.fileInfos.length === 0 ? (
								<Stack.Item grow={1}>
									<ProgressIndicator
										description={`${this.state.uploadInfo} ${this.state.progress}%`}
										percentComplete={this.state.progress / 100}
									/>
								</Stack.Item>
							) : null}

							<Stack.Item>
								{this.state.fileInfos.map((fileInfo: any) => {
									return (
										<Stack
											horizontal
											key={fileInfo}
											styles={fileStackStyles}
											tokens={fileStackTokens}
										>
											<ExcelColorIcon size={'large'} />
											<span>{fileInfo}</span>
											<Button
												icon={<CloseIcon size={'medium'} />}
												text
												content=''
												styles={{ minWidth: '0' }}
												onClick={this.onDeleteFileClick}
											/>
										</Stack>
									);
								})}
							</Stack.Item>
						</Stack>
					</Stack>

					{this.state.isSaving ? (
						<div className='panel-loading'>
							<Spinner
								size={SpinnerSize.large}
								label='Importing...'
								styles={{
									label: {
										color: 'white',
										fontSize: '1rem',
										fontWeight: 'bold',
									},
								}}
							/>
						</div>
					) : null}
				</Panel>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		importHistories: state.importHistories,
	}),
	{ initBucketsAsync, updateBucketsAsync }
)(ImportBucketPanel);

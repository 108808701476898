import { get, post, put, del } from './comm-http';
import { IDictItem, IResponse } from '../schemas';

/**
 * Get the dictExpense list
 * @param params The params used to query dictExpense
 * @returns DictExpense list
 */
async function getDictExpensesApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/expense', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getDictExpenseByIdApi(
	id: string
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem>>(`/dict/expense/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new dictExpense
 * @param data DictExpense data
 * @returns DictExpense data from backend service after the dictExpense is created
 */
async function addDictExpenseApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IDictItem, IResponse<IDictItem>>('/dict/expense', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a dictExpense
 * @param data DictExpense data
 * @returns DictExpense data from backend service after the dictExpense is updated
 */
async function updateDictExpenseApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IDictItem, IResponse<IDictItem>>(
		`/dict/expense/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a dictExpense
 * @param data DictExpense data
 * @returns DictExpense data from backend service after the dictExpense is updated
 */
async function deleteDictExpenseApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IDictItem>>(`/dict/expense/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getDictExpensesApi,
	getDictExpenseByIdApi,
	addDictExpenseApi,
	updateDictExpenseApi,
	deleteDictExpenseApi,
};

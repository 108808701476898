import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getSpeakerDatasAsync,
	openLoadingDialog,
	sharedSwithPortalNav,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	SpeakerDetailPanel,
	SpeakerList,
	SpeakerPagination,
	SpeakerToolbar,
} from '../../components';
import './index.scss';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { uploadSpeakerFileApi } from '../../api';

class SpeakerData extends Component<any, any> {
	fileInputRef: React.RefObject<HTMLInputElement>;
	private _pagination: any = null;
	private _searchParams: any = {};

	state = {
		isDetailOpened: false,
		selectedRow: {},
		searchText: '',
		page: 1,
		messageBar: { messageBarType: MessageBarType.error, show: false, msg: '' }
	};

	optionMenuClick = (menuKey, dataItem: any) => {
		switch (menuKey) {
			case 'details':
				this.setState({ isDetailOpened: true, selectedRow: { ...dataItem } });
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		this.setState({ isDetailOpened: isOpen });
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isDetailOpened: true });
	};

	constructor(props) {
		super(props);
		console.log("index",this.props)

		this.fileInputRef = React.createRef();
	}

	componentDidMount() {
		this.getData();
	}
	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ page: 1 });
		this.setState({ searchText, page: 1 });
		this.getData();
	};

	onUploadBtnClick = () => {
		this.fileInputRef.current?.click();
	};

	onFileChange = (e) => {
		console.log('-------------onFileChange1', e);

		const currentFile = e.target.files[0];
		console.log("currentFile",currentFile);
		let uploadApi: Function | undefined;
		uploadApi = uploadSpeakerFileApi;

		if (uploadApi) {
			this.props.openLoadingDialog({ loadingOpen: true });
			uploadApi(currentFile, (event) => {
				// this.setState({
				// 	progress: Math.round((100 * event.loaded) / event.total),
				// });
			})
				.then((response) => {
					console.log('--------------onFileChange response', response.data.errorMessage);
					if (this.fileInputRef.current) {
						this.fileInputRef.current.value = '';
					}
					this.props.openLoadingDialog({ loadingOpen: false });
					if (response.data?.errorCode && response.data?.errorCode.length > 0) {
						this.setState({
							messageBar: {
								messageBarType: MessageBarType.error,
								show: true,
								msg: response.data?.errorMessage,
							},
						});
					} else {
						this.setState({
							messageBar: {
								messageBarType: MessageBarType.success,
								show: true,
								msg: response.data?.errorMessage,
							},
						});
					}
				})
				.catch((e) => {
					console.log('--------------onFileChange error', e);
					if (this.fileInputRef.current) {
						this.fileInputRef.current.value = '';
					}
					this.props.openLoadingDialog({ loadingOpen: false });
				});
		}
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props.getSpeakerDatasAsync(this._searchParams).then(() => {
			this.props.openLoadingDialog({ loadingOpen: false });
		});
	};

	render() {
		const { speakerdatas } = this.props;
		return (
			<>
				{this.state.messageBar.show ? (
					<MessageBar
						messageBarType={this.state.messageBar.messageBarType}
						isMultiline={false}
						onDismiss={() => {
							this.setState({ messageBar: { show: false } });
						}}
						dismissButtonAriaLabel='Close'
					>
						{this.state.messageBar.msg}
					</MessageBar>
				) : null}
				<SpeakerToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
					onUploadBtnClick={this.onUploadBtnClick}
				/>

				<SpeakerList
					items={speakerdatas.data ?? speakerdatas}
					onOptionMenuClick={this.optionMenuClick}
				/>

				<SpeakerDetailPanel
					isOpen={this.state.isDetailOpened}
					detail={this.state.selectedRow}
					onDismiss={this.closeDetailPanel}
				/>

				<SpeakerPagination
					ref={(v) => {
						{
							this._pagination = v;
						}
					}}
					onPageClick={this.pageClick}
					pageCount={this.props.speakerdatas.total ?? 10}
				/>

				<input
					type='file'
					accept='.xlsx'
					ref={this.fileInputRef}
					style={{ display: 'none' }}
					onChange={this.onFileChange}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		speakerdatas: state.speakerdatas,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{ getSpeakerDatasAsync, sharedSwithPortalNav, openLoadingDialog }
)(SpeakerData);

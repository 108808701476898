import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getSubDepartmentsAsync,
	deleteSubDepartmentAsync,
	sharedSwithPortalNav,
	openLoadingDialog,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	SubDepartmentList,
	SubDepartmentToolbar,
	SubDepartmentPanelDetail,
	SubDepartmentPanelEdit,
	SubDepartmentPanelNew,
	SubDepartmentPagination,
} from '../../components';
import './index.scss';

class SubDepartments extends Component<any, any> {
	private _pagination: any = null;
	private _searchParams: any = {};

	state = {
		isDetailOpened: false,
		isPanelNewOpened: false,
		isPanelEditOpened: false,
		selectedRow: {},
		searchText: '',
		page: 1,
	};

	componentDidMount() {
		this.getData();
	}

	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ page: 1 });
		this.setState({ searchText, page: 1 });
		this.getData();
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props.getSubDepartmentsAsync(this._searchParams).then(() => {
			this.props.openLoadingDialog({ loadingOpen: false });
		});
	};

	/**
	 * Triggered when option menu is clicked
	 * @param menuKey The key of the option menu item
	 * @param dataItem The data item of select row
	 */
	optionMenuClick = (menuKey, dataItem: any) => {
		console.log('-------------optionMenuClick', menuKey, dataItem);
		switch (menuKey) {
			case 'details':
				this.setState({ isDetailOpened: true, selectedRow: { ...dataItem } });
				break;
			case 'edit':
				this.setState({
					isPanelEditOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'delete':
				this.props.deleteSubDepartmentAsync(dataItem);
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		console.log('-------------panel detail dismiss');
		this.setState({ isDetailOpened: isOpen });
	};

	closePanelNew = (isOpen) => {
		console.log('-------------panel new dismiss');
		this.setState({ isPanelNewOpened: isOpen });
	};

	closePanelEdit = (isOpen) => {
		console.log('-------------panel edit dismiss');
		this.setState({ isPanelEditOpened: isOpen });
	};

	switchPortalNavClick = () => {
		console.log('-------------switchPortalNavClick');
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isPanelNewOpened: true });
	};

	render() {
		const { subDepartments } = this.props;
		console.log("subDepartments", this.props);
		return (
			<>
				<SubDepartmentToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
				/>

				<SubDepartmentList
					items={subDepartments.data ?? subDepartments}
					onOptionMenuClick={this.optionMenuClick}
				/>

				{this.state.isDetailOpened ? (
					<SubDepartmentPanelDetail
						isOpen={this.state.isDetailOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closeDetailPanel}
					/>
				) : null}

				{this.state.isPanelNewOpened ? (
					<SubDepartmentPanelNew
						isOpen={this.state.isPanelNewOpened}
						onDismiss={this.closePanelNew}
					/>
				) : null}

				{this.state.isPanelEditOpened ? (
					<SubDepartmentPanelEdit
						isOpen={this.state.isPanelEditOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closePanelEdit}
					/>
				) : null}

				<SubDepartmentPagination
					ref={(v) => {
						{
							this._pagination = v;
						}
					}}
					onPageClick={this.pageClick}
					pageCount={this.props.subDepartments.total ?? 10}
				/>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		subDepartments: state.subDepartments,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{
		sharedSwithPortalNav,
		getSubDepartmentsAsync,
		deleteSubDepartmentAsync,
		openLoadingDialog,
	}
)(SubDepartments);

import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export const modifyTypeBuOptions: IDropdownOption[] = [
	{ key: 'phasingChange', text: 'Phasing Change' },
	{ key: 'bucketChange', text: 'Bucket Change' },
	{
		key: 'bucketChangespeakerfee',
		text: 'Bucket Change Speaker Fee',
	},
];

export const modifyTypeOptions: IDropdownOption[] = [
	{ key: 'phasingChange', text: 'Phasing Change' },
	{ key: 'bucketChange', text: 'Bucket Change' },
	{
		key: 'bucketChangeCrossOwners',
		text: 'Bucket Change Cross Owners',
	},
	{ key: 'pyAdjustment', text: 'PY Adjustment' },
	{
		key: 'financialAdjustment',
		text: 'Financial adjustment',
	},
	{
		key: 'bucketChangespeakerfee',
		text: 'Bucket Change Speaker Fee',
	},
];

export const roleOptions: IDropdownOption[] = [
	{ key: 'Admin', text: 'Admin' },
	{ key: 'BU Coordinator', text: 'BU Coordinator' },
	{ key: 'BU Head', text: 'BU Head' },
	{ key: 'Budget Owner', text: 'Budget Owner' },
	{ key: 'Finance', text: 'Finance' },
	{ key: 'FD', text: 'FD' },
	{ key: 'GM', text: 'GM' },
	{ key: 'PIC', text: 'PIC' },
];

export const kolStature: IDropdownOption[] = [
	{ key: '国际KOL', text: '国际KOL' },
	{ key: '全国KOL', text: '全国KOL' },
	{ key: '本地KOL', text: '本地KOL' },
	{ key: '非KOL', text: '非KOL' },
];

export const activityType: IDropdownOption[] = [
	{ key: '演讲', text: '演讲' },
	{ key: '点评/评委', text: '点评/评委' },
	{ key: '主持', text: '主持' },
	{ key: '主席', text: '主席' },
	{ key: '模拟实验', text: '模拟实验' },
	{
		key: '手术演示（包括手术转播）、术式培训',
		text: '手术演示（包括手术转播）、术式培训',
	},
	{ key: '咨询委员会', text: '咨询委员会' },
	{ key: '一般咨询', text: '一般咨询' },
	{ key: '产品评估/检测', text: '产品评估/检测' },
	{ key: '口头翻译', text: '口头翻译' },
	{ key: '学术/培训材料编写', text: '学术/培训材料编写' },
	{ key: '资料翻译及临床文张撰写', text: '资料翻译及临床文张撰写' },
];

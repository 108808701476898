import {
	ADD_DICTPRODUCT,
	UPDATE_DICTPRODUCT,
	DELETE_DICTPRODUCT,
	GET_DICTPRODUCT_LIST,
} from '../constant';
import {
	getDictProductsApi,
	addDictProductApi,
	updateDictProductApi,
	deleteDictProductApi,
} from '../../api';

export const getDictProducts = (data) => ({
	type: GET_DICTPRODUCT_LIST,
	data,
});

export const addDictProduct = (data) => ({
	type: ADD_DICTPRODUCT,
	data,
});

export const updateDictProduct = (data) => ({
	type: UPDATE_DICTPRODUCT,
	data,
});

export const deleteDictProduct = (data) => ({
	type: DELETE_DICTPRODUCT,
	data,
});

export const getDictProductsAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getDictProductsApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getDictProducts({
							data: (data.value as any)?.data ?? [],
							total: totalpage,
						})
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

export const addDictProductAsync = (data) => async (dispatch) => {
	try {
		const response = await addDictProductApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(addDictProduct(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateDictProductAsync = (data) => async (dispatch) => {
	try {
		const response = await updateDictProductApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(updateDictProduct(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteDictProductAsync = (data) => async (dispatch) => {
	try {
		const response = await deleteDictProductApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(deleteDictProduct(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

import React, { Component } from 'react';
import { Box, Button, Input, Text, Toolbar } from '@fluentui/react-northstar';
import { AddIcon, MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';

export default class SpeakerFeeSubToolbar extends Component<any, any> {
	switchPortalNavClick = (event) => {
		this.props.onSwitchPortalNavClick();
	};

	newRecordBtnClick = (event) => {
		this.props.onNewRecordBtnClick();
	};

	render() {
		return (
			<>
				<Box
					styles={{
						display: 'flex',
						height: '3rem',
						justifyContent: 'end',
						boxShadow: '0px 5px 5px rgba(0, 0, 0, .2)',
					}}
				>
					<Toolbar
						items={[
							{
								key: 'custom-clickable-btn',
								content: <Button icon={<AddIcon />} text content='New' />,
								kind: 'custom',
								onClick: this.newRecordBtnClick,
							},
							{
								key: 'custom-focusable-input',
								content: (
									// <Input
									// 	icon={<SearchIcon />}
									// 	placeholder='Search...'
									// 	input={{ styles: { backgroundColor: '#FFF' } }}
									// />
									<div
										style={{ width: '20px' }}
									/>
								),
								// focusable: true,
								kind: 'custom',
								// role: 'button',
							},
						]}
					/>
				</Box>
			</>
		);
	}
}

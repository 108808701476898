import { IAction, IBudget } from '../../schemas';
import {
	GET_BUDGET_LIST,
	ADD_BUDGET,
	UPDATE_BUDGET,
	DELETE_BUDGET,
	UPDATE_SP_BUDGET

} from '../constant';

const initState: any = {};

export default function budgetsReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case GET_BUDGET_LIST:
			return { ...data };
		case ADD_BUDGET:
			const newdata = [...preState.data, data];
			return { data: newdata, total: preState.total };
		//return { ...data };
		case UPDATE_BUDGET:
			const index = preState.data.findIndex(
				(item) => item.id === action.data.id
			);
			const updateData = [
				...preState.data.slice(0, index),
				{
					...data,
				},
				...preState.data.slice(index + 1),
			];
			return { data: updateData, total: preState.total };
		case UPDATE_SP_BUDGET:
			let resultData = preState.data;
			if (data && data.length > 0) {
				resultData = (preState.data as Array<any>).reduce((r, v) => {
					const index = data.findIndex(k => { return k.id === v.id })
					if (index > -1) {
						v = { ...data[index] }
					}
					r.push(v);
					return r;
				}, [])

			}

			return { data: resultData, total: preState.total };
		case DELETE_BUDGET:
			console.log('::::::::::DELETE_BUDGET ', action.data);
			return {
				data: preState.data.filter((d) => d.id !== action.data.id),
				total: preState.total,
			};
		default:
			return preState;
	}
}

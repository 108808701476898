import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getBudgetsAsync,
	deleteBudgetAsync,
	submitBudgetAsync,
	sharedSwithPortalNav,
	openLoadingDialog,
	getTeamMappingsAsync,
} from '../../redux/actions';
import { IStore } from '../../schemas';
import {
	BudgetPanelDetail,
	BudgetPanelNew,
	BudgetPanelHistory,
	BudgetList,
	BudgetPagination,
	BudgetToolbar,
	BudgetPanelModify,
	BudgetPanelEdit,
	SpeakerFeePopModal,
	RequestItemsModal
} from '../../components';
import './index.scss';
import Modal from '@fluentui/react/lib/Modal';
const moment = require('moment');

class Budgets extends Component<any, any> {
	private _pagination: any = null;
	private _speakerFeeBatchId = moment(new Date()).valueOf();
	private _searchParams: any = {};

	state = {
		isDetailOpened: false,
		isPanelNewOpened: false,
		isPanelEditOpened: false,
		isPanelModifyOpened: false,
		isPanelHistoryOpened: false,
		isSpeakerFeeOpened: false,
		selectedRow: {},
		toBudget: {},
		searchText: '',
		page: 1,
	};

	componentDidMount() {
		this.getData();
	}

	searchClick = (data) => {
		const { searchText } = data;
		this._searchParams = { ...data, pageNumber: 1 };
		this._pagination.setState({ page: 1 });
		this.setState({ searchText, page: 1 });
		this.getData();
	};

	pageClick = (page) => {
		this.setState({ page: page });
		this._searchParams = { ...this._searchParams, pageNumber: page };
		this.getData();
	};

	getData = () => {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props.getBudgetsAsync(this._searchParams).then(() => {
			this.props.openLoadingDialog({ loadingOpen: false });
		});

		this.props.getTeamMappingsAsync().then((v) => {
			this.props.openLoadingDialog({ loadingOpen: false });
			console.log(this.props.common);
		});
	};

	/**
	 * Triggered when option menu is clicked
	 * @param menuKey The key of the option menu item
	 * @param dataItem The data item of select row
	 */
	optionMenuClick = (menuKey, dataItem: any) => {
		console.log('-------------optionMenuClick', menuKey, dataItem);
		switch (menuKey) {
			case 'details':
				this.setState({ isDetailOpened: true, selectedRow: { ...dataItem } });
				break;
			case 'edit':
				this.setState({
					isPanelEditOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'delete':
				this.props.deleteBudgetAsync(dataItem);
				break;
			case 'modify':
				this.setState({
					isPanelModifyOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'history':
				this.setState({
					isPanelHistoryOpened: true,
					selectedRow: { ...dataItem },
				});
				break;
			case 'submit':
				this.props.openLoadingDialog({ loadingOpen: true });
				this.props.submitBudgetAsync(dataItem).then(() => {
					this.props.openLoadingDialog({ loadingOpen: false });
				});
				break;
			default:
				break;
		}
	};

	closeDetailPanel = (isOpen) => {
		this.setState({ isDetailOpened: isOpen });
	};

	closePanelNew = (isOpen) => {
		this.setState({ isPanelNewOpened: isOpen });
	};

	closePanelEdit = (isOpen) => {
		this.setState({ isPanelEditOpened: isOpen });
	};

	closePanelModify = (isOpen) => {
		if (!this.state.isSpeakerFeeOpened) {
			this.setState({ isPanelModifyOpened: isOpen });
		}
	};

	closePanelHistory = (isOpen) => {
		this.setState({ isPanelHistoryOpened: isOpen });
	};

	closeSpeakerFee = () => {
		this.setState({ isSpeakerFeeOpened: false });
	};

	switchPortalNavClick = () => {
		this.props.sharedSwithPortalNav();
	};

	newRecordBtnClick = () => {
		this.setState({ isPanelNewOpened: true });
	};

	doSubAction = (actionType, data?: any) => {
		console.log('+++++++++++++++++doSubAction', actionType, data);
		switch (actionType) {
			case 'openModifyPanel':
				this.setState({
					isDetailOpened: false,
					isPanelNewOpened: false,
					isPanelEditOpened: false,
					isPanelModifyOpened: true,
					isPanelHistoryOpened: false,
				});
				break;
			case 'openEditPanel':
				this.setState({
					isDetailOpened: false,
					isPanelNewOpened: false,
					isPanelEditOpened: true,
					isPanelModifyOpened: false,
					isPanelHistoryOpened: false,
				});
				break;
			case 'openSpeakerFee':
				this.setState({
					isSpeakerFeeOpened: true,
					// toBudget: { ...data, SpeakerFeeBatchId: this._speakerFeeBatchId },
					toBudget: { ...data },
				});
				break;
			default:
				break;
		}
	};

	render() {
		console.log('::::::::::::::budget::::::::::::', this.state);
		const { budgets } = this.props;

		console.log('::::::::::::::budgets::::::::::::', this.props);
		return (
			<>
				<BudgetToolbar
					onSwitchPortalNavClick={this.switchPortalNavClick}
					onNewRecordBtnClick={this.newRecordBtnClick}
					searchClick={this.searchClick}
				/>

				<BudgetList
					items={budgets.data ?? budgets}
					onOptionMenuClick={this.optionMenuClick}
				/>

				{this.state.isDetailOpened ? (
					<BudgetPanelDetail
						isOpen={this.state.isDetailOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closeDetailPanel}
						onSubAction={this.doSubAction}
					/>
				) : null}

				{this.state.isPanelNewOpened ? (
					<BudgetPanelNew
						isOpen={this.state.isPanelNewOpened}
						onDismiss={this.closePanelNew}
					/>
				) : null}

				{this.state.isPanelEditOpened ? (
					<BudgetPanelEdit
						isOpen={this.state.isPanelEditOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closePanelEdit}
						onSubAction={this.doSubAction}
					/>
				) : null}

				{this.state.isPanelModifyOpened ? (
					<BudgetPanelModify
						isOpen={this.state.isPanelModifyOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closePanelModify}
						onSubAction={this.doSubAction}
					// speakerFeeBatchId={this._speakerFeeBatchId}
					/>
				) : null}

				{this.state.isPanelHistoryOpened ? (
					<BudgetPanelHistory
						isOpen={this.state.isPanelHistoryOpened}
						detail={this.state.selectedRow}
						onDismiss={this.closePanelHistory}
					/>
				) : null}

				<BudgetPagination
					ref={(v) => {
						{
							this._pagination = v;
						}
					}}
					onPageClick={this.pageClick}
					pageCount={this.props.budgets.total ?? 10}
				/>

				<Modal
					titleAriaId='speakerFeeModal'
					isOpen={this.state.isSpeakerFeeOpened}
					onDismiss={this.closeSpeakerFee}
					isBlocking={true}
					styles={{
						main: {
							width: '800px',
							height: '600px',
							overflow: 'hidden',
							backgroundColor: '#e8e8e8',
						},
					}}
				>
					<SpeakerFeePopModal
						budget={this.state.toBudget}
						onDismiss={this.closeSpeakerFee}
					/>
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		budgets: state.budgets,
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		common: state.common,
	}),
	{
		sharedSwithPortalNav,
		getBudgetsAsync,
		deleteBudgetAsync,
		submitBudgetAsync,
		openLoadingDialog,
		getTeamMappingsAsync,
	}
)(Budgets);

import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { MenuIcon, SearchIcon } from '@fluentui/react-icons-northstar';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import numbro from 'numbro';
const moment = require('moment');

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const options: IDropdownOption[] = [
	{ key: 'Ella', text: 'Ella' },
	{ key: 'Candy', text: 'Candy' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

export default class SpeakerFeeDetailPanel extends Component<any> {
	closeDetailPanel = () => {
		console.log('-------------panel dismiss');
		this.props.onDismiss(false);
	};

	/**
	 * @returns Panel footer component
	 */
	onRenderFooterContent = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<DefaultButton styles={buttonStyle}>Cancel</DefaultButton>
				<PrimaryButton styles={buttonStyle}>Save</PrimaryButton>
			</div>
		);
	};

	render() {
		console.log('-------detail panel', this.props);
		const { detail } = this.props;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closeDetailPanel}
					type={PanelType.medium}
					headerText='Speaker Fee Detail'
					isFooterAtBottom={true}
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Item No.'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.itemNo}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='HCC Code'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.hccCode}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Event Date'
									disabled
									borderless
									styles={textFieldSytles}
									// value={detail.eventDate}
									value={
										detail.eventDate == ''
											? ''
											: moment(detail.eventDate).format('YYYY-MM-DD')
									}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Activity Type'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.activityType}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='SAP Vendor Code'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.sapVendorCode}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='KOL Name'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.kolName}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Hospital'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.hospitalName}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='HCP Specialty'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.hcpSpecialty}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='SpecialSegment'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.specialSegment}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='KOL Stature'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.kolStature}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Range of Rate'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.rangeOfRateF}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Rate per hour Range'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.rate}
								/>
							</Stack.Item>
						</Stack>

						<Stack horizontal tokens={stackTokens}>
							<Stack.Item grow>
								<TextField
									label='Service Hours'
									disabled
									borderless
									styles={textFieldSytles}
									value={detail.serviceHour}
								/>
							</Stack.Item>

							<Stack.Item grow>
								<TextField
									label='Amount'
									disabled
									borderless
									styles={textFieldSytles}
									//	value={numbro(detail.amount).format({ thousandSeparated: true, mantissa: 2 })}
									value={detail.amount}
								/>
							</Stack.Item>
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

import React, { Component } from 'react';
import {
	Dropdown,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackStyles,
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import numbro from 'numbro';
const moment = require('moment');

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackStyles: IStackStyles = {
	root: {
		paddingTop: '1rem',
	},
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const options: IDropdownOption[] = [
	{ key: 'Ella', text: 'Ella' },
	{ key: 'Candy', text: 'Candy' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

export default class RequestDetailPanel extends Component<any> {
	closeDetailPanel = () => {
		this.props.onDismiss(false);
	};

	rednerHistory = () => {
		if (this.props.history && this.props.hisFtory.length > 0) {
			return (
				<ul>
					{this.props.history.map((v, i) => {
						return (
							<li key={"li" + i}>
								{' '}
								<Stack.Item grow>
									<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
										{` ${v.approvalName}`}
									</span>
									<span>
										{' '}
										{`${v.status} - ${moment(v.updatedTime + 'Z').format(
											'YYYY-MM-DD HH:mm:ss'
										)}`}
									</span>
								</Stack.Item>
							</li>
						);
					})}
				</ul>
			);
		}
	};

	renderAddBucketRequest = (detail) => {
		return (
			<>
				<Stack styles={stackStyles} tokens={stackTokens}>
					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Type:{' '}
						</span>
						<span>{detail.requestType}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Item No.:{' '}
						</span>
						<span>{detail.fromItemNo}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Bucket:{' '}
						</span>
						<span>{detail.fromBucketTitle}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Year:{' '}
						</span>
						<span>{detail.fromBudgetYear}</span>
					</Stack.Item>

					{/* <Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Q1:{' '}
						</span>
						<span>
							{numbro(detail.fromLiveQ1Value).format({
								thousandSeparated: true,
								mantissa: 2,
							})}
						</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Q2:{' '}
						</span>
						<span>
							{numbro(detail.fromLiveQ2Value).format({
								thousandSeparated: true,
								mantissa: 2,
							})}
						</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Q3:{' '}
						</span>
						<span>
							{numbro(detail.fromLiveQ3Value).format({
								thousandSeparated: true,
								mantissa: 2,
							})}
						</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Q4:{' '}
						</span>
						<span>
							{numbro(detail.fromLiveQ4Value).format({
								thousandSeparated: true,
								mantissa: 2,
							})}
						</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							FY:{' '}
						</span>
						<span>
							{numbro(detail.fromLiveFyValue).format({
								thousandSeparated: true,
								mantissa: 2,
							})}
						</span>
					</Stack.Item> */}

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Status:{' '}
						</span>
						<span>{detail.status}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Comments:{' '}
						</span>
						<span>{detail.adjustComments ?? ''}</span>
					</Stack.Item>
				</Stack>
			</>
		);
	};

	renderModifyRequest = (detail) => {
		return (
			<>
				<Stack styles={stackStyles} tokens={stackTokens}>
					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Type:{' '}
						</span>
						<span>{detail.requestType}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							From Item:{' '}
						</span>
						<span>{detail.fromItemNo}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							From Budget:{' '}
						</span>
						<span>{detail.fromBucketTitle}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							From Year:{' '}
						</span>
						<span>{detail.fromBudgetYear}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							From Quarter:{' '}
						</span>
						<span>{detail.fromQuater}</span>
					</Stack.Item>
				</Stack>

				<Stack styles={stackStyles} tokens={stackTokens}>
					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							To Item:{' '}
						</span>
						<span>{detail.toItemNo}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							To Budget:{' '}
						</span>
						<span>{detail.toBucketTitle}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							To Year:{' '}
						</span>
						<span>{detail.toBudgetYear}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							To Quarter:{' '}
						</span>
						<span>{detail.toQuater}</span>
					</Stack.Item>
				</Stack>

				<Stack styles={stackStyles} tokens={stackTokens}>
					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Amount:{' '}
						</span>
						<span>
							{numbro(detail.adjustValue).format({
								thousandSeparated: true,
								mantissa: 2,
							})}
						</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Status:{' '}
						</span>
						<span>{detail.status}</span>
					</Stack.Item>

					<Stack.Item grow>
						<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
							Note:{' '}
						</span>
						<span>{detail.adjustComments ?? ''}</span>
					</Stack.Item>
				</Stack>
			</>
		);
	};

	render() {
		const { detail } = this.props;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closeDetailPanel}
					type={PanelType.medium}
					headerText='Request Details'
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						{detail.requestType === 'Add Bucket'
							? this.renderAddBucketRequest(detail)
							: this.renderModifyRequest(detail)}

						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow className='sub-header'>
								<Text variant={'large'}>Process History</Text>
							</Stack.Item>

							{this.rednerHistory()}
						</Stack>
					</Stack>
				</Panel>
			</>
		);
	}
}

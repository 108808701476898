import { get, post, put, del } from './comm-http';
import { IDictItem, IResponse } from '../schemas';

/**
 * Get the dictTeam list
 * @param params The params used to query dictTeam
 * @returns DictTeam list
 */
async function getDictTeamsApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/team', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getDictTeaMappingsApi(params): Promise<IResponse<IDictItem[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem[]>>('/dict/teamdetail', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}


async function getDictTeamByIdApi(id: string): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictItem>>(`/dict/team/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add a new dictTeam
 * @param data DictTeam data
 * @returns DictTeam data from backend service after the dictTeam is created
 */
async function addDictTeamApi(data: IDictItem): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await post<IDictItem, IResponse<IDictItem>>('/dict/team', data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a dictTeam
 * @param data DictTeam data
 * @returns DictTeam data from backend service after the dictTeam is updated
 */
async function updateDictTeamApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<IDictItem, IResponse<IDictItem>>(
		`/dict/team/${data.id}`,
		data,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

/**
 * Delete a dictTeam
 * @param data DictTeam data
 * @returns DictTeam data from backend service after the dictTeam is updated
 */
async function deleteDictTeamApi(
	data: IDictItem
): Promise<IResponse<IDictItem>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await del<IResponse<IDictItem>>(`/dict/team/${data.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export {
	getDictTeamsApi,
	getDictTeamByIdApi,
	addDictTeamApi,
	updateDictTeamApi,
	deleteDictTeamApi,
	getDictTeaMappingsApi
};

import React, { Component } from 'react';
import { Button, Text } from '@fluentui/react-northstar';
import { FilesUploadIcon, MenuIcon } from '@fluentui/react-icons-northstar';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react/lib/Button';
import { connect } from 'react-redux';
import { IStore } from '../../schemas';

class SpeakerToolbar extends Component<any, any> {
    state = {
        searchText: '',
    };

    switchPortalNavClick = (event) => {
        this.props.onSwitchPortalNavClick();
    };
    uploadBtnClick = (event) => {
        this.props.onUploadBtnClick();
    };
    newRecordBtnClick = (event) => {
        this.props.onNewRecordBtnClick();
    };

    searchClick = (e) => {
        if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
            if (this.props.searchClick) {
                const searchParams = {
                    ...this.state,
                };
                this.props.searchClick(searchParams);
            }
        }
    };
    render() {
        console.log(this.props.currentUser?.userEmail);
        const userEmail = this.props.currentUser?.userEmail;
        return (
            <>
                <div
                    style={{
                        width: '100%',
                        height: '45px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: '0px 5px 5px rgba(0, 0, 0, .2)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <MenuIcon
                            {...{
                                outline: true,
                            }}
                            styles={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                paddingLeft: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={this.switchPortalNavClick}
                        />

                        <Text
                            content='Speaker Data'
                            styles={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                paddingLeft: '25px',
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {(userEmail == 'hcao26@ITS.JNJ.com') ?
                            <Button
                                icon={<FilesUploadIcon />}
                                text
                                content='Upload File'
                                onClick={this.uploadBtnClick}

                            /> : null}
                        <TextField
                            borderless
                            placeholder='Search...'
                            //'Search...'
                            styles={{
                                root: { marginLeft: '10px', borderBottom: 'solid 2px #6264a7' },
                            }}
                            onChange={(e, v) => {
                                this.setState({ searchText: v });
                            }}
                            value={this.state.searchText}
                            onKeyDown={this.searchClick}
                        />

                        <IconButton
                            iconProps={{ iconName: 'Search' }}
                            styles={{ icon: { color: '#6264a7' } }}
                            onClick={this.searchClick}
                        />
                    </div>
                </div>
            </>
        );
    }
}
export default connect(
    (state: IStore) => ({
        currentUser: state.currentUser,
    }),
    {}
)(SpeakerToolbar);

import React, { Component } from 'react';
import {
	DetailsList,
	DetailsListLayoutMode,
	Selection,
	SelectionMode,
	IColumn,
} from '@fluentui/react/lib/DetailsList';
import { IconButton } from '@fluentui/react/lib/Button';
import numbro from 'numbro';

class MyContextMenu extends React.Component<any, any> {
	acttionbtnStyle = {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	};

	_menuItemClick = (_, item) => {
		this.props.onMenuItemClick(item?.key);
	};

	_getMenuProps = () => {
		return {
			shouldFocusOnMount: true,
			shouldFocusOnContainer: true,
			items: [
				{
					key: 'details',
					text: 'Details',
					iconProps: { iconName: 'Search' },
					onClick: this._menuItemClick,
				},
			],
		};
	};

	render() {
		const menuProps = this._getMenuProps();
		return (
			<IconButton
				persistMenu={false}
				menuProps={menuProps}
				style={this.acttionbtnStyle}
			/>
		);
	}
}

export interface IDetailsListDocumentsExampleState {
	columns: IColumn[];
	selectionDetails: string;
	isModalSelection: boolean;
	isCompactMode: boolean;
	announcedMessage?: string;
}

export interface IDocument {
	key: string;
	itemNo: string;
	bucket: string;
	year: number;
	q1Value: number;
	q2Value: number;
	q3Value: number;
	q4Value: number;
	fyValue: number;
	ytdBalance: number;
	status: string;
}

export default class RequestList extends Component<
	any,
	IDetailsListDocumentsExampleState
> {
	private _selection: Selection;

	constructor(props: {}) {
		super(props);

		const columns: IColumn[] = [
			{
				key: 'optionMenu',
				name: 'Option',
				minWidth: 50,
				maxWidth: 50,
				fieldName: 'optionMenu',

				isRowHeader: true,
				onRender: (item: IDocument) => (
					<MyContextMenu item={item} onMenuItemClick={this._onMenuItemClick} />
				),
			},
			{
				key: 'itemNo',
				name: 'Item No.',
				fieldName: 'fromItemNo',
				minWidth: 80,
				isRowHeader: true,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'bucket',
				name: 'Bucket',
				fieldName: 'fromBucketTitle',
				minWidth: 100,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				data: 'string',
			},
			{
				key: 'year',
				name: 'Year',
				fieldName: 'fromBudgetYear',
				minWidth: 30,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'q1Value',
				name: 'Q1',
				fieldName: 'fromLiveQ1Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'q2Value',
				name: 'Q2',
				fieldName: 'fromLiveQ2Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'q3Value',
				name: 'Q3',
				fieldName: 'fromLiveQ3Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'q4Value',
				name: 'Q4',
				fieldName: 'fromLiveQ4Value',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			{
				key: 'fyValue',
				name: 'FY',
				fieldName: 'fromLiveFyValue',
				minWidth: 80,
				isResizable: true,
				data: 'number',
				onColumnClick: this._onColumnClick,
				headerClassName: 'rightHead',
				className: 'rightCol',
			},
			// {
			// 	key: 'ytdBalance',
			// 	name: 'YTD Balance to go',
			// 	fieldName: 'fromBalanceAmtYtd',
			// 	minWidth: 120,
			// 	isResizable: true,
			// 	data: 'number',
			// 	onColumnClick: this._onColumnClick,
			// },
			{
				key: 'status',
				name: 'Status',
				fieldName: 'status',
				minWidth: 60,
				isResizable: true,
				data: 'string',
				onColumnClick: this._onColumnClick,
			},
		];

		this._selection = new Selection({
			onSelectionChanged: () => {
				this.setState({
					selectionDetails: this._getSelectionDetails(),
				});
			},
		});

		this.state = {
			columns: columns,
			selectionDetails: this._getSelectionDetails(),
			isModalSelection: false,
			isCompactMode: false,
			announcedMessage: undefined,
		};
	}
	_renderItemColumn = (item: any, index: number, column: any) => {
		const fieldContent = item[column.fieldName];
		//return <span>1111</span>
		switch (column.key) {
			case 'q1Value':
			case 'q2Value':
			case 'q3Value':
			case 'q4Value':
			case 'fyValue':
				return (
					<span>
						{numbro(+fieldContent).format({
							thousandSeparated: true,
							mantissa: 2,
						})}
					</span>
				);

			default:
				return <span>{fieldContent}</span>;
		}
	};

	render() {
		const { columns, isCompactMode } = this.state;
		const { items } = this.props;

		return (
			<div
				style={{
					height: 'calc(100vh - 7rem)',
					width: '100%',
					overflow: 'auto',
					padding: '10px',
				}}
			>
				<DetailsList
					items={items}
					compact={isCompactMode}
					columns={columns}
					selectionMode={SelectionMode.single}
					getKey={this._getKey}
					setKey='none'
					selection={this._selection}
					layoutMode={DetailsListLayoutMode.fixedColumns}
					isHeaderVisible={true}
					// onItemInvoked={this._onItemInvoked}
					onRenderItemColumn={(item: any, index: any, column: any) =>
						this._renderItemColumn(item, index, column)
					}
				/>
			</div>
		);
	}

	componentDidUpdate(
		previousProps: any,
		previousState: IDetailsListDocumentsExampleState
	) {
		if (
			previousState.isModalSelection !== this.state.isModalSelection &&
			!this.state.isModalSelection
		) {
			this._selection.setAllSelected(false);
		}
	}

	private _getKey(item: any, index?: number): string {
		return item.key;
	}

	private _onItemInvoked(item: any): void {
		alert(`Item invoked: ${item.name}`);
	}

	private _getSelectionDetails(): any {
		const selectionCount = this._selection.getSelectedCount();

		switch (selectionCount) {
			case 0:
				return null;
			case 1:
				return this._selection.getSelection()[0];
			default:
				return null;
		}
	}

	private _onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		const { columns } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				this.setState({
					announcedMessage: `${currColumn.name} is sorted ${
						currColumn.isSortedDescending ? 'descending' : 'ascending'
					}`,
				});
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		this.setState({
			columns: newColumns,
		});
	};

	itemOptionsClick = (data) => {
		return (e) => {
			console.log('----------------button', e, data);
		};
	};

	_onMenuItemClick = (menuKey) => {
		console.log(':::::::::::::::_onMenuItemClick', menuKey);
		const selectItem = this._getSelectionDetails();
		this.setState({
			selectionDetails: selectItem,
		});
		this.props.onOptionMenuClick(menuKey, selectItem);
	};
}

function _copyAndSort<T>(
	items: T[],
	columnKey: string,
	isSortedDescending?: boolean
): T[] {
	const key = columnKey as keyof T;
	return items
		.slice(0)
		.sort((a: T, b: T) =>
			(isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
		);
}

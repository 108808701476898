import { get, put } from './comm-http';
import { ISapData, IResponse } from '../schemas';

/**
 * Get the sapData list
 * @param params The params used to query sapData
 * @returns SapData list
 */
async function getSapDatasApi(params): Promise<IResponse<ISapData[]>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<ISapData[]>>('/sapData', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

async function getSapDataByIdApi(id: string): Promise<IResponse<ISapData>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<ISapData>>(`/sapData/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Update a sapData
 * @param data SapData data
 * @returns SapData data from backend service after the sapData is updated
 */
async function updateSapDataApi(data: ISapData): Promise<IResponse<ISapData>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await put<ISapData, IResponse<ISapData>>(`/sapData/${data.id}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export { getSapDatasApi, getSapDataByIdApi, updateSapDataApi };

import React, { Component } from 'react';
import {
	Dropdown,
	IDropdownOption,
	IDropdownStyles,
} from '@fluentui/react/lib/Dropdown';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
	IStackStyles,
	IStackItemStyles,
	IStackTokens,
	Stack,
} from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const stackStyles: IStackStyles = {
	root: {
		paddingTop: '1rem',
	},
};

const stackItemStyles: IStackItemStyles = {
	root: {
		flex: 1,
		// alignItems: 'center',
		// background: DefaultPalette.themePrimary,
		// color: DefaultPalette.white,
		// display: 'flex',
		// height: 50,
		// justifyContent: 'center',
	},
};

const verticalStackTokens: IStackTokens = {
	childrenGap: 5,
};

const buttonStyle = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};

export default class BudgetPanelHistory extends Component<any> {
	closeDetailPanel = () => {
		this.props.onDismiss(false);
	};

	render() {
		const { detail } = this.props;
		return (
			<>
				<Panel
					isOpen={this.props.isOpen}
					onDismiss={this.closeDetailPanel}
					type={PanelType.medium}
					headerText='Budget History'
					styles={{
						navigation: { backgroundColor: '#f5f5f5' },
						main: {
							backgroundColor: '#f5f5f5',
						},
						commands: { zIndex: '9999' },
					}}
				>
					<Stack tokens={verticalStackTokens}>
						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Type:{' '}
								</span>
								<span>Bucket Change</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Item:{' '}
								</span>
								<span>005</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Budget:{' '}
								</span>
								<span>005 RTB+Agency Commission Fee & Service Fee</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Year:{' '}
								</span>
								<span>2021</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Quarter:{' '}
								</span>
								<span>Q3</span>
							</Stack.Item>
						</Stack>

						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Item:{' '}
								</span>
								<span>006</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Budget:{' '}
								</span>
								<span>006 Business Intelligence Fee</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Year:{' '}
								</span>
								<span>2021</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Quarter:{' '}
								</span>
								<span>Q3</span>
							</Stack.Item>
						</Stack>

						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Amount:{' '}
								</span>
								<span>20</span>
							</Stack.Item>

							<ul>
								<li>
									{' '}
									<Stack.Item grow>
										<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											Approved By:{' '}
										</span>
										<span>XXX - 2021/11/20</span>
									</Stack.Item>
								</li>

								<li>
									{' '}
									<Stack.Item grow>
										<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											Approved By:{' '}
										</span>
										<span>XXX - 2021/11/18</span>
									</Stack.Item>
								</li>

								<li>
									{' '}
									<Stack.Item grow>
										<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											Requested By:{' '}
										</span>
										<span>XXX - 2021/11/15</span>
									</Stack.Item>
								</li>
							</ul>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'large'}></Text>
						</Stack.Item>

						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Type:{' '}
								</span>
								<span>Bucket Change</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Item:{' '}
								</span>
								<span>005</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Budget:{' '}
								</span>
								<span>005 RTB+Agency Commission Fee & Service Fee</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Year:{' '}
								</span>
								<span>2021</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									From Quarter:{' '}
								</span>
								<span>Q3</span>
							</Stack.Item>
						</Stack>

						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Item:{' '}
								</span>
								<span>006</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Budget:{' '}
								</span>
								<span>006 Business Intelligence Fee</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Year:{' '}
								</span>
								<span>2021</span>
							</Stack.Item>

							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									To Quarter:{' '}
								</span>
								<span>Q3</span>
							</Stack.Item>
						</Stack>

						<Stack styles={stackStyles} tokens={stackTokens}>
							<Stack.Item grow>
								<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
									Amount:{' '}
								</span>
								<span>20</span>
							</Stack.Item>

							<ul>
								<li>
									{' '}
									<Stack.Item grow>
										<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											Approved By:{' '}
										</span>
										<span>XXX - 2021/11/20</span>
									</Stack.Item>
								</li>

								<li>
									{' '}
									<Stack.Item grow>
										<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											Approved By:{' '}
										</span>
										<span>XXX - 2021/11/18</span>
									</Stack.Item>
								</li>

								<li>
									{' '}
									<Stack.Item grow>
										<span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
											Requested By:{' '}
										</span>
										<span>XXX - 2021/11/15</span>
									</Stack.Item>
								</li>
							</ul>
						</Stack>

						<Stack.Item grow className='sub-header'>
							<Text variant={'large'}></Text>
						</Stack.Item>
					</Stack>
				</Panel>
			</>
		);
	}
}

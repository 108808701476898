import React, { Component } from 'react';
import {
	Nav,
	INavStyles,
	INavLinkGroup,
	INavLink,
} from '@fluentui/react/lib/Nav';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IStore } from '../../schemas';
import {
	BmeApprovals,
	BmeBudgets,
	BmeDashboard,
	BmeDataMaintance,
	BmeDictionaries,
	BmeImportBuckets,
	BmePersonalSettings,
	BmeRequests,
	BmeSpeakerFees,
	BmeSpeaker,
	BmeUsers,
	SubDepartments,
} from '../../containers';

import './index.scss';
import {
	getUserDictionaryAsync,
	getCurrentUserAsync,
	openLoadingDialog,
	getTeamMappingsAsync,
	getDictionaryAsync,
} from '../../redux/actions';
import { Label } from '@fluentui/react/lib/Label';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Dialog, { DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { PrimaryButton } from '@fluentui/react';

const navStyles: Partial<INavStyles> = {
	root: {
		width: '100%',
		height: '100%',
		overflowY: 'auto',
	},
};

const navLinkGroups: INavLinkGroup[] = [
	{
		links: [
			{
				name: 'Application Management',
				expandAriaLabel: 'Application Management',
				collapseAriaLabel: 'Application Management',
				url: '',
				links: [
					{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
					{ name: 'My Budgets', url: '', key: '/portal/budget' },
					{ name: 'My Requests', url: '', key: '/portal/request' },
					{ name: 'My Approvals', url: '', key: '/portal/approval' },
					{ name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
				],
				isExpanded: true,
			},
			{
				name: 'System Management',
				expandAriaLabel: 'System Management',
				collapseAriaLabel: 'System Management',
				url: '',
				links: [
					// { name: 'Personal Setting', url: '', key: '/portal/personalsetting' },
					{ name: 'Data Maintenance', url: '', key: '/portal/datamaintenance' },
					{ name: 'Import Buckets', url: '', key: '/portal/importbuckets' },
					{ name: 'User Setting', url: '', key: '/portal/user' },
				],
				isExpanded: true,
			},
		],
	},
];

initializeIcons();

class PortalTab extends Component<any, any> {
	timer;

	public state: any = {
		navLinkGroups: [
			{
				links: [
					{
						name: 'Application Management',
						expandAriaLabel: 'Application Management',
						collapseAriaLabel: 'Application Management',
						url: '',
						links: [
							{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
							{ name: 'My Budgets', url: '', key: '/portal/budget' },
							// { name: 'My Requests', url: '', key: '/portal/request' },
							// { name: 'My Approval', url: '', key: '/portal/approval' },
							// { name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
						],
						isExpanded: true,
					},
					// {
					// 	name: 'System Management',
					// 	expandAriaLabel: 'System Management',
					// 	collapseAriaLabel: 'System Management',
					// 	url: '',
					// 	links: [
					// 		// { name: 'Personal Setting', url: '', key: '/portal/personalsetting' },
					// 		{ name: 'Data Maintenance', url: '', key: '/portal/datamaintenance' },
					// 		{ name: 'Import Buckets', url: '', key: '/portal/importbuckets' },
					// 		{ name: 'User Setting', url: '', key: '/portal/user' },
					// 	],
					// 	isExpanded: true,
					// },
				],
			},
		],
		loginerror: false,
		relogin: false,
	};

	navLinkClick = (
		event?: React.MouseEvent<HTMLElement>,
		linkItem?: INavLink
	) => {
		if (event) {
			event.nativeEvent.preventDefault();
		}

		if (linkItem && linkItem.key) {
			this.props.history.push(linkItem.key);
		}
	};

	componentDidUpdate(previousProps: any, previousState: any) {
		if (
			previousProps.currentUser !== this.props.currentUser ||
			previousProps.currentUser.role !== this.props.currentUser.role
		) {
			const role = this.props.currentUser.role;
			let navLinkGroups = [
				{
					links: [
						{
							name: 'Application Management',
							expandAriaLabel: 'Application Management',
							collapseAriaLabel: 'Application Management',
							url: '',
							links: [
								{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
								{ name: 'My Budgets', url: '', key: '/portal/budget' },
								// { name: 'My Requests', url: '', key: '/portal/request' },
								// { name: 'My Approval', url: '', key: '/portal/approval' },
								// { name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
							],
							isExpanded: true,
						},
						{
							name: 'System Management',
							expandAriaLabel: 'System Management',
							collapseAriaLabel: 'System Management',
							url: '',
							links: [
								// { name: 'Personal Setting', url: '', key: '/portal/personalsetting' },
								{
									name: 'Speaker Data',
									url: '', key: '/portal/speaker'
								}
								//	{ name: 'Import Buckets', url: '', key: '/portal/importbuckets' },
								//	{ name: 'User Setting', url: '', key: '/portal/user' },
							],
							isExpanded: true,
						},
						// {
						// 	name: 'System Management',
						// 	expandAriaLabel: 'System Management',
						// 	collapseAriaLabel: 'System Management',
						// 	url: '',
						// 	links: [
						// 		// { name: 'Personal Setting', url: '', key: '/portal/personalsetting' },
						// 		{ name: 'Data Maintenance', url: '', key: '/portal/datamaintenance' },
						// 		{ name: 'Import Buckets', url: '', key: '/portal/importbuckets' },
						// 		{ name: 'User Setting', url: '', key: '/portal/user' },
						// 	],
						// 	isExpanded: true,
						// },
					],
				},
			];
			switch (role) {
				case 'BU Coordinator':
					navLinkGroups = [
						{
							links: [
								{
									name: 'Application Management',
									expandAriaLabel: 'Application Management',
									collapseAriaLabel: 'Application Management',
									url: '',
									links: [
										{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
										{ name: 'My Budgets', url: '', key: '/portal/budget' },
										{ name: 'My Requests', url: '', key: '/portal/request' },
										// { name: 'My Approval', url: '', key: '/portal/approval' },
										{ name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
									],
									isExpanded: true,
								},
								{
									name: 'System Management',
									expandAriaLabel: 'System Management',
									collapseAriaLabel: 'System Management',
									url: '',
									links: [
										{
											name: 'Speaker Data',
											url: '', key: '/portal/speaker'
										}
									],
									isExpanded: true,
								},
							],
						},
					];
					break;
				case 'Budget Owner':
					navLinkGroups = [
						{
							links: [
								{
									name: 'Application Management',
									expandAriaLabel: 'Application Management',
									collapseAriaLabel: 'Application Management',
									url: '',
									links: [
										{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
										{ name: 'My Budgets', url: '', key: '/portal/budget' },
										// { name: 'My Requests', url: '', key: '/portal/request' },
										//{ name: 'My Approval', url: '', key: '/portal/approval' },
										// { name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
									],
									isExpanded: true,
								},
								{
									name: 'System Management',
									expandAriaLabel: 'System Management',
									collapseAriaLabel: 'System Management',
									url: '',
									links: [
										{
											name: 'Speaker Data',
											url: '', key: '/portal/speaker'
										}
									],
									isExpanded: true,
								},
							],
						},
					];
					break;
				case 'Finance':
					navLinkGroups = [
						{
							links: [
								{
									name: 'Application Management',
									expandAriaLabel: 'Application Management',
									collapseAriaLabel: 'Application Management',
									url: '',
									links: [
										{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
										{ name: 'My Budgets', url: '', key: '/portal/budget' },
										{ name: 'My Requests', url: '', key: '/portal/request' },
										{ name: 'My Approvals', url: '', key: '/portal/approval' },
										{ name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
									],
									isExpanded: true,
								},
								{
									name: 'System Management',
									expandAriaLabel: 'System Management',
									collapseAriaLabel: 'System Management',
									url: '',
									links: [
										// { name: 'Personal Setting', url: '', key: '/portal/personalsetting' },
										{
											name: 'Speaker Data',
											url: '', key: '/portal/speaker'
										},
										{
											name: 'Data Maintenance',
											url: '',
											key: '/portal/datamaintenance',
										},
										{
											name: 'Import Buckets',
											url: '',
											key: '/portal/importbuckets',
										},
									],
									isExpanded: true,
								},
								{
									name: 'System Setting',
									expandAriaLabel: 'System Setting',
									collapseAriaLabel: 'System Setting',
									url: '',
									links: [
										{ name: 'User Setting', url: '', key: '/portal/user' },
										{ name: 'SubDepartment Setting', url: '', key: '/portal/subDepartment' },
										{
											name: 'Team Setting',
											url: '',
											key: '/portal/dict/team',
										},
										{
											name: 'Product Category',
											url: '',
											key: '/portal/dict/product',
										},
										{
											name: 'Expense Category',
											url: '',
											key: '/portal/dict/expense',
										},
										{
											name: 'SubExpense Category',
											url: '',
											key: '/portal/dict/subexpense',
										},
										{
											name: 'Budget Type',
											url: '',
											key: '/portal/dict/budgettype',
										},
										{
											name: 'Platform/Customer',
											url: '',
											key: '/portal/dict/platform',
										},
									],
									isExpanded: true,
								},
							],
						},
					];
					break;
				case 'FD':
				case 'GM':
				case 'BU Head':
					navLinkGroups = [
						{
							links: [
								{
									name: 'Application Management',
									expandAriaLabel: 'Application Management',
									collapseAriaLabel: 'Application Management',
									url: '',
									links: [
										{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
										{ name: 'My Budgets', url: '', key: '/portal/budget' },
										{ name: 'My Approvals', url: '', key: '/portal/approval' },
									],
									isExpanded: true,
								},
								{
									name: 'System Management',
									expandAriaLabel: 'System Management',
									collapseAriaLabel: 'System Management',
									url: '',
									links: [
										{
											name: 'Speaker Data',
											url: '', key: '/portal/speaker'
										},
									],
									isExpanded: true,
								},
							],
						},
					];
					break;
				case 'PIC':
					navLinkGroups = [
						{
							links: [
								{
									name: 'Application Management',
									expandAriaLabel: 'Application Management',
									collapseAriaLabel: 'Application Management',
									url: '',
									links: [
										{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
										{ name: 'My Budgets', url: '', key: '/portal/budget' },
									],
									isExpanded: true,
								},
								{
									name: 'System Management',
									expandAriaLabel: 'System Management',
									collapseAriaLabel: 'System Management',
									url: '',
									links: [
										{
											name: 'Speaker Data',
											url: '', key: '/portal/speaker'
										},
									],
									isExpanded: true,
								},
							],
						},
					];
					break;
				case 'Admin':
					navLinkGroups = [
						{
							links: [
								{
									name: 'Application Management',
									expandAriaLabel: 'Application Management',
									collapseAriaLabel: 'Application Management',
									url: '',
									links: [
										{ name: 'My Dashboard', url: '', key: '/portal/dashbord' },
										{ name: 'My Budgets', url: '', key: '/portal/budget' },
										{ name: 'My Requests', url: '', key: '/portal/request' },
										{ name: 'My Approvals', url: '', key: '/portal/approval' },
										{ name: 'Speaker Fee', url: '', key: '/portal/speakerfee' },
									],
									isExpanded: true,
								},
								{
									name: 'System Management',
									expandAriaLabel: 'System Management',
									collapseAriaLabel: 'System Management',
									url: '',
									links: [
										// { name: 'Personal Setting', url: '', key: '/portal/personalsetting' },
										{
											name: 'Speaker Data',
											url: '', key: '/portal/speaker'
										},
										{
											name: 'Data Maintenance',
											url: '',
											key: '/portal/datamaintenance',
										},
										{
											name: 'Import Buckets',
											url: '',
											key: '/portal/importbuckets',
										},
									],
									isExpanded: true,
								},
								{
									name: 'System Setting',
									expandAriaLabel: 'System Setting',
									collapseAriaLabel: 'System Setting',
									url: '',
									links: [
										{ name: 'User Setting', url: '', key: '/portal/user' },
										{ name: 'SubDepartment Setting', url: '', key: '/portal/subDepartment' },
										{
											name: 'Team Setting',
											url: '',
											key: '/portal/dict/team',
										},
										{
											name: 'Product Category',
											url: '',
											key: '/portal/dict/product',
										},
										{
											name: 'Expense Category',
											url: '',
											key: '/portal/dict/expense',
										},
										{
											name: 'SubExpense Category',
											url: '',
											key: '/portal/dict/subexpense',
										},
										{
											name: 'Budget Type',
											url: '',
											key: '/portal/dict/budgettype',
										},
										{
											name: 'Platform/Customer',
											url: '',
											key: '/portal/dict/platform',
										},
									],
									isExpanded: true,
								},
							],
						},
					];
					break;
			}

			this.setState({ navLinkGroups: navLinkGroups });
		}
	}
	componentDidMount() {
		this.props.openLoadingDialog({ loadingOpen: true });
		this.props
			.getCurrentUserAsync()
			.then((result) => {
				this.props.getUserDictionaryAsync();
				this.props.getDictionaryAsync();
				this.props.getTeamMappingsAsync().then((v) => {
					this.props.openLoadingDialog({ loadingOpen: false });
					console.log(this.props.common);
				});
			})
			.catch((error) => {
				this.props.openLoadingDialog({ loadingOpen: false });
				this.setState({ loginerror: true });
			});
	}

	componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	onDismiss = () => {
		this.setState({ loginerror: false });
	};
	onOkClick = () => {
		this.setState({ relogin: true });
	};

	render() {
		const { currentUser, common } = this.props;

		return (
			<>
				<div style={{ height: '100vh', width: '100vw', display: 'flex' }}>
					<div
						style={{
							backgroundColor: '#F5F5F5',
							height: '100%',
							overflowY: 'auto',
							borderRight: 'solid 1px #E0E0E0',
							boxShadow: '1px 0px 1px rgba(0, 0, 0, .2)',
						}}
					>
						{this.props.sharedPortalNavSwitch ? (
							<Nav
								styles={navStyles}
								groups={this.state.navLinkGroups}
								//selectedKey={} // change this value by code if the actual page is change
								onLinkClick={this.navLinkClick}
								initialSelectedKey={'/portal/dashbord'}
							/>
						) : null}
					</div>

					<div
						style={{
							backgroundColor: '#F5F5F5',
							width: '100%',
							overflow: 'auto',
						}}
					>
						<Switch>
							<Redirect exact from='/portal' to='/portal/dashbord' />
							<Route path='/portal/dashbord' component={BmeDashboard} />
							<Route path='/portal/budget' component={BmeBudgets} />
							<Route path='/portal/request' component={BmeRequests} />
							<Route path='/portal/approval' component={BmeApprovals} />
							<Route path='/portal/speakerfee' component={BmeSpeakerFees} />
							<Route path='/portal/speaker' component={BmeSpeaker} />
							{/* <Route
								path='/portal/personalsetting'
								component={BmePersonalSettings}
							/> */}
							<Route
								path='/portal/datamaintenance'
								component={BmeDataMaintance}
							/>
							<Route
								path='/portal/importbuckets'
								component={BmeImportBuckets}
							/>
							<Route path='/portal/user' component={BmeUsers} />
							<Route path='/portal/subDepartment' component={SubDepartments} />
							<Route path='/portal/dict/:type' component={BmeDictionaries} />
						</Switch>
					</div>

					<div>
						{this.props.common.loadingOpen ? (
							<div
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									top: 0,
									left: 0,
									backgroundColor: 'rgba(0, 0, 0, 0.6)',
									zIndex: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Spinner
									size={SpinnerSize.large}
									label='Loading...'
									styles={{
										label: {
											color: 'white',
											fontSize: '1rem',
											fontWeight: 'bold',
										},
									}}
								/>
							</div>
						) : // <Spinner label="loading..." style={{
							// 	position: 'fixed',
							// 	height: '100%',
							// 	width: '100%',
							// 	top: '0px',
							// 	left: '0px',
							// 	opacity: 1,
							// 	backgroundColor: "rgba(0, 0, 0, 0.541176)",
							// }} />

							null}
					</div>
				</div>
				{this.state.loginerror ? (
					<Dialog
						hidden={false}
						onDismiss={this.onDismiss}
						dialogContentProps={{
							type: DialogType.normal,
							title: 'Authorization failed',
							closeButtonAriaLabel: 'Close',
							subText: 'Click the OK button to login again',
						}}
					>
						<DialogFooter>
							<PrimaryButton onClick={this.onOkClick} text='Ok' />
						</DialogFooter>
					</Dialog>
				) : null}

				{this.state.relogin ? <Redirect to='/auth' /> : null}
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		sharedPortalNavSwitch: state.sharedPortalNavSwitch,
		currentUser: state.currentUser,
		common: state.common,
	}),
	{
		getCurrentUserAsync,
		getUserDictionaryAsync,
		getDictionaryAsync,
		openLoadingDialog,
		getTeamMappingsAsync,
	}
)(PortalTab);

import React, { Component } from 'react';
import './index.scss';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Redirect } from 'react-router-dom';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { getUserValidateApi } from '../../api/user';
import { IStore } from '../../schemas';
import { openLoadingDialog } from '../../redux/actions';
import { connect } from 'react-redux';
import { getauthorizationCodeApi } from '../../api';
interface ILoginAuthEndState {
	loading: boolean;
	logined: boolean;
	loginerror: boolean;
	relogin: boolean;
}

interface ILoginAuthEndProps {
	openLoadingDialog: (data) => void;
	common?: any;
}

class LoginAuthEnd extends Component<ILoginAuthEndProps, ILoginAuthEndState> {
	private _token = '';
	public state: ILoginAuthEndState = {
		loading: false,
		logined: false,
		loginerror: false,
		relogin: false,
	};
	componentDidMount() {
		this.setState({ loading: true });
		const code = window.location.search.replace('?code=', '');
		this.props.openLoadingDialog({ loadingOpen: true });
		if (code != '') {
			getauthorizationCodeApi({ code: code }).then((result) => {
				this.props.openLoadingDialog({ loadingOpen: false });
				if (result && result.errorCode && result.errorCode.length > 0) {
					this.setState({ loginerror: true });
				} else if (result && result.value) {
					localStorage.setItem('teamsAuthToken', result.value);
					this.setState({ logined: true });
				} else {
					this.setState({ loginerror: true });
				}
			});
		}
	}
	onDismiss = () => {
		this.setState({ loginerror: false });
	};
	onOkClick = () => {
		this.setState({ relogin: true });
	};
	render() {
		let html: any = null;
		html = (
			<div className='LoginAuthEnd'>
				<img
					src='/bg.png'
					style={{ marginTop: '100px', marginBottom: '20px', height: '400px' }}
				></img>
			</div>
		);
		return (
			<>
				<div>{html}</div>

				{this.props.common.loadingOpen ? (
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							top: 0,
							left: 0,
							backgroundColor: 'rgba(0, 0, 0, 0.6)',
							zIndex: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Spinner
							size={SpinnerSize.large}
							label='Authorization...'
							styles={{
								label: {
									color: 'white',
									fontSize: '1rem',
									fontWeight: 'bold',
								},
							}}
						/>
					</div>
				) : null}

				{this.state.logined ? <Redirect to='/portal' /> : null}
				{this.state.loginerror ? (
					<Dialog
						hidden={false}
						onDismiss={this.onDismiss}
						dialogContentProps={{
							type: DialogType.normal,
							title: 'Authorization failed',
							closeButtonAriaLabel: 'Close',
							subText:
								'Authorization failed,Click the OK button to login again',
						}}
					>
						<DialogFooter>
							<PrimaryButton onClick={this.onOkClick} text='Ok' />
						</DialogFooter>
					</Dialog>
				) : null}

				{this.state.relogin ? <Redirect to='/auth' /> : null}
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({
		currentUser: state.currentUser,
		common: state.common,
	}),
	{
		openLoadingDialog,
	}
)(LoginAuthEnd);

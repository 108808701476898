import { get } from './comm-http';
import { IDictionary, IResponse } from '../schemas';

/**
 * Get the dictionaries
 * @param params The params used to query dictionaries
 * @returns Dictionaries
 */
async function getDictionaryApi(params): Promise<IResponse<IDictionary>> {
	const token = localStorage.getItem('teamsAuthToken');
	return await get<IResponse<IDictionary>>('/dict', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params,
	});
}

export { getDictionaryApi };

import {
	ADD_DICTTEAM,
	UPDATE_DICTTEAM,
	DELETE_DICTTEAM,
	GET_DICTTEAM_LIST,
} from '../constant';
import {
	getDictTeamsApi,
	addDictTeamApi,
	updateDictTeamApi,
	deleteDictTeamApi,
} from '../../api';

export const getDictTeams = (data) => ({
	type: GET_DICTTEAM_LIST,
	data,
});

export const addDictTeam = (data) => ({
	type: ADD_DICTTEAM,
	data,
});

export const updateDictTeam = (data) => ({
	type: UPDATE_DICTTEAM,
	data,
});

export const deleteDictTeam = (data) => ({
	type: DELETE_DICTTEAM,
	data,
});

export const getDictTeamsAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getDictTeamsApi(params)
			.then((data) => {
				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getDictTeams({
							data: (data.value as any)?.data ?? [],
							total: totalpage,
						})
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}
			})
			.catch((error) => {
				throw error;
			});
	};
};

export const addDictTeamAsync = (data) => async (dispatch) => {
	try {
		const response = await addDictTeamApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(addDictTeam(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateDictTeamAsync = (data) => async (dispatch) => {
	try {
		const response = await updateDictTeamApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(updateDictTeam(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteDictTeamAsync = (data) => async (dispatch) => {
	try {
		const response = await deleteDictTeamApi(data);
		if (response.errorCode.trim().length === 0) {
			dispatch(deleteDictTeam(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

import { UPDATE_PODATA, GET_PODATA_LIST } from '../constant';
import { getPoDatasApi, updatePoDataApi } from '../../api';

export const getPoDatas = (data) => ({
	type: GET_PODATA_LIST,
	data,
});

export const updatePoData = (data) => ({
	type: UPDATE_PODATA,
	data,
});

export const getPoDatasAsync = (params) => {
	const pageSize = 15;
	params = { ...params, pageSize };
	return (dispatch) => {
		return getPoDatasApi(params)
			.then((data) => {
				// if (data.errorCode.trim().length === 0) {
				// 	dispatch(getPoDatas(data.value));
				// } else {
				// 	console.error(`${data.errorCode}:: ${data.errorMessage}`);
				// }

				if (data.errorCode.trim().length === 0) {
					const totalCount = (data.value as any)?.total ?? 0;
					const totalpage =
						parseInt(parseInt(totalCount) / pageSize + '') +
						(totalCount % pageSize > 0 ? 1 : 0);
					dispatch(
						getPoDatas({ data: (data.value as any)?.data ?? [], total: totalpage })
					);
				} else {
					console.error(`${data.errorCode}:: ${data.errorMessage}`);
				}

			})
			.catch((error) => {
				throw error;
			});
	};
};

export const updatePoDataAsync = (data) => async (dispatch) => {
	try {
		const response = await updatePoDataApi(data);
		if (response.errorCode.trim().length === 0) {
			console.log(':::::::::::updatePoDataAsync', response.value);
			dispatch(updatePoData(response.value));

			return Promise.resolve(response.value);
		} else {
			return Promise.reject(response.errorMessage);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

import { Component } from 'react';
import { IButtonStyles, IconButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { connect } from 'react-redux';
import { IStore, IRequestItem } from '../../schemas';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { SearchableDropdown } from '..';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { isNumber, isEmptyStr } from '../../utilities';

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const buttonStyle = {
	root: {
		border: 'none',
	},
};
const buttonStyle1 = {
	root: {
		marginLeft: 8,
		border: 'none',
	},
};
const iconButtonStyles: Partial<IButtonStyles> = {
	root: {
		color: 'black',
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',
	},
	rootHovered: {
		color: 'grey',
	},
};

const stackTokens: IStackTokens = {
	childrenGap: 5,
};

const textFieldSytles: Partial<ITextFieldStyles> = {
	field: {
		color: '#000',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
	},
};

const dropdownStyles: Partial<IDropdownStyles> = {
	title: { color: '#484644', border: 'none' },
	label: {
		fontWeight: 'normal',
		color: '#484644',
	},
	subComponentStyles: {
		label: {
			root: { fontWeight: 'normal', color: '#484644' },
		},
		panel: {},
		multiSelectItem: {},
	},
};

class RequestItemsModal extends Component<any, any> {
	state = {
		requestItem: {} as IRequestItem,
		amountErrorMsg: '',
		toBucketErrorMsg: '',
		fromQuaterErrorMsg: '',
		toQuaterErrorMsg: ''
	};

	addRequestItem = () => {
		const item = this.state.requestItem;
		console.log('onDropDownChanged', item);
		var checkResult = true;
		if (isEmptyStr(item.toBucketId)) {
			this.setState({ toBucketErrorMsg: 'The ToBucket cannot be null' });
			checkResult = false;
		}
		if (isEmptyStr(item.fromQuater)) {
			this.setState({ fromQuaterErrorMsg: 'The FromQuater cannot be null' });
			checkResult = false;
		}
		if (isEmptyStr(item.toQuater)) {
			this.setState({ toQuaterErrorMsg: 'The ToQuater cannot be null' });
			checkResult = false;
		}
		if (!isNumber(item.adjustValue)) {
			this.setState({ amountErrorMsg: 'The amount must be a figure' });
			checkResult = false;
		}
		if (checkResult) {
			this.props.onBtnAddItemClick(item);
			this.closeModal();
		}
	};

	onTextChanged = (name) => {
		const item = this.state.requestItem;
		return (_, data) => {
			this.setState({
				requestItem: { ...item, [name]: data },
			});
		};
	};

	onDropDownChanged1 = (e, data, name) => {
		if (isNaN(data)) {
			const item = this.state.requestItem;
			this.setState({
				requestItem: {
					...item,
					toBucketId: data.key,
					toBucketexpenseName: isNaN(data) ? data.expenseName : e.expenseName,
					itemNo: data.data.itemNo
				}
			});
		}
	};

	onDropDownChanged = (name) => {
		const item = this.state.requestItem;
		return (_, data) => {
			if (name === 'toBucket') {
				this.setState({
					requestItem: {
						...item,
						toBucketId: data.key,
						toBucketexpenseName: data.expenseName
					}
				});
			} else {
				const optionObj = {
					[`${name}`]: data.text,
				};
				this.setState({
					requestItem: {
						...item, ...optionObj
					},
				});
			}
		};
	};

	closeModal = () => {
		console.log('closeModal', this.props);
		this.props.onDismiss();
	}

	render() {
		const { } = this.props;
		let option: any = [];
		let monthData = new Date();
		var month = monthData.getMonth() + 1;
		if (month == 1 || month == 2 || month == 3) {
			option = [
				{ key: 'q1', text: 'Q1' },
				{ key: 'q2', text: 'Q2' },
				{ key: 'q3', text: 'Q3' },
				{ key: 'q4', text: 'Q4' }]
		} else if (month == 4 || month == 5 || month == 6) {
			option = [
				{ key: 'q2', text: 'Q2' },
				{ key: 'q3', text: 'Q3' },
				{ key: 'q4', text: 'Q4' }]
		} else if (month == 7 || month == 8 || month == 9) {
			option = [
				{ key: 'q3', text: 'Q3' },
				{ key: 'q4', text: 'Q4' }]
		} else {
			option = [{ key: 'q4', text: 'Q4' }]
		}
		return (
			<>
				<div
					style={{
						overflowY: 'auto',
						padding: '1rem 2rem 0',
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<span style={{ fontWeight: 'bold' }}>
							Request Item
						</span>
						<IconButton
							styles={iconButtonStyles}
							iconProps={cancelIcon}
							onClick={this.closeModal}
						/>
					</div>
					<Stack tokens={stackTokens}>
						<Stack.Item grow style={{ width: '80%' }}>
							<SearchableDropdown
								placeholder='Select an option'
								api='getBudgetsApi'
								label='To Bucket'
								options={[]}
								styles={dropdownStyles}
								multiSelect={false}
								onChange={(e, v) => this.onDropDownChanged1(e, v, 'toBucket')}
								errorMessage={this.state.toBucketErrorMsg}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '80%' }}>
							<Dropdown
								key='dpModifyFromQuater'
								label='From Quarter'
								options={option}
								styles={dropdownStyles}
								onChange={this.onDropDownChanged('fromQuater')}
								errorMessage={this.state.fromQuaterErrorMsg}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '80%' }}>
							<Dropdown
								key='dpModifyToQuater'
								label='To Quarter'
								options={option}
								styles={dropdownStyles}
								onChange={this.onDropDownChanged('toQuater')}
								errorMessage={this.state.toQuaterErrorMsg}
							/>
						</Stack.Item>

						<Stack.Item grow style={{ width: '80%' }}>
							<TextField
								key='txtModifyAmount'
								label='Amount'
								borderless
								styles={textFieldSytles}
								onChange={this.onTextChanged('adjustValue')}
								errorMessage={this.state.amountErrorMsg}
							/>
						</Stack.Item>
					</Stack>
					<div
						style={{
							marginTop: 10,
							paddingTop: '1rem',
							display: 'flex',
							justifyContent: 'left',
						}}
					>
						<PrimaryButton styles={buttonStyle} onClick={() => this.addRequestItem()}>
							Add Item
						</PrimaryButton>
						<DefaultButton styles={buttonStyle1} onClick={() => this.closeModal()}>
							Cancel
						</DefaultButton>
					</div>
				</div>
			</>
		);
	}
}

export default connect(
	(state: IStore) => ({}),
	{
	}
)(RequestItemsModal);

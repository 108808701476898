function debounce(func, wait = 500) {
	let timeout;
	return function (event, data) {
		clearTimeout(timeout);
		event.persist && event.persist();
		timeout = setTimeout(() => {
			func(event, data);
		}, wait);
	};
}

function isNumber(s) {
	var reg = /^[-]?[\.\d]+$/;
	if (reg.test(s)) {
		return true;
	}
	return false;
}

function isEmptyStr(s) {
	if (s == undefined || s == null || s == '') {
		return true
	}
	return false
}

export { debounce, isNumber, isEmptyStr };

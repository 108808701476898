import { IAction, IUser } from '../../schemas';
import { GET_USER_LIST, ADD_USER, UPDATE_USER, DELETE_USER } from '../constant';

const initState: any = {};

export default function usersReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case GET_USER_LIST:
			return { ...data };
		case ADD_USER:
			const newdata = [...preState.data, data];
			return { data: newdata, total: preState.total };
		case UPDATE_USER:
			const index = preState.data.findIndex(
				(item) => item.id === action.data.id
			);
			const updateData = [
				...preState.data.slice(0, index),
				{
					...data,
				},
				...preState.data.slice(index + 1),
			];
			return { data: updateData, total: preState.total };
		case DELETE_USER:
			return {
				data: preState.data.filter((d) => d.id !== action.data.id),
				total: preState.total,
			};
		default:
			return preState;
	}
}

import { get, post, put, del } from './comm-http';
import { ISubDepartment, IResponse } from '../schemas';


async function getSubDepartmentsApi(params): Promise<IResponse<ISubDepartment[]>> {
    const token = localStorage.getItem('teamsAuthToken');
    return await get<IResponse<ISubDepartment[]>>('/dict/teamdetail', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params,
    });
}

async function addSubDepartmentApi(data: ISubDepartment): Promise<IResponse<ISubDepartment>> {
    const token = localStorage.getItem('teamsAuthToken');
    return await post<ISubDepartment, IResponse<ISubDepartment>>('/dict/subDepartment', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

async function updateSubDepartmentApi(data: ISubDepartment): Promise<IResponse<ISubDepartment>> {
    const token = localStorage.getItem('teamsAuthToken');
    return await put<ISubDepartment, IResponse<ISubDepartment>>(`/dict/subDepartment/${data.id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

async function deleteSubDepartmentApi(data: ISubDepartment): Promise<IResponse<ISubDepartment>> {
    const token = localStorage.getItem('teamsAuthToken');
    return await del<IResponse<ISubDepartment>>(`/dict/subDepartment/${data.id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

export {
    getSubDepartmentsApi,
    addSubDepartmentApi,
    updateSubDepartmentApi,
    deleteSubDepartmentApi,
};

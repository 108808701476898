import { IAction, ITemplate } from '../../schemas';
import { ADD_TEMPLATE } from '../constant';

const initState: ITemplate[] = [];

export default function templateReducer(
	preState = initState,
	action: IAction<ITemplate>
): ITemplate[] {
	const { type, data } = action;
	switch (type) {
		case ADD_TEMPLATE:
			return [data, ...preState];
		default:
			return preState;
	}
}
